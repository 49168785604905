import React from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Spinner } from '@alfalab/core-components/spinner';
import { SystemMessage } from '@alfalab/core-components/system-message';
import { colorLightGraphicPositive, colorLightGraphicPrimaryInverted } from '@alfalab/core-components/vars';
import CheckmarkMIcon from '@alfalab/icons-glyph/CheckmarkMIcon';


export const PasswordRecoveryChangePasswordSuccess: React.VFC = () => (
        <React.Fragment>
            <Gap size='5xl' />
            <SystemMessage>
                <SystemMessage.Graphic>
                    <SuperEllipse size={64} backgroundColor={colorLightGraphicPositive}>
                        <CheckmarkMIcon color={colorLightGraphicPrimaryInverted} />
                    </SuperEllipse>
                </SystemMessage.Graphic>
                <SystemMessage.Title>Пароль изменен</SystemMessage.Title>
                <SystemMessage.Subtitle>
                    Через несколько секунд откроется главная страница
                </SystemMessage.Subtitle>
                <SystemMessage.Caption>
                    <Spinner visible={true} size='s' />
                </SystemMessage.Caption>
            </SystemMessage>
        </React.Fragment>
    );

PasswordRecoveryChangePasswordSuccess.displayName = nameof(PasswordRecoveryChangePasswordSuccess);
