import { useSelector } from 'react-redux';

import { CoreUserSelectors } from '@ak-front/core/client/store/selectors';

import { DISALLOW_RIGHTS_DOCUMENT_REQUEST_SEND_BANK } from '@ak-front/document-request/client/constants';
import { DISALLOW_RIGHTS_DOCUMENT_QUERY_REQUEST_SEND_BANK } from '@ak-front/document-query-request/client/constants';
import { DISALLOW_RIGHTS_REPORT_CONTRACTS_VIEW } from '@ak-front/deal-report/client/constants';
import {
    DISALLOW_RIGHTS_CREDIT_REQUEST_ALL,
    DISALLOW_RIGHTS_CREDIT_REQUEST_CREATE_OR_EDIT,
    DISALLOW_RIGHTS_GUARANTEE_REQUEST_ALL,
    DISALLOW_RIGHTS_GUARANTEE_REQUEST_CREATE_OR_EDIT,
    DISALLOW_RIGHTS_BANK_REQUEST_SIGN,
} from '#/src/client/constants/user';

import { ApplicationState } from '#/src/client/store/reducers';

type UserDisallowedRightFlags = {
    isCreditRequestAllDisallowed?: boolean;
    isCreditRequestCreateOrEditDisallowed?: boolean;
    isGuaranteeRequestAllDisallowed?: boolean;
    isGuaranteeRequestCreateOrEditDisallowed?: boolean;
    isGuaranteeRequestSignDisallowed?: boolean;
    isGuaranteeRequestSendFnsDisallowed?: boolean;
    isDocumentQueryRequestSignDisallowed?: boolean;
    isDocumentRequestSendBankDisallowed?: boolean;
    isBankRequestSignDisallowed?: boolean;
    isReportContractsViewDisallowed?: boolean;
    isCreditHidden?: boolean;
    isGuaranteeHidden?: boolean;
    isMainPageHidden?: boolean;
    isNewAndDraftsPagesHidden?: boolean;
};

export const useUserDisallowedRightFlags = (): UserDisallowedRightFlags => {
    const isCreditRequestAllDisallowed = useSelector(
        (state: ApplicationState) => (
            CoreUserSelectors.isActionsDisallowedU(state, [DISALLOW_RIGHTS_CREDIT_REQUEST_ALL])
        ),
    );

    const isCreditRequestCreateOrEditDisallowed = useSelector(
        (state: ApplicationState) => (
            CoreUserSelectors.isActionsDisallowedU(state, [DISALLOW_RIGHTS_CREDIT_REQUEST_CREATE_OR_EDIT])
        ),
    );
    const isGuaranteeRequestAllDisallowed = useSelector(
        (state: ApplicationState) => (
            CoreUserSelectors.isActionsDisallowedU(state, [DISALLOW_RIGHTS_GUARANTEE_REQUEST_ALL])
        ),
    );
    const isGuaranteeRequestCreateOrEditDisallowed = useSelector(
        (state: ApplicationState) => (
            CoreUserSelectors.isActionsDisallowedU(state, [DISALLOW_RIGHTS_GUARANTEE_REQUEST_CREATE_OR_EDIT])
        ),
    );
    const isDocumentQueryRequestSignDisallowed = useSelector(
        (state: ApplicationState) => (
            CoreUserSelectors.isActionsDisallowedU(state, [DISALLOW_RIGHTS_DOCUMENT_QUERY_REQUEST_SEND_BANK])
        ),
    );
    const isDocumentRequestSendBankDisallowed = useSelector(
        (state: ApplicationState) => (
            CoreUserSelectors.isActionsDisallowedU(state, [DISALLOW_RIGHTS_DOCUMENT_REQUEST_SEND_BANK])
        ),
    );
    const isBankRequestSignDisallowed = useSelector(
        (state: ApplicationState) => (
            CoreUserSelectors.isActionsDisallowedU(state, [DISALLOW_RIGHTS_BANK_REQUEST_SIGN])
        ),
    );
    const isReportContractsViewDisallowed = useSelector(
        (state: ApplicationState) => (
            CoreUserSelectors.isActionsDisallowedU(state, [DISALLOW_RIGHTS_REPORT_CONTRACTS_VIEW])
        ),
    );

    return {
        isCreditRequestAllDisallowed,
        isCreditRequestCreateOrEditDisallowed,
        isGuaranteeRequestAllDisallowed,
        isGuaranteeRequestCreateOrEditDisallowed,
        isDocumentQueryRequestSignDisallowed,
        isDocumentRequestSendBankDisallowed,
        isBankRequestSignDisallowed,
        isReportContractsViewDisallowed,
        isCreditHidden: isCreditRequestAllDisallowed || isCreditRequestCreateOrEditDisallowed,
        isGuaranteeHidden: isGuaranteeRequestAllDisallowed || isGuaranteeRequestCreateOrEditDisallowed,
        isMainPageHidden: isCreditRequestAllDisallowed
            && isGuaranteeRequestAllDisallowed
            && isDocumentQueryRequestSignDisallowed
            && isDocumentRequestSendBankDisallowed
            && isBankRequestSignDisallowed,
        isNewAndDraftsPagesHidden: (isCreditRequestAllDisallowed || isCreditRequestCreateOrEditDisallowed)
            && (isGuaranteeRequestAllDisallowed || isGuaranteeRequestCreateOrEditDisallowed)
            && isDocumentRequestSendBankDisallowed,
    };
};
