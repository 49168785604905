import React from 'react';

import { CreditForm as CF } from '@ak-front/credit-request/client/components';
import { CreditFillingContractWithOrganization } from '@ak-front/credit-request/client/components/credit-form/credit-filling-contract';
import { RequestCode } from '@ak-front/core/client/types';
import { CRSelectors } from '@ak-front/credit-request/client';
import { useSelector } from 'react-redux';
import { CreditRequestModelStatus } from '@ak-front/credit-request/client/api/fetch-client';
import { FormPlate } from '@ak-front/core/client';
import { RequestFeedback } from '#/src/client/components/request/request-feedback';

export const CreditForm: React.VFC = () => {
    const status = useSelector(CRSelectors.getStatusCR);
    const requestFeedbackIsVisible = status === CreditRequestModelStatus.Issued;

    const requestFeedback = requestFeedbackIsVisible ? (
        <FormPlate>
            <RequestFeedback requestCode={ RequestCode.CreditRequest } />
        </FormPlate>
    ) : null;

    return (
        <CF
            creditFillingContractComponent={ CreditFillingContractWithOrganization }
            footer={ requestFeedback }
        />
    );
};

CreditForm.displayName = nameof(CreditForm);
