import React from 'react';
import { DocumentType } from '@ak-front/core/client/types';

import { Link } from '@alfalab/core-components/link';
import { Space } from '@alfalab/core-components/space';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';

import { findLastDocumentVersion } from '#/src/client/utils/dictionary';

type AppFooterDocumentProps = {
    description: string;
    versions: DocumentType[];
};

export const PageExternalFooterDocument: React.VFC<AppFooterDocumentProps> = ({ versions, description }) => {
    const handleClick = () => {
        window.location.href = findLastDocumentVersion(versions).relativePath;
    };

    if (versions.length > 1) {
        const links = versions.map((v, i) => (
            <Link key={i} view='primary' href={v.relativePath}>
                {v.description}
            </Link>
        ));

        return (
            <Tooltip
                content={
                    <Space size={0} direction='vertical'>
                        {links}
                    </Space>
                }
                position='top'
                trigger='click'
            >
                <Link pseudo={true} view='secondary'>
                    <Typography.Text view='secondary-small' color='secondary'>
                        {description}
                    </Typography.Text>
                </Link>
            </Tooltip>
        );
    }

    return (
        <Link pseudo={true} view='secondary' onClick={handleClick}>
            <Typography.Text view='secondary-small' color='secondary'>
                {description}
            </Typography.Text>
        </Link>
    );
};

PageExternalFooterDocument.displayName = nameof(PageExternalFooterDocument);
