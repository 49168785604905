import React from 'react';
import { useDispatch } from 'react-redux';

import { FormControl } from '@ak-front/core/client/types';

import { GRActions } from '@ak-front/guarantee-request/client/store/actions';
import { useDidMount } from '@ak-front/core/client/utils/hook';

export const BatchFillingAdditionalDataRentGR: React.FC<FormControl> = React.memo(() => {
    const dispatch = useDispatch();

    useDidMount(() => {
        dispatch(GRActions.actionSetAdditionalDataRentGR({}));
    });

    return null;
});

BatchFillingAdditionalDataRentGR.displayName = nameof(BatchFillingAdditionalDataRentGR);
