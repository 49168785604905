/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { Colors } from '@ak-front/core/client';
import {
    LINK_SUPPORT_EMAIL,
    LINK_SUPPORT_MOSCOW_TEL,
    LINK_SUPPORT_REGION_TEL,
    SUPPORT_EMAIL,
    SUPPORT_MOSCOW_TEL,
    SUPPORT_REGION_TEL,
} from '@ak-front/core/constants/all';

import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { BuildMoscowMIcon } from '@alfalab/icons-glyph/BuildMoscowMIcon';
import { MailMIcon } from '@alfalab/icons-glyph/MailMIcon';
import { PhoneBubbleMIcon } from '@alfalab/icons-glyph/PhoneBubbleMIcon';

const contacts = [
    {
        title: SUPPORT_MOSCOW_TEL,
        subtitle: 'в Москве и за границей',
        link: LINK_SUPPORT_MOSCOW_TEL,
        icon: BuildMoscowMIcon,
    },
    {
        title: SUPPORT_REGION_TEL,
        subtitle: 'в других регионах России',
        link: LINK_SUPPORT_REGION_TEL,
        icon: PhoneBubbleMIcon,
    },
    {
        title: SUPPORT_EMAIL,
        subtitle: 'техническая поддержка',
        link: LINK_SUPPORT_EMAIL,
        icon: MailMIcon,
    },
];

export const AppContacts: React.VFC = React.memo(() => {
    const result = contacts.map(({ title, link, subtitle, icon: Icon }, index) => (
        <React.Fragment key={index}>
            {index === 0 ? null : <Gap size='m' />}
            <GenericWrapper alignItems='center'>
                <SuperEllipse size={48} backgroundColor={Colors['secondary-light']}>
                    <Icon color={Colors.primary} />
                </SuperEllipse>
                <Gap direction='horizontal' size='m' />
                <GenericWrapper column={true}>
                    <Typography.Text view='primary-small' weight='bold' color='primary' tag='div'>
                        <Link href={link} view='primary' underline={false}>
                            {title}
                        </Link>
                    </Typography.Text>
                    <Typography.Text view='secondary-medium' color='secondary'>
                        {subtitle}
                    </Typography.Text>
                </GenericWrapper>
            </GenericWrapper>
        </React.Fragment>
    ));

    return <React.Fragment>{result}</React.Fragment>;
});

AppContacts.displayName = nameof(AppContacts);
