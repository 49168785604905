import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect, Route, RouteComponentProps, Switch, } from 'react-router';
import {
    PATH_REQUEST_BANK_ROUTE_WITH_ID,
} from '@ak-front/bank-request/constants/router';
import { AppNotificationManager } from '@ak-front/core/client/components/app-notification-manager';
import { CoreUserSelectors } from '@ak-front/core/client/store/selectors';
import { PATH_ROOT_ROUTE } from '@ak-front/core/constants';
import {
    PATH_REQUEST_CREDIT_ROUTE,
    PATH_REQUEST_CREDIT_ROUTE_WITH_ID,
} from '@ak-front/credit-request/constants/router';
import { DealReport } from '@ak-front/deal-report/client/components';
import { PATH_DEAL_REPORT_ROUTE } from '@ak-front/deal-report/constants';
import {
    PATH_REQUEST_DOCUMENT_QUERY_ROUTE_WITH_ID,
} from '@ak-front/document-query-request/constants/router';
import {
    PATH_REQUEST_DOCUMENT_ROUTE,
    PATH_REQUEST_DOCUMENT_ROUTE_WITH_ID,
} from '@ak-front/document-request/constants/router';
import {
    PATH_REQUEST_GUARANTEE_ROUTE,
    PATH_REQUEST_GUARANTEE_ROUTE_WITH_ID,
} from '@ak-front/guarantee-request/client/constants/router';
import { IncomingGuarantees, IncomingGuaranteeView } from '@ak-front/incoming-guarantee/client/components';
import {
    PATH_INCOMING_GUARANTEE_ROUTE_WITH_DOC_NUMBER,
    PATH_INCOMING_GUARANTEES_ROUTE,
} from '@ak-front/incoming-guarantee/client/constants/router';
import { TrancheChangeGuaranteeForm, TrancheChangeGuaranteeList } from '@ak-front/tranche-change-request/client/components';
import {
    PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_LIST_ROUTE,
    PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_ROUTE,
    PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_ROUTE_WITH_ID,
} from '@ak-front/tranche-change-request/client/constants/router';

import { useUserDisallowedRightFlags } from '#/src/client/components/app-menu/hooks';
import { BankForm } from '#/src/client/components/request/bank-form';
import { BatchForm } from '#/src/client/components/request/batch-form';
import { BatchList } from '#/src/client/components/request/batch-form/batch-list';
import { CreditForm } from '#/src/client/components/request/credit-form';
import { DocumentQueryForm } from '#/src/client/components/request/document-query-request';
import { DocumentForm } from '#/src/client/components/request/document-request';
import { GuaranteeForm } from '#/src/client/components/request/guarantee-request';
import { RequestForm } from '#/src/client/components/request/request-form';
import {
PATH_REQUEST_BATCH_LIST_ROUTE, PATH_REQUEST_BATCH_ROUTE_WITH_ID,
    PATH_SETTINGS, } from '#/src/client/constants/routes';
import { PageInternal } from '#/src/client/pages/internal';
import { SettingsPage } from '#/src/client/pages/internal/settings';
import {
    PATH_NOT_FOUND_ROUTE,
    PATH_REQUEST_BATCH_ROUTE,
} from '#/src/shared/constants/routes';

const Head: React.FC<RouteComponentProps<void>> = () => {
    const whiteList = useSelector(CoreUserSelectors.getWhiteListU);

    const isBatchEnabled = Boolean(whiteList?.batchRequest);

    const {
        isDocumentRequestSendBankDisallowed,
        isCreditHidden,
        isGuaranteeHidden,
    } = useUserDisallowedRightFlags();

    return (
        <PageInternal>
            <Switch>
                <Route path={ PATH_REQUEST_BANK_ROUTE_WITH_ID } component={ BankForm } />
                { !isGuaranteeHidden ? <Route exact={ true } path={ PATH_REQUEST_GUARANTEE_ROUTE } component={ GuaranteeForm } /> : null }
                <Route exact={ true } path={ PATH_REQUEST_GUARANTEE_ROUTE_WITH_ID } component={ GuaranteeForm } />
                { isBatchEnabled ? <Route exact={ true } path={ PATH_REQUEST_BATCH_LIST_ROUTE } component={ BatchList } /> : null }
                { isBatchEnabled && !isGuaranteeHidden ? <Route exact={ true } path={ PATH_REQUEST_BATCH_ROUTE } component={ BatchForm } /> : null }
                { isBatchEnabled ? <Route path={ PATH_REQUEST_BATCH_ROUTE_WITH_ID } component={ BatchForm } /> : null }
                <Route exact={ true } path={ PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_LIST_ROUTE } component={ TrancheChangeGuaranteeList } />
                <Route exact={ true } path={ PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_ROUTE } component={ TrancheChangeGuaranteeForm } />
                <Route path={ PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_ROUTE_WITH_ID } component={ TrancheChangeGuaranteeForm } />
                <Route path={ PATH_REQUEST_DOCUMENT_QUERY_ROUTE_WITH_ID } component={ DocumentQueryForm } />
                { !isDocumentRequestSendBankDisallowed ? <Route exact={ true } path={ PATH_REQUEST_DOCUMENT_ROUTE } component={ DocumentForm } /> : null }
                <Route path={ PATH_REQUEST_DOCUMENT_ROUTE_WITH_ID } component={ DocumentForm } />
                { !isCreditHidden ? <Route exact={ true } path={ PATH_REQUEST_CREDIT_ROUTE } component={ CreditForm } /> : null }
                <Route path={ PATH_REQUEST_CREDIT_ROUTE_WITH_ID } component={ CreditForm } />
                <Route exact={ true } path={ PATH_INCOMING_GUARANTEES_ROUTE } component={ IncomingGuarantees } />
                <Route path={ PATH_INCOMING_GUARANTEE_ROUTE_WITH_DOC_NUMBER } component={ IncomingGuaranteeView } />
                <Route path={ PATH_DEAL_REPORT_ROUTE } component={ DealReport } />
                <Route path={ PATH_SETTINGS } component={ SettingsPage } />
                <Route exact={ true } path={ PATH_ROOT_ROUTE } component={ RequestForm } />
                <Redirect to={ PATH_NOT_FOUND_ROUTE } />
            </Switch>
            <AppNotificationManager />
        </PageInternal>
    );
};

export default Head;
