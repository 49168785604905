import React, { useMemo, useState } from 'react';

import { Table } from '@alfalab/core-components/table';

import { GuaranteeRequestModelType as GuaranteeType } from '@ak-front/guarantee-request/client/api/fetch-client';

import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { RequestCode } from '@ak-front/core/client/types/request';
import { useSelector } from 'react-redux';
import { useDidUpdate } from '@ak-front/core/client/utils/hook';
import { BatchRequestFilterStatus, BatchRequestsGroupsByStatus } from '#/src/client/types';
import { BHRSelectors } from '#/src/client/store/selectors';
import { BatchViewGuaranteeRequestItem } from './batch-view-guarantee-request-item';
import { tableSettings } from '../../batch-table-settings';

type BatchViewRequestsProps = {
    options: { guaranteeType?: GuaranteeType };
    requests?: BatchRequestsGroupsByStatus;
};

const DEFAULT_PER_PAGE = 5;

export const BatchViewRequests: React.FC<BatchViewRequestsProps> = React.memo((
    { options, requests = {} },
) => {
    const [perPage, setPerPage] = useState<number>(DEFAULT_PER_PAGE);
    const [pageNumber, setPageNumber] = useState<number>(0);

    const filterStatus = useSelector(BHRSelectors.getFilterStatusBHR);

    const currentRequests = requests[filterStatus || BatchRequestFilterStatus.All] || [];

    const currentPageData = useMemo(
        () => currentRequests.slice(pageNumber * perPage).slice(0, perPage),
        [currentRequests, filterStatus, pageNumber, perPage],
    );

    useDidUpdate(() => {
        setPageNumber(0);
    }, [filterStatus]);

    const handlePageChange = (value: number) => setPageNumber(value);

    const handlePerPageChange = (value: number) => {
        setPageNumber(0);
        setPerPage(value);
    };

    const headers = (
        (tableSettings[RequestCode.GuaranteeRequest]![options.guaranteeType!] || [])
            .map(({ title, align, width }) => (
                <Table.THeadCell title={ title } textAlign={ align } width={ width }>
                    { title }
                </Table.THeadCell>
            )));

    const rows = currentPageData.map((request) => (
        <BatchViewGuaranteeRequestItem
            key={ request.id }
            type={ options.guaranteeType }
            request={ request }
        />
    ));

    const pagination = (
        <Table.Pagination
            perPage={ perPage }
            possiblePerPage={ [DEFAULT_PER_PAGE, 10] }
            currentPageIndex={ pageNumber }
            pagesCount={ Math.ceil(currentRequests.length / perPage) }
            onPageChange={ handlePageChange }
            onPerPageChange={ handlePerPageChange }
        />
    );

    return (
        <LineIntent top="2xl">
            <Table pagination={ pagination }>
                <Table.THead>
                    { headers }
                </Table.THead>
                <Table.TBody>
                    { rows }
                </Table.TBody>
            </Table>
        </LineIntent>
    );
});

BatchViewRequests.displayName = nameof(BatchViewRequests);
