import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PATH_ROOT_ROUTE } from '@ak-front/core';
import { FormPlate } from '@ak-front/core/client';
import { RequestCode } from '@ak-front/core/client/types';
import { DRSelectors } from '@ak-front/document-request/client';
import { DocumentRequestResponseStatus } from '@ak-front/document-request/client/api/fetch-client';
import { DocumentForm as DF } from '@ak-front/document-request/client/components';
import { DocumentContext, DocumentContextData } from '@ak-front/document-request/client/components/context';
import { DocumentFillingOrganization } from '@ak-front/document-request/client/components/document-filling-organization';

import { RequestFeedback } from '#/src/client/components/request/request-feedback';

export const DocumentForm: React.FC = () => {
    const status = useSelector(DRSelectors.getStatusDR);
    const contextValue = useMemo<DocumentContextData>(() => {
        const search = new URLSearchParams({
            requestCode: RequestCode.DocumentRequest,
        });

        return {
            redirectType: 'push',
            redirectToPathAfterSendSuccess: `${PATH_ROOT_ROUTE}?${search}`
         };
    }, []);

    const requestFeedbackIsVisible = status === DocumentRequestResponseStatus.SendBank;

    const requestFeedback = requestFeedbackIsVisible ? (
        <FormPlate>
            <RequestFeedback requestCode={RequestCode.DocumentRequest} />
        </FormPlate>
    ) : null;

    return (
        <DocumentContext.Provider value={contextValue}>
            <DF documentFillingOrganizationComponent={DocumentFillingOrganization} footer={requestFeedback} />
        </DocumentContext.Provider>
    );
};

DocumentForm.displayName = nameof(DocumentForm);
