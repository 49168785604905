import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { TypographyWrapper } from '@ak-front/core/client';

import { Confirmation, useConfirmation } from '@alfalab/core-components/confirmation';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { SendConfirmationRequestChannel } from '#/src/client/api/fetch-client/fetch-client-dto';
import { PasswordRecoveryConfirmationHelp } from '#/src/client/pages/external/password-recovery/confirmation/help';
import { TaskActions } from '#/src/client/store/actions/creators';

import './index.css';

type PasswordRecoveryConfirmationProps = {
    channel: SendConfirmationRequestChannel;
    title: string;
    text: string;
    value: string;
    onSuccess: () => void;
};

export const PasswordRecoveryConfirmation: React.VFC<PasswordRecoveryConfirmationProps> = ({
    channel,
    title,
    text,
    value,
    onSuccess,
}) => {
    const dispatch = useDispatch();

    const [openHelp, setOpenHelp] = useState<boolean>(false);

    const {
        confirmationState,
        confirmationScreen,
        confirmationBlockSmsRetry,
        setConfirmationState,
        setConfirmationScreen,
    } = useConfirmation();

    const handleCloseHelp = () => setOpenHelp(false);
    const handleOpenHelp = () => setOpenHelp(true);

    const handleInputFinished = (code: string) =>
        dispatch(
            TaskActions.actionTaskCheckConfirmationRun(channel, code, value, (result) => {
                if (result) {
                    onSuccess();
                } else {
                    setConfirmationState('CODE_ERROR');
                }
            }),
        );

    const handleSmsRetryClick = () =>
        dispatch(
            TaskActions.actionTaskSendConfirmationRun(channel, value, () => {
                setConfirmationState('INITIAL');
            }),
        );

    const handleTempBlockFinished = () => {
        setConfirmationScreen('INITIAL');
        setConfirmationState('CODE_SENDING');
    };

    return (
        <React.Fragment>
            <TypographyWrapper align='center'>
                <Typography.TitleResponsive tag='h1' view='medium' color='primary' font='system'>
                    {title}
                </Typography.TitleResponsive>
                <Gap size='m' />
                <Typography.Text view='primary-medium' color='primary'>
                    {text}
                </Typography.Text>
            </TypographyWrapper>
            <Gap size='2xl' />
            <Confirmation
                className='password-recovery-confirmation__confirmation'
                screen={confirmationScreen}
                state={confirmationState}
                blockSmsRetry={confirmationBlockSmsRetry}
                requiredCharAmount={6}
                clearCodeOnError={true}
                alignContent='center'
                onChangeState={setConfirmationState}
                onChangeScreen={setConfirmationScreen}
                onInputFinished={handleInputFinished}
                onSmsRetryClick={handleSmsRetryClick}
                onTempBlockFinished={handleTempBlockFinished}
            />
            <Link view='secondary' pseudo={true} onClick={handleOpenHelp}>
                <Typography.Text view='primary-small' color='secondary'>
                    Не приходит сообщение?
                </Typography.Text>
            </Link>
            <PasswordRecoveryConfirmationHelp open={openHelp} onClose={handleCloseHelp} />
        </React.Fragment>
    );
};

PasswordRecoveryConfirmation.displayName = nameof(PasswordRecoveryConfirmation);
