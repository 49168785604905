import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Icon } from '@ak-front/core/client/components/icon';

import { Button } from '@alfalab/core-components/button';
import { Tooltip } from '@alfalab/core-components/tooltip';
import { Typography } from '@alfalab/core-components/typography';
import BriefcaseMIcon from '@alfalab/icons-glyph/BriefcaseMIcon';
import LogOutMIcon from '@alfalab/icons-glyph/LogOutMIcon';

import { PATH_SETTINGS } from '#/src/client/constants/routes';
import { TaskActions } from '#/src/client/store/actions/creators';
import { TaskSelectors, UserSelectors } from '#/src/client/store/selectors';
import { getFullName } from '#/src/client/utils/user';

import './index.css';

export const AppNavigation: React.VFC = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const firstName = useSelector(UserSelectors.getFirstNameAH) || '';
    const lastName = useSelector(UserSelectors.getLastNameAH) || '';
    const patronymicName = useSelector(UserSelectors.getPatronymicNameAH) || '';

    const statusTaskSignOut = useSelector(TaskSelectors.getStatusTaskSignOut);

    const fullName = getFullName(firstName, lastName, patronymicName);

    const handleSettings = useCallback(() => history.push(PATH_SETTINGS), [history.push]);
    const handleSignOut = useCallback(() => dispatch(TaskActions.actionTaskSignOutRun()), []);

    return (
        <React.Fragment>
            <div className="app-navigation app-navigation_desktop">
                <div className="app-navigation__profile">
                    <Tooltip view="hint" content="Настройки" position="bottom">
                        <Button
                            id="user-settings"
                            className="app-navigation__profile-user-settings"
                            view="link"
                            size="s"
                            nowrap={ true }
                            leftAddons={ <Icon color="secondary" icon={ BriefcaseMIcon } /> }
                            onClick={ handleSettings }
                        >
                            { fullName }
                        </Button>
                    </Tooltip>
                    <Tooltip view="hint" content="Выход" position="bottom">
                        <Button
                            id="logout"
                            view="link"
                            size="s"
                            leftAddons={ <Icon color="secondary" icon={ LogOutMIcon } /> }
                            disabled={ statusTaskSignOut }
                            onClick={ handleSignOut }
                        />
                    </Tooltip>
                </div>
            </div>
            <div className="app-navigation app-navigation_mobile">
                <div className="app-navigation__profile">
                    <Typography.Text
                        className="app-navigation__profile-user-settings"
                        view="secondary-large"
                        weight="medium"
                        color="primary-inverted"
                    >
                        { fullName }
                    </Typography.Text>
                    <Button
                        id="logout"
                        view="link"
                        size="s"
                        leftAddons={ <Icon color="primary-inverted" icon={ LogOutMIcon } /> }
                        disabled={ statusTaskSignOut }
                        onClick={ handleSignOut }
                    />
                </div>
            </div>
        </React.Fragment>
    );
};
