import { useMemo } from 'react';
import { useLocation } from 'react-router';
import { RequestCode } from '@ak-front/core/client/types';

export const useRequestCode = () => {
    const { search } = useLocation();

    return useMemo(() => {
        const params = new URLSearchParams(search);
        const requestCode = (params.get('requestCode') || '').toLowerCase();
        const [[, result] = []] = Object.entries({
            [RequestCode.BankRequest]: RequestCode.BankRequest,
            [RequestCode.CreditRequest]: RequestCode.CreditRequest,
            [RequestCode.DocumentQueryRequest]: RequestCode.DocumentRequest,
            [RequestCode.DocumentRequest]: RequestCode.DocumentRequest,
            [RequestCode.GuaranteeRequest]: RequestCode.GuaranteeRequest,
        }).filter(([key, ]) => requestCode === key.toLowerCase());

        return result;
    }, [search]);
};