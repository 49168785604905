import React, { useState, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { NBSP } from '@ak-front/core/client/constants/formatters';
import { Typography } from '@alfalab/core-components/typography';

import { Button } from '@alfalab/core-components/button';

import { getSelectedThumbprint, getCertificateGroups } from '@ak-front/core/client/store/selectors/certificate';

import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { ValidCertificateGroup } from '@ak-front/core/client/components/modals/certificates-modal/certificate-group';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { getStatusTaskSignUpCertificate } from '#/src/client/store/selectors/task';
import { AccessionAgreementModal } from '#/src/client/components/modals';
import { CheckboxWithError } from '#/src/client/components/checkbox-with-error';
import { CheckboxConfirmingLabel } from '#/src/client/pages/external/sign/up/checkbox-confirming-label';
import { SignOtherCertificates } from '../other-certificates';
import { SignInButton } from './sing-in-button';

type SignUpFormProps = {
    waiting?: boolean;
};

export const SignUpForm = React.memo(({ waiting }: SignUpFormProps) => {
    const [openAccessionAgreement, setOpenAccessionAgreement] = useState<boolean>(false);

    const [confimation, setConfimation] = useState<boolean>(false);
    const [confimationError, setConfimationError] = useState<string | undefined>();

    const [validCertificates, otherCertificates] = useSelector(getCertificateGroups);

    const selectedThumbprint = useSelector(getSelectedThumbprint);

    const statusTaskSignUpCertificate = useSelector(getStatusTaskSignUpCertificate);

    const disabled = statusTaskSignUpCertificate;

    const handleCloseAccessionAgreement = useCallback(() => {
        setOpenAccessionAgreement(false);
    }, []);

    const handleSignUp = useCallback(() => {
        if (confimation) {
            setOpenAccessionAgreement(true);
        } else {
            setConfimationError('Чтобы продолжить, необходимо ваше согласие');
        }
    }, [confimation]);

    const handleConfimation = (
        _?: React.ChangeEvent<HTMLInputElement>,
        payload?: {
            checked: boolean;
            name?: string | undefined;
        },
    ) => {
        setConfimation(!!payload?.checked);
        setConfimationError(undefined);
    };

    return (
        <React.Fragment>
            <LineIntent top="m" bottom="m">
                <Typography.Text view="primary-large" color="primary">
                    Для подписи документов зарегистрируйтесь в
                    { NBSP }
                    Альфа-Кредит.
                    Если вы
                    { NBSP }
                    уже зарегистрированы в
                    { NBSP }
                    системе,
                    войдите по
                    { NBSP }
                    логину и
                    { NBSP }
                    паролю:
                </Typography.Text>
            </LineIntent>
            <LineIntent bottom="m">
                <Skeleton visible={ waiting } width="available" size="8xl">
                    <ValidCertificateGroup
                        id="valid-certificates"
                        value={ validCertificates }
                        thumbprint={ selectedThumbprint }
                        autoSelect={ true }
                        disabled={ disabled }
                    />
                </Skeleton>
            </LineIntent>
            <SignOtherCertificates waiting={ waiting } certificates={ otherCertificates } />
            <LineIntent top="2xl">
                <Skeleton visible={ waiting } width="available" size="4xl">
                    <CheckboxWithError
                        checked={ confimation }
                        label={ <CheckboxConfirmingLabel /> }
                        disabled={ disabled }
                        error={ confimationError }
                        onChange={ handleConfimation }
                    />
                </Skeleton>
            </LineIntent>
            <LineIntent top="2xl">
                <Skeleton visible={ waiting } width="available" size="4xl">
                    <Button
                        id="signUp"
                        view="primary"
                        size="s"
                        block={ true }
                        disabled={ !selectedThumbprint || disabled }
                        loading={ statusTaskSignUpCertificate }
                        onClick={ handleSignUp }
                    >
                        Зарегистрироваться
                    </Button>
                </Skeleton>
            </LineIntent>
            <LineIntent top="m">
                <Skeleton visible={ waiting } width="available" size="4xl">
                    <SignInButton disabled={ disabled } />
                </Skeleton>
            </LineIntent>
            <AccessionAgreementModal open={ openAccessionAgreement } onClose={ handleCloseAccessionAgreement } />
        </React.Fragment>
    );
});

SignUpForm.displayName = nameof(SignUpForm);
