import { call,  select } from 'redux-saga/effects';
import { CoreHistorySagas } from '@ak-front/core/client';
import { listenLocationChange } from '@ak-front/core/client/store/sagas/history';
import { LocationChangedArgs } from '@ak-front/core/client/types/history';
import { PATH_ROOT_ROUTE } from '@ak-front/core/constants/router';

import { SignInResponseCode } from '#/src/client/api/fetch-client';
import { PATH_CHANGE_PASSWORD_ROUTE, PATH_PASSWORD_RECOVERY_ROUTE } from '#/src/client/constants/routes';
import { UserSelectors } from '#/src/client/store/selectors';
import {
PATH_ERROR_ROUTE, PATH_NOT_FOUND_ROUTE,
    PATH_SUPPORT_BROWSER_ROUTE, } from '#/src/shared/constants/routes';
import {
    PATH_SIGN_IN_ROUTE,
    PATH_SIGN_UP_ROUTE,
} from '#/src/shared/constants/user';

/**
 * Вызывается при каждой смене url - проверяет, что открыта главная страница
 */
export function* sagaCheckUrlForHead(action: LocationChangedArgs) {
    if (action.action === 'POP'
        && !action.location.pathname.startsWith(PATH_SIGN_UP_ROUTE)
        && !action.location.pathname.startsWith(PATH_PASSWORD_RECOVERY_ROUTE)
        && !action.location.pathname.startsWith(PATH_SUPPORT_BROWSER_ROUTE)
        && !action.location.pathname.startsWith(PATH_ERROR_ROUTE)
        && !action.location.pathname.startsWith(PATH_NOT_FOUND_ROUTE)
    ) {
        const code: SignInResponseCode | undefined = yield select(UserSelectors.getUserCodeAH);

        if (code !== SignInResponseCode.AUTHENTICATED
            && code !== SignInResponseCode.TEMP
            && code !== SignInResponseCode.EXPIRED
        ) {
            return yield call(CoreHistorySagas.push, `${PATH_SIGN_IN_ROUTE}${action.location.search}`);
        } if (code === SignInResponseCode.TEMP || code === SignInResponseCode.EXPIRED) {
            return yield call(CoreHistorySagas.push, PATH_CHANGE_PASSWORD_ROUTE);
        }
    }

    if (action.action === 'REPLACE' || action.location.pathname !== PATH_ROOT_ROUTE) {
        return false;
    }

    return false;
}

export default function* root() {
    yield listenLocationChange(sagaCheckUrlForHead);
}
