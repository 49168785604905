import React from 'react';
import { useSelector } from 'react-redux';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { CoreAppSelectors } from '@ak-front/core/client/store/selectors';

import { Grid } from '@alfalab/core-components/grid';
import { Space } from '@alfalab/core-components/space';

import { StalemateBaseError } from '#/src/client/components/error/stalemate';
import { PageExternal } from '#/src/client/pages/external';

export const ErrorPage: React.FC = () => {
    const errorCode = useSelector(CoreAppSelectors.getErrorCodeA);

    return (
        <PageExternal>
            <Space size={ 0 } direction="vertical" fullWidth={ true } wrap={ false }>
                <LineIntent top="6xl" bottom="6xl">
                    <Grid.Row gutter="0" justify="center">
                        <Grid.Col width={ { desktop: '6', tablet: '6', mobile: '12' } }>
                            <StalemateBaseError
                                title="Возникла техническая проблема"
                                text="Обратитесь в поддержку"
                                code={ errorCode }
                            />
                        </Grid.Col>
                    </Grid.Row>
                </LineIntent>
            </Space>
        </PageExternal>
    );
};

ErrorPage.displayName = nameof(ErrorPage);
