import React, { useCallback, useState } from 'react';
import { useHistory } from 'react-router';
import { useDispatch } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';
import { Button } from '@alfalab/core-components/button';
import { NBSP } from '@ak-front/core/client/constants/formatters';

import { FormControl } from '@ak-front/core/client/types';

import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { ConfirmingModal } from '@ak-front/core/client/components/modals/confirming';
import { TaskActions } from '#/src/client/store/actions/creators';

const confirmModalText = `После удаления, дальнейшая работа${NBSP}с${NBSP}реестром станет невозможной. Заявления${NBSP}в${NBSP}статусе "Черновик"${NBSP}и${NBSP}"На подпись" будут удалены, прочие заявления будут доступны на${NBSP}главной странице.`;

export const ButtonRemove: React.FC<FormControl> = React.memo(({ waiting, disabled }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [openConfirmModal, setOpenConfirmModal] = useState(false);

    const handleOpenConfirmModal = useCallback(() => {
        setOpenConfirmModal((open) => !open);
    }, [openConfirmModal]);

    const handleRemove = useCallback(() => {
        dispatch(TaskActions.actionTaskRemoveBHRRun({ history }));
    }, []);

    const confirmButton = {
        disabled,
        loading: disabled,
        onClick: handleRemove,
        text: 'Удалить',
    };
    const cancelButton = {
        disabled,
        onClick: handleOpenConfirmModal,
        text: 'Не удалять',
    };

    return (
        <Grid.Col width={ { desktop: 'auto', tablet: 'auto', mobile: '12' } }>
            <LineIntent bottom="m">
                <Skeleton width="available" visible={ waiting }>
                    <Button
                        id="bhr-remove"
                        view="secondary"
                        block={ true }
                        size="m"
                        disabled={ disabled }
                        onClick={ handleOpenConfirmModal }
                    >
                        Удалить пакет заявлений
                    </Button>
                </Skeleton>
                <ConfirmingModal
                    title="Удалить пакет заявлений?"
                    text={ confirmModalText }
                    open={ openConfirmModal }
                    disableBackdropClick={ true }
                    disableEscapeKeyDown={ true }
                    confirmButton={ confirmButton }
                    cancelButton={ cancelButton }
                />
            </LineIntent>
        </Grid.Col>
    );
});

ButtonRemove.displayName = nameof(ButtonRemove);
