import React from 'react';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import {
    LINK_SUPPORT_EMAIL,
    LINK_SUPPORT_MOSCOW_TEL,
    LINK_SUPPORT_REGION_TEL,
    MDASH, SUPPORT_EMAIL,
    SUPPORT_MOSCOW_TEL,
    SUPPORT_REGION_TEL,
} from '@ak-front/core/constants';

import { Link } from '@alfalab/core-components/link';
import { TextProps,Typography } from '@alfalab/core-components/typography';

type SupportContactsProps = Pick<TextProps, 'view'>;

/** @deprecated Устаревший компонент */
export const SupportContacts: React.VFC<SupportContactsProps> = ({ view = 'primary-medium' }) => (
    <React.Fragment>
        <Link view="secondary" pseudo={ true } href={ LINK_SUPPORT_REGION_TEL }>
            <Typography.Text view={ view } weight="medium" color="secondary">
                { SUPPORT_REGION_TEL }
            </Typography.Text>
        </Link>
        <Typography.Text view={ view } color="primary">
            { NBSP }
            { MDASH }
            { NBSP }
            для звонков по
            { NBSP }
            России
        </Typography.Text>
        <LineIntent top="xs" bottom="xs">
            <Link view="secondary" pseudo={ true } href={ LINK_SUPPORT_MOSCOW_TEL }>
                <Typography.Text view={ view } weight="medium" color="secondary">
                    { SUPPORT_MOSCOW_TEL }
                </Typography.Text>
            </Link>
            <Typography.Text view={ view } color="primary">
                { NBSP }
                { MDASH }
                { NBSP }
                в
                { NBSP }
                Москве и
                { NBSP }
                за
                { NBSP }
                границей
            </Typography.Text>
        </LineIntent>
        <Link view="secondary" pseudo={ true } href={ LINK_SUPPORT_EMAIL }>
            <Typography.Text view={ view } weight="medium" color="secondary">
                { SUPPORT_EMAIL }
            </Typography.Text>
        </Link>
        <Typography.Text view={ view } color="primary">
            { NBSP }
            { MDASH }
            { NBSP }
            эл.
            { NBSP }
            почта
        </Typography.Text>
    </React.Fragment>
);

SupportContacts.displayName = nameof(SupportContacts);
