import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { subscribePlugin } from '@ak-front/core/client/api/crypto-pro-api';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { TypographyWrapper } from '@ak-front/core/client/components/typography';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import { CoreTasksActions } from '@ak-front/core/client/store/actions/creators';
import { CoreTasksSelectors } from '@ak-front/core/client/store/selectors';
import { useDidMount } from '@ak-front/core/client/utils/hook';
import { validateCertificatesOfClient } from '@ak-front/core/client/validations/certificate';

import { Grid } from '@alfalab/core-components/grid';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { PageExternal } from '#/src/client/pages/external';
import { UserSelectors } from '#/src/client/store/selectors';

import { SignUpCSPErrorBoundary } from './sign-up-csp-error-boundary';
import { SignUpForm } from './sign-up-form';
import { SignUpOffBoundary } from './sign-up-off-boundary';
import { SignUpOtherErrorBoundary } from './sign-up-other-error-boundary';
import { SignUpValidCertificatesEmptyBoundary } from './sign-up-valid-certificates-empty-boundary';

export const SignUpPage: React.VFC = () => {
    const dispatch = useDispatch();

    const [loadingCryptoPlugin, setLoadingCryptoPlugin] = useState<boolean>(true);

    const { inn, snils } = useSelector(
        UserSelectors.getIdentifiersAH,
        (left, right) => left.inn === right.inn && left.snils === right.snils,
    );
    const statusTaskGetUserCertificates = useSelector(CoreTasksSelectors.getStatusTaskGetUserCertificates);

    const waiting = loadingCryptoPlugin || statusTaskGetUserCertificates;

    useDidMount(() => {
        subscribePlugin(() => {
            dispatch(CoreTasksActions.actionTaskGetUserCertificatesRun(
                (certificates) => validateCertificatesOfClient(certificates, inn, snils),
            ));
            setLoadingCryptoPlugin(false);
        });
    });

    return (
        <PageExternal>
            <Space size={ 0 } direction="vertical" fullWidth={ true } wrap={ false }>
                <LineIntent top="6xl" bottom="6xl">
                    <Grid.Row gutter="0" justify="center">
                        <Grid.Col width={ { desktop: '6', tablet: '6', mobile: '12' } }>
                            <SignUpOffBoundary>
                                <SignUpOtherErrorBoundary>
                                    <SignUpCSPErrorBoundary>
                                        <SignUpValidCertificatesEmptyBoundary waiting={ waiting }>
                                            <TypographyWrapper align="left" space="nowrap">
                                                <Typography.TitleResponsive
                                                    tag="h1"
                                                    view="large"
                                                    font="system"
                                                    color="primary"
                                                >
                                                    Регистрация в
                                                    { NBSP }
                                                    Альфа-Кредит
                                                </Typography.TitleResponsive>
                                            </TypographyWrapper>
                                            <SignUpForm waiting={ waiting } />
                                        </SignUpValidCertificatesEmptyBoundary>
                                    </SignUpCSPErrorBoundary>
                                </SignUpOtherErrorBoundary>
                            </SignUpOffBoundary>
                        </Grid.Col>
                    </Grid.Row>
                </LineIntent>
            </Space>
        </PageExternal>
    );
};

SignUpPage.displayName = nameof(SignUpPage);
