import { verifyInputRegex } from '@ak-front/core/constants';
import { object, string } from 'yup';

import { ChangePassword, Credentials, Login, Password } from '#/src/client/types/user';
import { MAX_PASSWORD_LENGTH } from '#/src/shared/constants/user';

export const clearPasswordRegex = /\s+/g;
export const newPasswordConst = 'newPassword';

export const passwordValidationRules = {
    length: {
        value: [8, 40],
        message: 'от 8 до 40 символов',
    },
    latin: {
        value: [/^[a-zA-Z()*!.,+-_@#$%^&}{\][]*$/, /[a-zA-Z]/],
        message: 'Латиница',
    },
    numbers: {
        value: /[0-9]+/,
        message: 'Цифры',
    },
    allowed: {
        value: /[-!.,_@#$%^&]+/,
        message: 'Cимволы: ! . , - _ @ # $ % ^ &',
    },
    forbidden: {
        value: verifyInputRegex,
        message: 'Нет запрещенных символов <>',
    },
    letterCase: {
        value: [/[A-Z]+/, /[a-z]+/],
        message: 'Строчные и заглавные',
    },
    noThreeSymbols: {
        value: /(.)\1\1/,
        message: 'Нет 3х одинаковых символов подряд',
    },
};

export const loginSchema = object<Login>({
    login: string()
        .matches(verifyInputRegex, 'Логин содержит запрещённые символы')
        .max(250, 'Логин превышает допустимую длину')
        .required('Введите логин'),
});

export const passwordSchema = object<Password>({
    password: string()
        .matches(verifyInputRegex, 'Пароль содержит запрещённые символы')
        .max(250, 'Пароль превышает допустимую длину')
        .required('Введите пароль'),
});

export const emailSchema = object<{ email: string }>({
    email: string()
        .email('Введите email в формате ivanivanov@yandex.ru')
        .max(50, 'Email превышает допустимую длину')
        .required('Введите email'),
});

export const credentialsSchema = object<Credentials>({
    login: loginSchema.fields.login,
    password: passwordSchema.fields.password,
});

export const newPasswordSchema = object<Pick<ChangePassword, 'newPassword'>>({
    newPassword: string()
        .test(
            nameof(passwordValidationRules.length),
            passwordValidationRules.length.message,
            (value: string) =>
                value.length >= passwordValidationRules.length.value[0] &&
                value.length <= passwordValidationRules.length.value[1],
        )
        .test(
            nameof(passwordValidationRules.latin),
            passwordValidationRules.latin.message,
            (value: string) =>
                passwordValidationRules.latin.value[0].test(value) &&
                passwordValidationRules.latin.value[1].test(value),
        )
        .test(nameof(passwordValidationRules.numbers), passwordValidationRules.numbers.message, (value?: string) =>
            passwordValidationRules.numbers.value.test(value!),
        )
        .test(nameof(passwordValidationRules.allowed), passwordValidationRules.allowed.message, (value?: string) =>
            passwordValidationRules.allowed.value.test(value!),
        )
        .test(nameof(passwordValidationRules.forbidden), passwordValidationRules.forbidden.message, (value?: string) =>
            passwordValidationRules.forbidden.value.test(value!),
        )
        .test(
            nameof(passwordValidationRules.letterCase),
            passwordValidationRules.letterCase.message,
            (value?: string) =>
                passwordValidationRules.letterCase.value[0].test(value!) &&
                passwordValidationRules.letterCase.value[1].test(value!),
        )
        .test(
            nameof(passwordValidationRules.noThreeSymbols),
            passwordValidationRules.noThreeSymbols.message,
            (value?: string) => !passwordValidationRules.noThreeSymbols.value.test(value!),
        ),
});

export const currentPasswordSchema = object<Pick<ChangePassword, 'currentPassword'>>({
    currentPassword: string()
        .max(MAX_PASSWORD_LENGTH)
        .matches(verifyInputRegex, 'Пароль содержит запрещённые символы')
        .required('Введите пароль'),
});

export const changePasswordSchema = object<Omit<ChangePassword, 'currentPassword'>>({
    newPassword: newPasswordSchema.fields.newPassword,
    confirmPassword: string().test(
        'is-equal',
        'Пароли не совпадают. Попробуйте ввести снова',
        function isEqual(value?: string) {
            const { newPassword } = this.parent as Pick<ChangePassword, 'newPassword'>;

            return value && newPassword ? value === newPassword : false;
        },
    ),
});
