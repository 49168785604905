import React from 'react';
import { TypographyWrapper } from '@ak-front/core/client';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import { LINK_USER_HELP } from '@ak-front/core/constants';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { colorLightGraphicSecondary } from '@alfalab/core-components/vars';
import QuestionCircleSIcon from '@alfalab/icons-glyph/QuestionCircleSIcon';

import { PageExternalFooterDocument } from '#/src/client/pages/external/footer-document';
import { documents } from '#/src/client/utils/dictionary';


export const PageExternalFooter: React.VFC = () => (
    <Grid.Row>
        <Grid.Col width='12'>
            <TypographyWrapper align='center'>
                <Space direction='horizontal' size={24}>
                    <PageExternalFooterDocument {...documents.UsageAgreementLegalEntities} />
                    <PageExternalFooterDocument {...documents.Declaration} />
                    <PageExternalFooterDocument {...documents.Confirmation} />
                </Space>
                <Gap size='m' />
                <Space direction='horizontal' size={16} align='center'>
                    <Typography.Text view='secondary-small' color='secondary'>
                        &copy;
                        {NBSP}
                        {`2001—${new Date().getFullYear()}`}
                        {NBSP}
                        Альфа-Банк.
                        {NBSP}
                        Генеральная лицензия ЦБ РФ №1326.
                    </Typography.Text>
                    <Button
                        leftAddons={<QuestionCircleSIcon color={colorLightGraphicSecondary} />}
                        view='ghost'
                        size='xxs'
                        href={LINK_USER_HELP}
                        target='_blank'
                    >
                        <Typography.Text view='secondary-small' color='secondary' weight='medium'>
                            Помощь
                        </Typography.Text>
                    </Button>
                </Space>
            </TypographyWrapper>
            <Gap size='m' />
        </Grid.Col>
    </Grid.Row>
);

PageExternalFooter.displayName = nameof(PageExternalFooter);
