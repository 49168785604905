/* eslint-disable complexity */
import { Reducer } from 'redux';
import { TaskType } from '@ak-front/core/client/types/task';
import update from 'immutability-helper';

import { TaskAction } from '#/src/client/store/actions/constants/task';
import { TaskActionsType } from '#/src/client/store/actions/creators';

/** Задачи */
export type TaskState = {
    readonly taskSignIn?: TaskType;
    readonly taskSignInCertificate?: TaskType;
    readonly taskSignUpCertificate?: TaskType;
    readonly taskAuthentication?: TaskType;
    readonly taskChangePassword?: TaskType;
    readonly taskSignOut?: TaskType;
    readonly taskSendConfirmation?: TaskType;
    readonly taskCheckConfirmation?: TaskType;
    readonly taskGetListBatchRequest?: TaskType;
    readonly taskGetListBatchMoreRequest?: TaskType;
    readonly taskCreateBatchRequest?: TaskType;
    readonly taskRemoveBatchRequest?: TaskType;
    readonly taskSignAndProcessBatchRequest?: TaskType;
    readonly taskGetBatchRequest?: TaskType;
    readonly taskDownloadErrorsFileBatchRequest?: TaskType;
};
export const defaultTaskState: TaskState = {};

export const tasksReducer: Reducer<TaskState, TaskActionsType> = (
    state = defaultTaskState,
    action: TaskActionsType,
) => {
    switch (action.type) {
        case TaskAction.TASK_SIGN_IN_RUN:
        case TaskAction.TASK_SIGN_IN_STOP:
            return update(state, {
                taskSignIn: { $set: action.payload.task },
            });
        case TaskAction.TASK_SIGN_IN_CERTIFICATE_RUN:
        case TaskAction.TASK_SIGN_IN_CERTIFICATE_STOP:
            return update(state, {
                taskSignInCertificate: { $set: action.payload.task },
            });
        case TaskAction.TASK_SIGN_UP_CERTIFICATE_RUN:
        case TaskAction.TASK_SIGN_UP_CERTIFICATE_STOP:
            return update(state, {
                taskSignUpCertificate: { $set: action.payload.task },
            });
        case TaskAction.TASK_CHANGE_PASSWORD_RUN:
        case TaskAction.TASK_CHANGE_PASSWORD_STOP:
            return update(state, {
                taskChangePassword: { $set: action.payload.task },
            });
        case TaskAction.TASK_SIGN_OUT_RUN:
        case TaskAction.TASK_SIGN_OUT_STOP:
            return update(state, {
                taskSignOut: { $set: action.payload.task },
            });
        case TaskAction.TASK_SEND_CONFIRMATION_RUN:
        case TaskAction.TASK_SEND_CONFIRMATION_STOP:
            return update(state, {
                taskSendConfirmation: { $set: action.payload.task },
            });
        case TaskAction.TASK_CHECK_CONFIRMATION_RUN:
        case TaskAction.TASK_CHECK_CONFIRMATION_STOP:
            return update(state, {
                taskCheckConfirmation: { $set: action.payload.task },
            });
        case TaskAction.TASK_GET_LIST_BHR_RUN:
        case TaskAction.TASK_GET_LIST_BHR_STOP:
            return update(state, {
                taskGetListBatchRequest: { $set: action.payload.task },
            });
        case TaskAction.TASK_LOAD_MORE_BATCHES_RUN:
        case TaskAction.TASK_LOAD_MORE_BATCHES_STOP:
            return update(state, {
                taskGetListBatchMoreRequest: { $set: action.payload.task },
            });
        case TaskAction.TASK_CREATE_BHR_RUN:
        case TaskAction.TASK_CREATE_BHR_STOP:
            return update(state, {
                taskCreateBatchRequest: { $set: action.payload.task },
            });
        case TaskAction.TASK_REMOVE_BHR_RUN:
        case TaskAction.TASK_REMOVE_BHR_STOP:
            return update(state, {
                taskRemoveBatchRequest: { $set: action.payload.task },
            });
        case TaskAction.TASK_GET_BHR_RUN:
        case TaskAction.TASK_GET_BHR_STOP:
            return update(state, {
                taskGetBatchRequest: { $set: action.payload.task },
            });
        case TaskAction.TASK_DOWNLOAD_ERRORS_BHR_RUN:
        case TaskAction.TASK_DOWNLOAD_ERRORS_BHR_STOP:
            return update(state, {
                taskDownloadErrorsFileBatchRequest: { $set: action.payload.task },
            });
        default:
            return state;
    }
};
