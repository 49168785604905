import React from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { LineIntent } from '@ak-front/core/client/components/line-intent';

import { Button } from '@alfalab/core-components/button';

import { StalemateBaseError } from '#/src/client/components/error/stalemate';
import { UserActions } from '#/src/client/store/actions/creators';
import { UserSelectors } from '#/src/client/store/selectors';

export const SignUpOtherErrorBoundary: React.FC<React.PropsWithChildren> = React.memo(({ children }) => {
    const dispatch = useDispatch();

    const userException = useSelector(UserSelectors.getExceptionAH);

    if (userException && !userException.isErrorCryptoProviderForUser) {
        const handleClick = () => dispatch(UserActions.actionExceptionAH(undefined));

        return (
            <StalemateBaseError
                title={ userException.title }
                text={ userException.message }
                code={ userException.requestId }
            >
                <LineIntent top="2xl">
                    <Button
                        id="back"
                        view="secondary"
                        size="s"
                        onClick={ handleClick }
                    >
                        Вернуться назад
                    </Button>
                </LineIntent>
            </StalemateBaseError>
        );
    }

    return <React.Fragment>{ children }</React.Fragment>;
});

SignUpOtherErrorBoundary.displayName = nameof(SignUpOtherErrorBoundary);
