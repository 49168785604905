import { put } from 'redux-saga/effects';
import { GRTasksActions, GRActions } from '@ak-front/guarantee-request/client/store';
import { CoreTasksActions } from '@ak-front/core/client/store';
import { RequestCode } from '@ak-front/core/client/types';

export function* sagaLoadDataForRequestGR(idRequest: string) {
    yield put(GRTasksActions.actionTaskGetGRRun(idRequest));
}

export function* sagaLoadDataForRequestNewGR() {
    yield put(GRTasksActions.actionTaskGetJurisdictionRegionsRun());
    yield put(
        CoreTasksActions.actionTaskGetContractsRun(
            RequestCode.GuaranteeRequest,
            true,
            GRActions.actionSetContractsGR,
            GRActions.actionViewExceptionContractsGR,
        ),
    );
}
