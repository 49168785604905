import React from 'react';

import { Typography } from '@alfalab/core-components/typography';
import { TypographyWrapper } from '@ak-front/core/client/components/typography';

type BatchFillingFileErrorProps = {
    error?: string;
};

export const BatchFillingFileError: React.FC<BatchFillingFileErrorProps> = React.memo(({ error }) => (
    error ? (
        <TypographyWrapper>
            <Typography.Text view="secondary-large" color="negative">
                { error }
            </Typography.Text>
        </TypographyWrapper>
    ) : null
));

BatchFillingFileError.displayName = nameof(BatchFillingFileError);
