import React from 'react';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { TypographyWrapper } from '@ak-front/core/client/components/typography';
import * as ConstHelper from '@ak-front/core/constants';

import { FileUploadItem } from '@alfalab/core-components/file-upload-item';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { IconButton } from '@alfalab/core-components/icon-button';
import { SidePanel as SidePanelResponsive } from '@alfalab/core-components/side-panel';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';
import { Underlay } from '@alfalab/core-components/underlay';
import { CrossHeavyMIcon } from '@alfalab/icons-glyph/CrossHeavyMIcon';
import { DocumentLinesMIcon } from '@alfalab/icons-glyph/DocumentLinesMIcon';

import { AppContacts } from '#/src/client/components/app-contacts';
import { documents, findLastDocumentVersion } from '#/src/client/utils/dictionary';

type AppSidebarSupportProps = {
    visible: boolean;
};

type AppSidebarSupportEvents = {
    onClose: () => void;
};

export const AppSidebarSupport: React.FC<AppSidebarSupportProps & AppSidebarSupportEvents> = React.memo(
    ({ visible, onClose }) => {
        const document = findLastDocumentVersion(documents.InstructionsInstallingPersonalCertificate.versions);

        return (
            <SidePanelResponsive open={visible} placement='right' disableFocusLock={true}>
                <SidePanelResponsive.Header
                    hasCloser={false}
                    leftAddons={<IconButton icon={CrossHeavyMIcon} view='primary' size={24} onClick={onClose} /> }
                />
                <SidePanelResponsive.Content>
                    <TypographyWrapper align='left' space='nowrap'>
                        <Typography.Title view='medium' font='system' color='primary' tag='div'>
                            Помощь по Альфа-Кредиту
                        </Typography.Title>
                    </TypographyWrapper>
                    <LineIntent top='xl'>
                        <Typography.Text view='caps' weight='bold' color='secondary'>
                            Инструкции
                        </Typography.Text>
                    </LineIntent>
                    <LineIntent top='2xl'>
                        <LineIntent>
                            <Underlay padding={{ left: 'xs' }}>
                                <Space direction='horizontal' size={12}>
                                    <DocumentLinesMIcon />
                                    <Link view='primary' target='_blank' href={ConstHelper.LINK_USER_HELP}>
                                        <Typography.Text view='primary-small' color='primary'>
                                            Помощь
                                        </Typography.Text>
                                    </Link>
                                </Space>
                            </Underlay>
                        </LineIntent>
                        <FileUploadItem
                            name={`${document.name}.${document.ext}`}
                            size={document.size}
                            downloadLink={document.relativePath}
                        />
                    </LineIntent>
                    <LineIntent top='4xl'>
                        <Typography.Text view='caps' weight='bold' color='secondary'>
                            Служба поддержки
                        </Typography.Text>
                    </LineIntent>
                    <Gap size='xl' />
                    <AppContacts />
                </SidePanelResponsive.Content>
            </SidePanelResponsive>
        );
    },
);

AppSidebarSupport.displayName = nameof(AppSidebarSupport);
