import React from 'react';
import { useSelector } from 'react-redux';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import { getCertificateGroups } from '@ak-front/core/client/store/selectors/certificate';
import { BoundaryComponent } from '@ak-front/core/client/types/component';

import { Button } from '@alfalab/core-components/button';
import { RepeatMIcon } from '@alfalab/icons-glyph/RepeatMIcon';

import { StalemateBaseError } from '#/src/client/components/error/stalemate';
import { SignOtherCertificates } from '#/src/client/pages/external/sign/other-certificates';

type SignUpValidCertificatesEmptyBoundaryProps = BoundaryComponent;

export const SignUpValidCertificatesEmptyBoundary:
React.FC<React.PropsWithChildren<SignUpValidCertificatesEmptyBoundaryProps>> = React.memo((
    { waiting, children },
) => {
    const [validCertificates, otherCertificates] = useSelector(getCertificateGroups);

    if (!waiting && !validCertificates.length) {
        const element = otherCertificates.length
            ? <SignOtherCertificates certificates={ otherCertificates } /> : null;

        const handleClick = () => window.location.reload();

        return (
            <StalemateBaseError
                title="Нет доступных сертификатов"
                text={ `Нужно установить сертификат электронной подписи. Если сертификат установлен, а${NBSP}ошибка повторяется, обратитесь в${NBSP}поддержку` }
            >
                { element }
                <LineIntent top="2xl">
                    <Button
                        id="update"
                        view="secondary"
                        size="s"
                        leftAddons={ <RepeatMIcon /> }
                        onClick={ handleClick }
                    >
                        Обновить страницу
                    </Button>
                </LineIntent>
            </StalemateBaseError>
        );
    }

    return <React.Fragment>{ children }</React.Fragment>;
});

SignUpValidCertificatesEmptyBoundary.displayName = nameof(SignUpValidCertificatesEmptyBoundary);
