import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { FormControl } from '@ak-front/core/client/types';
import { useDidMount, useScrollToError } from '@ak-front/core/client/utils/hook';
import { validationShallowEqual } from '@ak-front/core/client/validations';
import { GRActions } from '@ak-front/guarantee-request/client';
import { AdditionalDataTenderSubtype as TenderSubtype } from '@ak-front/guarantee-request/client/api/fetch-client';
import {
    NotificationNumberField,
    TenderSubtype44Field,
} from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-additional-data/tender/fields';
import { GRSelectors,GRTasksSelectors } from '@ak-front/guarantee-request/client/store/selectors';
import { fieldsNames } from '@ak-front/guarantee-request/client/validations';

import { Grid } from '@alfalab/core-components/grid';

import { CustomersCountField } from './fields';

const validationErrorFieldsNames = {
    ...fieldsNames.additionalData.tender44,
};

export const BatchFillingAdditionalDataTender44JointPurchasesGR: React.FC<FormControl> = React.memo((
    { waiting, ...rest },
) => {
    const dispatch = useDispatch();

    const additionalData = useSelector(GRSelectors.getAdditionalDataGR);

    const statusTaskGetAndFillTenderInfo = useSelector(GRTasksSelectors.getStatusTaskGetAndFillTenderInfoGR);

    const validationErrors = useSelector(
        GRSelectors.getValidationErrorsGR,
        validationShallowEqual(validationErrorFieldsNames),
    );

    const ref = useScrollToError<HTMLDivElement>(validationErrors, validationErrorFieldsNames);
    const disabled = rest.disabled || statusTaskGetAndFillTenderInfo;

    useDidMount(() => {
        const currentTenderData = additionalData.tender44;
        const tenderData = !currentTenderData
            ? {
                subtype: TenderSubtype.Participation,
                shouldBeSentToEis: true,
                firstRequestCompleted: false,
            } : {
                ...additionalData.tender44,
                firstRequestCompleted: [
                    currentTenderData.notificationNumber,
                    currentTenderData.purchaseIdentificationCode,
                    currentTenderData.subject,
                    currentTenderData.protocolType,
                    currentTenderData.protocolDate,
                    currentTenderData.protocolNumber,
                ].some((f) => Boolean(f)),
            };

        dispatch(GRActions.actionSetAdditionalDataTender44GR(tenderData));
    });

    return (
        <div ref={ ref }>
            <Grid.Row>
                <Grid.Col width={ { desktop: '6', tablet: '6', mobile: '12' } }>
                    <LineIntent top="xl">
                        <Skeleton visible={ waiting } size="4xl">
                            <NotificationNumberField
                                disabled={ disabled }
                                selector={ GRSelectors.get44NotificationNumberGR }
                                action={ GRActions.actionSet44NotificationNumberGR }
                                error={ 
                                    validationErrors[fieldsNames.additionalData.tender44.notificationNumber44] || 
                                    validationErrors[fieldsNames.additionalData.tender44.customersList44]
                                }
                            />
                        </Skeleton>
                    </LineIntent>
                </Grid.Col>
                <Grid.Col width={ { desktop: '6', tablet: '6', mobile: '12' } }>
                    <LineIntent top="xl">
                        <Skeleton visible={ waiting } size="4xl">
                            <TenderSubtype44Field disabled={ disabled } disabledOptions={ [TenderSubtype.Warranties] } />
                        </Skeleton>
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                <Grid.Col width="12">
                    <LineIntent top="xl">
                        <Skeleton visible={ waiting } size="4xl">
                            <CustomersCountField disabled={ disabled } />
                        </Skeleton>
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
        </div>
    );
});

BatchFillingAdditionalDataTender44JointPurchasesGR.displayName = nameof(BatchFillingAdditionalDataTender44JointPurchasesGR);
