import React from 'react';
import { TypographyWrapper } from '@ak-front/core/client';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import { LINK_SUPPORT_EMAIL, MDASH, SUPPORT_EMAIL } from '@ak-front/core/constants/all';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { Modal as ModalResponsive } from '@alfalab/core-components/modal';
import { Typography } from '@alfalab/core-components/typography';

type PasswordRecoveryConfirmationHelpProps = {
    open: boolean;
    onClose: () => void;
};

export const PasswordRecoveryConfirmationHelp: React.VFC<PasswordRecoveryConfirmationHelpProps> = ({
    open,
    onClose,
}) => (
    <ModalResponsive open={open} size='s' onClose={onClose}>
        <ModalResponsive.Header hasCloser={true} />
        <ModalResponsive.Content>
            <TypographyWrapper align='center'>
                <Typography.TitleResponsive tag='h1' view='small' font='system' color='primary'>
                    Не приходит сообщение?
                </Typography.TitleResponsive>
                <Gap size='xl' />
                <Typography.Text view='primary-medium' color='primary'>
                    Напишите на
                    {NBSP}
                    <Link view='default' href={LINK_SUPPORT_EMAIL}>
                        {SUPPORT_EMAIL}
                    </Link>
                    {NBSP}
                    {MDASH}
                    {NBSP}и мы поможем
                </Typography.Text>
                <Gap size='xl' />
                <Typography.Text view='primary-medium' color='primary'>
                    Возможно, перегружен сервис отправки сообщений. Попробуйте повторить действие через несколько минут.
                </Typography.Text>
                <Gap size='2xl' />
                <Button view='secondary' size='s' onClick={onClose}>
                    Вернуться к вводу кода
                </Button>
            </TypographyWrapper>
        </ModalResponsive.Content>
    </ModalResponsive>
);

PasswordRecoveryConfirmationHelp.displayName = nameof(PasswordRecoveryConfirmationHelp);
