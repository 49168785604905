import React from 'react';
import { Route, Switch } from 'react-router';
import { PATH_ROOT_ROUTE } from '@ak-front/core/constants/router';

import { PATH_CHANGE_PASSWORD_ROUTE, PATH_PASSWORD_RECOVERY_ROUTE } from '#/src/client/constants/routes';
import { ChangePasswordPage } from '#/src/client/pages/external/change-password';
import { ErrorPage } from '#/src/client/pages/external/error';
import { PasswordRecoveryPage } from '#/src/client/pages/external/password-recovery';
import { SignInPage } from '#/src/client/pages/external/sign/in';
import { SignUpPage } from '#/src/client/pages/external/sign/up';
import { NotFoundPage } from '#/src/client/pages/internal/not-found';
import { SupportBrowserPage } from '#/src/client/pages/internal/support-browser';
import {
    PATH_ERROR_ROUTE,
    PATH_NOT_FOUND_ROUTE,
    PATH_SUPPORT_BROWSER_ROUTE,
} from '#/src/shared/constants/routes';
import {
    PATH_SIGN_IN_ROUTE,
    PATH_SIGN_UP_ROUTE,
} from '#/src/shared/constants/user';

import HeadPage from './head';

export const Routes: React.FC = () => (
    <Switch>
        <Route exact={ true } path={ PATH_SIGN_IN_ROUTE } component={ SignInPage } />
        <Route exact={ true } path={ PATH_SIGN_UP_ROUTE } component={ SignUpPage } />
        <Route exact={ true } path={ PATH_CHANGE_PASSWORD_ROUTE } component={ ChangePasswordPage } />
        <Route exact={ true } path={ PATH_PASSWORD_RECOVERY_ROUTE } component={ PasswordRecoveryPage } />
        <Route exact={ true } path={ PATH_ERROR_ROUTE } component={ ErrorPage } />
        <Route exact={ true } path={ PATH_NOT_FOUND_ROUTE } component={ NotFoundPage } />
        <Route exact={ true } path={ PATH_SUPPORT_BROWSER_ROUTE } component={ SupportBrowserPage } />
        <Route path={ PATH_ROOT_ROUTE } component={ HeadPage } />
    </Switch>
);

Routes.displayName = nameof(Routes);
