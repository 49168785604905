import { RequestCode } from '@ak-front/core/client/types/request';
import { DeepPartial } from '@ak-front/core/types/utils';
import { GuaranteeRequest } from '@ak-front/guarantee-request/client/types';

import * as ApiClient from '#/src/client/api/fetch-client';

export type BatchRequest = {
    id?: string;
    number?: string;
    requestCode?: RequestCode;
    status?: ApiClient.BatchRequestModelStatus;
    guaranteesCommonData?: GuaranteeRequest;
    createGuaranteeCommands?: GuaranteeRequest[];
    createdDateTime?: Date;
    requestsCount?: number;
    idRequests?: string[];
};

export type BatchRequestErrors = Record<string, string[]>;

export enum BatchFillingGuaranteeType {
    FNS = 'FNS',
    Tender44FZ = 'Tender_44_FZ',
    Rent = 'Rent',
    Tender44FZJointPurchases = 'Tender_44_FZ_Joint_Purchases',
}

export enum BatchRequestFilterStatus {
    All = 'All',
    Draft = 'Draft',
    ToBeSigned = 'ToBeSigned',
    SendToBank = 'SendToBank',
}

export type BatchRequestFilter = {
    status?: BatchRequestFilterStatus;
};

export type BatchRequestsGroupsByStatus = Record<BatchRequestFilterStatus, GuaranteeRequest[]>;

export type BatchRequestForm = {
    requestCode: RequestCode;
    sourceData: Array<Array<string | number | Date | undefined>>;
    guaranteeRequests: Array<DeepPartial<GuaranteeRequest>>;
    guaranteeType?: BatchFillingGuaranteeType;
};

export type BatchRequestGuaranteeFileOptions = {
    type?: ApiClient.GuaranteeRequestModelType;
};

export type BatchRequestFileOptions = BatchRequestGuaranteeFileOptions;
