import { put, select } from 'redux-saga/effects';
import { RequestCode } from '@ak-front/core/client/types/request';
import { DRTasksActions, DRActions, DRSelectors } from '@ak-front/document-request/client/store';
import { CoreTasksActions } from '@ak-front/core/client/store';
import { DocumentRequestResponseStatus } from '@ak-front/document-request/client/api/fetch-client';

export function* sagaLoadDataForRequestDR(idRequest: string) {
    yield put(DRTasksActions.actionTaskGetDRRun(idRequest, function* sagaCallback() {
        const status: DocumentRequestResponseStatus | undefined = yield select(DRSelectors.getStatusDR);
        const idClient: number | undefined = yield select(DRSelectors.getOrganizationIdDR);

        if (status === DocumentRequestResponseStatus.Draft) {
            yield put(CoreTasksActions.actionTaskGetOrganizationsRun(
                DRActions.actionSetOrganizationsDR,
                DRActions.actionViewExceptionOrganizationsDR,
            ));
            yield put(CoreTasksActions.actionTaskGetContractsByOrganizationRun(
                idClient!,
                RequestCode.DocumentRequest,
                false,
                DRActions.actionSetContractsDR,
                DRActions.actionExceptionContractsDR,
            ));
        }
    }));
}
