import React from 'react';
import { useSelector } from 'react-redux';
import { FormControl } from '@ak-front/core/client/types';

import { BHRSelectors } from '#/src/client/store/selectors';
import { BatchFillingGuaranteeType } from '#/src/client/types';

import { BatchFillingAdditionalDataFNSGR } from './batch-filling-additional-data-fns-gr';
import { BatchFillingAdditionalDataRentGR } from './batch-filling-additional-data-rent-gr';
import { BatchFillingAdditionalDataTender44GR } from './batch-filling-additional-data-tender44-gr';
import { BatchFillingAdditionalDataTender44JointPurchasesGR } from './batch-filling-additional-data-tender44-joint-purchases-gr';

export const BatchFillingAdditionalDataGR: React.FC<FormControl> = React.memo(({ waiting, disabled }) => {
    const guaranteeType = useSelector(BHRSelectors.getGuaranteeTypeBHR);

    switch (guaranteeType) {
    case BatchFillingGuaranteeType.FNS:
        return <BatchFillingAdditionalDataFNSGR waiting={ waiting } disabled={ disabled } />;
    case BatchFillingGuaranteeType.Tender44FZ:
        return <BatchFillingAdditionalDataTender44GR waiting={ waiting } disabled={ disabled } />;
    case BatchFillingGuaranteeType.Rent:
        return <BatchFillingAdditionalDataRentGR waiting={ waiting } disabled={ disabled } />;
    case BatchFillingGuaranteeType.Tender44FZJointPurchases:
        return <BatchFillingAdditionalDataTender44JointPurchasesGR waiting={ waiting } disabled={ disabled } />;
    default:
        return null;
    }
});

BatchFillingAdditionalDataGR.displayName = nameof(BatchFillingAdditionalDataGR);
