import React, { useState } from 'react';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { Certificate } from '@ak-front/core/client/types/certificate';

import { Grid } from '@alfalab/core-components/grid';
import { Link } from '@alfalab/core-components/link';

import { OtherCertificatesModal } from '#/src/client/components/modals';

type SignOtherCertificatesProps = {
    waiting?: boolean;
    certificates: Certificate[];
};

export const SignOtherCertificates = React.memo(({ waiting, certificates }: SignOtherCertificatesProps) => {
    const [openOtherCertificates, setOpenOtherCertificates] = useState<boolean>(false);

    const handleOtherCertificates = () => {
        setOpenOtherCertificates(!openOtherCertificates);
    };

    return waiting || Boolean(certificates.length) ? (
        <React.Fragment>
            <Grid.Row gutter="0">
                <Skeleton visible={ waiting }>
                    <Link pseudo={ true } onClick={ handleOtherCertificates }>
                        Показать другие сертификаты
                    </Link>
                </Skeleton>
            </Grid.Row>
            <OtherCertificatesModal
                open={ openOtherCertificates }
                value={ certificates }
                onClose={ handleOtherCertificates }
            />
        </React.Fragment>
    ) : null;
});

SignOtherCertificates.displayName = nameof(SignOtherCertificates);
