import React from 'react';
import { Colors,Icon } from '@ak-front/core/client/components/icon';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { NBSP } from '@ak-front/core/client/constants/formatters';

import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import CrossMIcon from '@alfalab/icons-glyph/CrossMIcon';

import { SupportContacts } from './support-contacts';

type StalemateBaseErrorProps = {
    title: React.ReactNode;
    text: string;
    additionalText?: React.ReactNode;
    code?: string;
};

export const StalemateBaseError: React.FC<React.PropsWithChildren<StalemateBaseErrorProps>> = React.memo(({
    title, code, children, ...rest
}) => {
    const text = rest.text ? (
        <React.Fragment>
            { rest.text }
            <br />
        </React.Fragment>
    )
        : null;

    const additionalText = rest.additionalText ? (
        <React.Fragment>
            { rest.additionalText }
            <br />
        </React.Fragment>
    )
        : null;

    const errorCode = code ? (
        <LineIntent top="2xl">
            <Typography.Text view="primary-medium" color="secondary">
                Код ошибки:
                { NBSP }
                { code }
            </Typography.Text>
        </LineIntent>
    ) : null;

    const elements = !code && Boolean(React.Children.count(children)) ? (
        <LineIntent top="2xl">
            { children }
        </LineIntent>
    ) : children;

    return (
        <React.Fragment>
            <SuperEllipse size={ 80 } backgroundColor={ Colors.tertiary }>
                <Icon size="6xl" color="primary-inverted" icon={ CrossMIcon } />
            </SuperEllipse>
            <LineIntent top="xl">
                <Typography.TitleResponsive tag="h1" view="small" color="primary">
                    { title }
                </Typography.TitleResponsive>
                <LineIntent top="m">
                    <Typography.Text view="primary-medium" color="primary">
                        { text }
                        { additionalText }
                        <LineIntent top="s">
                            <SupportContacts />
                        </LineIntent>
                    </Typography.Text>
                </LineIntent>
                { errorCode }
                { elements }
            </LineIntent>
        </React.Fragment>
    );
});

StalemateBaseError.displayName = nameof(StalemateBaseError);
