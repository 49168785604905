import React from 'react';

import { Checkbox } from '@alfalab/core-components/checkbox';
import { Typography } from '@alfalab/core-components/typography';

type CheckboxWithErrorProps = {
    checked: boolean;
    label?: React.ReactNode;
    disabled?: boolean;
    error?: string;
};

type CheckboxWithErrorEvents = {
    onChange?: (event?: React.ChangeEvent<HTMLInputElement>, payload?: {
        checked: boolean;
        name?: string | undefined;
    }) => void;
};

export const CheckboxWithError = React.memo(({
    checked, label, disabled, error, onChange,
}: CheckboxWithErrorProps & CheckboxWithErrorEvents) => {
    const errorElement = error ? (
        <React.Fragment>
            <br />
            <Typography.Text view="primary-small" color="negative">
                { error }
            </Typography.Text>
        </React.Fragment>
    ) : null;

    const labelElement = (
        <React.Fragment>
            <Typography.Text view="primary-medium" monospaceNumbers={ true } color="primary">
                { label }
            </Typography.Text>
            { errorElement }
        </React.Fragment>
    );

    return (
        <Checkbox
            name="confirmation"
            label={ labelElement }
            checked={ checked }
            disabled={ disabled }
            onChange={ onChange }
        />
    );
});

CheckboxWithError.displayName = nameof(CheckboxWithError);
