import React from 'react';
import { NBSP } from '@ak-front/core/client/constants/formatters';

import { formatDate } from '@alfalab/core-components/calendar-input/utils';
import { Collapse } from '@alfalab/core-components/collapse';
import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import {
    colorLightBgPrimary,
    colorLightGraphicPrimary,
    colorLightGraphicSecondary,
} from '@alfalab/core-components/vars';
import { DocumentStampMIcon } from '@alfalab/icons-glyph/DocumentStampMIcon';

import './index.css';

type CertificateAttribute = {
    cn?: string;
};

type Certificate = {
    thumbprint?: string;
    issuerAttributes: CertificateAttribute;
    subjectAttributes: CertificateAttribute;
    validFromDate?: Date;
    validToDate?: Date;
};

type CertificateGroupProps = {
    dataTestId?: string;
    value: Certificate[];
    disabled?: boolean;
    onClick?: (event: React.MouseEvent, payload: { thumbprint?: string }) => void;
};

export const CertificateGroup = React.memo(({ dataTestId, value = [], disabled, onClick }: CertificateGroupProps) => {
    const items = value.map((m, index) => {
        const date = m.validToDate
            ? `действует до ${formatDate(m.validToDate, 'd MMMM yyyy')} года`
            : 'срок действия не определен';

        const handleClick = (event: React.MouseEvent) => {
            onClick?.(event, { thumbprint: m.thumbprint });
        };

        return (
            <div key={m.thumbprint} role='button' onClick={handleClick} tabIndex={index}>
                <GenericWrapper
                    key={m.thumbprint}
                    className={`certificate-group__item ${disabled ? 'certificate-group__item_disabled' : ''}`}
                >
                    <SuperEllipse size={48} backgroundColor={colorLightBgPrimary}>
                        <DocumentStampMIcon color={disabled ? colorLightGraphicSecondary : colorLightGraphicPrimary} />
                    </SuperEllipse>
                    <Gap size='m' direction='horizontal' />
                    <GenericWrapper column={true}>
                        <Typography.Text view='primary-medium' weight='bold' color={disabled ? 'secondary' : 'primary'}>
                            {m.subjectAttributes.cn}
                        </Typography.Text>
                        <Typography.Text view='secondary-large' color='secondary'>
                            Выдан
                            {NBSP}
                            {m.issuerAttributes.cn},{NBSP}
                            {date}
                        </Typography.Text>
                    </GenericWrapper>
                </GenericWrapper>
                {index === value.length ? null : <Gap size='m' />}
            </div>
        );
    });

    return value.length > 3 ? (
        <React.Fragment>
            <GenericWrapper dataTestId={dataTestId} column={true}>
                {items.slice(0, 3)}
            </GenericWrapper>
            <Collapse className='certificate-group__collapse' collapsedLabel='Развернуть' expandedLabel='Свернуть'>
                <GenericWrapper column={true}>{items.slice(3)}</GenericWrapper>
            </Collapse>
        </React.Fragment>
    ) : (
        <GenericWrapper dataTestId={dataTestId} column={true}>
            {items}
        </GenericWrapper>
    );
});

CertificateGroup.displayName = nameof(CertificateGroup);
