import React from 'react';
import { TValidationProblems } from '@ak-front/core/client/types/validation';

import { Gap } from '@alfalab/core-components/gap';
import { Notification } from '@alfalab/core-components/notification';
import { PasswordInput, PasswordInputProps } from '@alfalab/core-components/password-input';
import { Tooltip as TooltipResponsive } from '@alfalab/core-components/tooltip';

import { ChangePasswordFormState } from '#/src/client/components/forms/change-password/hooks';
import { PasswordRulesValidation } from '#/src/client/pages/external/password-recovery/change-password/rules-validation';
import { ChangePassword } from '#/src/client/types/user';

import './index.css';

type ChangePasswordFormProps = {
    disabled?: boolean;
    state: ChangePasswordFormState;
    openChipsValidation: boolean;
    openNotification: boolean;
    statuses: Record<string, { valid?: boolean; message: string }>;
    onChangeNewPassword?: PasswordInputProps['onChange'];
    onFocusNewPassword?: PasswordInputProps['onFocus'];
    onBlurNewPassword?: PasswordInputProps['onBlur'];
    onChangeConfirmPassword?: PasswordInputProps['onChange'];
    onFocusConfirmPassword?: PasswordInputProps['onFocus'];
    onCloseNotification?: () => void;
};

const validationFields = {
    confirmPassword: nameof<ChangePassword>((s) => s.confirmPassword),
};

const getErrorMessage = (validationProblem: TValidationProblems[0]) => {
    const [error] = validationProblem?.error || [];

    return error?.message;
};

export const ChangePasswordForm: React.VFC<ChangePasswordFormProps> = ({
    state,
    openChipsValidation,
    openNotification,
    statuses,
    disabled,
    onChangeNewPassword: handleChangeNewPassword,
    onFocusNewPassword: handleFocusNewPassword,
    onBlurNewPassword: handleBlurNewPassword,
    onChangeConfirmPassword: handleChangeConfirmPassword,
    onFocusConfirmPassword: handleFocusConfirmPassword,
    onCloseNotification: handleCloseNotification,
}) => (
    <React.Fragment>
        <TooltipResponsive
            targetClassName='form-change-password__tooltip'
            open={openChipsValidation}
            content={<PasswordRulesValidation statuses={statuses} dataTestId='password-chips-validation' />}
            position='right-start'
        >
            <PasswordInput
                id='new-password'
                size='m'
                block={true}
                label='Новый пароль'
                value={state.newPassword}
                disabled={disabled}
                autoComplete='off'
                error={state.errorMessage}
                onChange={handleChangeNewPassword}
                onFocus={handleFocusNewPassword}
                onBlur={handleBlurNewPassword}
            />
        </TooltipResponsive>
        <Gap size='m' />
        <PasswordInput
            id='confirm-password'
            size='m'
            block={true}
            label='Повторите пароль'
            value={state.confirmPassword}
            disabled={disabled}
            autoComplete='off'
            error={getErrorMessage(state.validationProblems[validationFields.confirmPassword])}
            onChange={handleChangeConfirmPassword}
            onFocus={handleFocusConfirmPassword}
        />
        <Notification
            visible={openNotification}
            title='Не получилось отправить данные'
            badge='negative'
            autoCloseDelay={5000}
            onClose={handleCloseNotification}
            onCloseTimeout={handleCloseNotification}
        >
            Уже исправляем. Попробуйте еще раз или зайдите позже
        </Notification>
    </React.Fragment>
);

ChangePasswordForm.displayName = nameof(ChangePasswordForm);
