import React from 'react';

import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import { LineIntent } from '@ak-front/core/client/components/line-intent';

type UserAttributeProps = {
    name: string;
    value?: React.ReactNode;
};

export const UserAttribute: React.VFC<UserAttributeProps> = ({ name, value }): JSX.Element => (
    <LineIntent top="xl">
        <Grid.Row>
            <Grid.Col width="auto">
                <Typography.Text view="secondary-large" weight="medium" color="secondary">
                    { name }
                </Typography.Text>
            </Grid.Col>
        </Grid.Row>
        <LineIntent top="2xs">
            <Grid.Row>
                <Grid.Col width="auto">
                    <Typography.Text view="primary-medium" color="primary">
                        { value }
                    </Typography.Text>
                </Grid.Col>
            </Grid.Row>
        </LineIntent>
    </LineIntent>
);

UserAttribute.displayName = nameof(UserAttribute);
