import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';
import { Tag } from '@alfalab/core-components/tag';

import { FormControl } from '@ak-front/core/client/types';

import { FormPlate } from '@ak-front/core/client/components/forms';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { RadioGroup } from '@alfalab/core-components/radio-group';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import { batchFilterStatusDictionary } from '#/src/client/utils/dictionary';
import { BatchRequestFilterStatus } from '#/src/client/types';
import { BHRActions } from '#/src/client/store/actions/creators';
import { BHRSelectors } from '#/src/client/store/selectors';

type BatchViewFilterProps = FormControl & {
    requestsCount: Record<BatchRequestFilterStatus, number>;
}

export const BatchViewFilter: React.FC<BatchViewFilterProps> = React.memo(({ waiting, disabled, requestsCount = [] }) => {
    const dispatch = useDispatch();

    const filterStatus = useSelector(BHRSelectors.getFilterStatusBHR);

    const tags = batchFilterStatusDictionary.map(({ value, text }) => (
        <Tag key={ value } value={ value } disabled={ disabled } variant="alt">
            { text }
            { NBSP }
            { requestsCount[value] }
        </Tag>
    ));

    const handleSelectFilterStatus = useCallback((
        _?: React.ChangeEvent | React.MouseEvent,
        payload?: { value: string; name?: string },
    ) => {
        const value = payload?.value as BatchRequestFilterStatus;

        if (value && value !== filterStatus) {
            dispatch(BHRActions.actionSetFilterStatusBHR(value));
        }
    }, [filterStatus]);

    return (
        <FormPlate gap="m">
            <Grid.Row>
                <Grid.Col width="auto">
                    <LineIntent>
                        <Skeleton visible={ waiting } width="available">
                            <RadioGroup
                                direction="horizontal"
                                type="tag"
                                value={ filterStatus }
                                disabled={ disabled }
                                dataTestId="batch-filter-status"
                                onChange={ handleSelectFilterStatus }
                            >
                                { tags }
                            </RadioGroup>
                        </Skeleton>
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
        </FormPlate>
    );
});

BatchViewFilter.displayName = nameof(BatchViewFilter);
