import React from 'react';
import { Icon } from '@ak-front/core/client/components/icon';
import { RequestCode } from '@ak-front/core/client/types/request';
import { formatDate } from '@ak-front/core/client/utils/date';
import { UtilGR } from '@ak-front/guarantee-request/client/utils/request';

import { Grid } from '@alfalab/core-components/grid';
import { Status } from '@alfalab/core-components/status';
import { Table } from '@alfalab/core-components/table';
import DocumentLineMIcon from '@alfalab/icons-glyph/DocumentLineMIcon';

import { Shape } from '#/src/client/components/shape';
import { BatchRequest } from '#/src/client/types';
import { UtilBHR } from '#/src/client/utils/batch';
import { getRequestStatus, requestStatusAll } from '#/src/client/utils/dictionary';

import './index.css';

type BatchListItemProps = {
    item: BatchRequest;
};

type BatchListItemEvents = {
    onGoToItem: (id: string) => void;
};

export const BatchListItem: React.FC<BatchListItemProps & BatchListItemEvents> = React.memo((
    { item = {}, onGoToItem },
) => {
    const requestData = UtilBHR.getRequestData(item);
    const statusInfo = getRequestStatus(RequestCode.BatchRequest, requestStatusAll[item.status!]);

    const handleItemClick = () => {
        if (item.id) {
            onGoToItem(item.id);
        }
    };

    return (
        <Table.TRow className="batch-list-item" onClick={ handleItemClick }>
            <Table.TCell>
                <Grid.Row align="middle" justify="left">
                    <Grid.Col width="auto">
                        <div style={ { width: 40, height: 40, display: 'flex' } }>
                            <Shape
                                justify="middle"
                                align="middle"
                                radius="circle"
                                color="secondary"
                                size="xs"
                            >
                                <Icon icon={ DocumentLineMIcon } color="secondary" size="xl" />
                            </Shape>
                        </div>
                    </Grid.Col>
                    { item?.number ? `№ ${item.number}` : '' }
                </Grid.Row>
            </Table.TCell>
            <Table.TCell>
                { formatDate(item.createdDateTime, 'D MMMM YYYY HH:mm') }
            </Table.TCell>
            <Table.TCell>
                { requestData?.principalInfo?.name }
            </Table.TCell>
            <Table.TCell>
                { UtilGR.getTypeTitle(requestData?.type) }
            </Table.TCell>
            <Table.TCell>
                <Status dataTestId="bhr-request-status" view="soft" color={ statusInfo.color }>
                    { statusInfo.text }
                </Status>
            </Table.TCell>
        </Table.TRow>
    );
});

BatchListItem.displayName = nameof(BatchListItem);
