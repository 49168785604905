import React from 'react';

import './index.css';

type ShadowType = 'xs' | undefined;
type ColorType = 'primary' | 'secondary' | undefined;
type RadiusType = 's' | 'm' | 'l' | 'xl' | 'circle' | 'pill' | undefined;
type WidthType = 'available' | undefined;
type HeightType = 'available' | undefined;
type JustifyType = 'top' | 'middle' | 'bottom' | undefined;
type AlignType = 'top' | 'middle' | 'bottom' | undefined;
type SizeType = 'xs' | undefined;

type ShapeProps = {
    id?: string;
    className?: string;
    height?: HeightType;
    width?: WidthType;
    radius?: RadiusType;
    shadow?: ShadowType;
    color?: ColorType;
    justify?: JustifyType;
    align?: AlignType;
    size?: SizeType;
};

/** @deprecated use client/components/shape */
export const Shape: React.FC<React.PropsWithChildren<ShapeProps>> = (props) => {
    const radius = props.radius ? ` shape_radius_${props.radius}` : '';
    const shadow = props.shadow ? ` shape_shadow_${props.shadow}` : '';
    const color = props.color ? ` shape_color_${props.color}` : '';
    const height = props.height ? ` shape_height_${props.height}` : '';
    const width = props.width ? ` shape_width_${props.width}` : '';
    const justify = props.justify ? ` shape_justify_${props.justify}` : '';
    const align = props.align ? ` shape_align_${props.align}` : '';
    const size = props.size ? ` shape_size_${props.size}` : '';
    const includeClassName = props.className ? ` ${props.className}` : '';

    const className = `shape${radius}${shadow}${color}${height}${width}${justify}${align}${size}${includeClassName}`;

    return (
        <div id={ props.id } className={ className }>
            { props.children }
        </div>
    );
};

/** @deprecated use client/components/shape */
type SizeTypeOld = 's' | 'm' | 'l' | 'xl' | 'xxl' | undefined;
/** @deprecated use client/components/shape */
type ColorTypeOld = 'white' | 'gray' | 'palegray' | 'red' | 'palered' | undefined;
/** @deprecated use client/components/shape */
type ShapeOldType = 'square' | 'circle' | 'ellipse' | undefined;

type ShapeOldProps = {
    id?: string;
    className?: string;
    type: ShapeOldType;
    size: SizeTypeOld;
    color?: ColorTypeOld;
};

/** @deprecated use client/components/shape */
export const ShapeOld: React.FC<React.PropsWithChildren<ShapeOldProps>> = React.memo((props) => {
    const type = ` shape-old_${props.type}`;
    const size = ` shape-old_${props.type}_size_${props.size}`;
    const color = props.color ? ` shape-old_color_${props.color}` : '';
    const includeClassName = props.className ? ` ${props.className}` : '';

    return (
        <div id={ props.id } className={ `shape-old${type}${size}${color}${includeClassName}` }>
            { props.children }
        </div>
    );
});
