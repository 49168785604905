import { call, put, select } from 'redux-saga/effects';
import { CoreTasksActions } from '@ak-front/core/client/store/actions';
import { listenLocationChange } from '@ak-front/core/client/store/sagas/history';
import { LocationChangedArgs } from '@ak-front/core/client/types/history';
import { RequestCode } from '@ak-front/core/client/types/request';
import { getIdFromUrl } from '@ak-front/core/client/utils/router';
import { GRSelectors } from '@ak-front/guarantee-request/client';
import { GRActions, GRTasksActions } from '@ak-front/guarantee-request/client/store/actions';
import { UtilGR } from '@ak-front/guarantee-request/client/utils';

import { PATH_REQUEST_BATCH_LIST_ROUTE,PATH_REQUEST_BATCH_ROUTE_WITH_ID } from '#/src/client/constants/routes';
import { BHRActions, TaskActions } from '#/src/client/store/actions/creators';
import { getIsLoadedBHR } from '#/src/client/store/selectors/request/batch';
import { BatchFillingGuaranteeType } from '#/src/client/types';
import {
    PATH_REQUEST_BATCH_ROUTE,
} from '#/src/shared/constants/routes';

export function* sagaCheckUrlForBHRId(action: LocationChangedArgs) {
    const id: string | undefined = yield call(
        getIdFromUrl,
        action.location.pathname,
        PATH_REQUEST_BATCH_ROUTE_WITH_ID,
    );

    if (!id || action.location.pathname === PATH_REQUEST_BATCH_LIST_ROUTE) { return; }

    const batchRequestLoaded = yield select(getIsLoadedBHR, id);

    if (batchRequestLoaded) { return; }

    yield put(TaskActions.actionTaskGetBHRRun(id, true));
    yield put(CoreTasksActions.actionTaskGetContractsRun(
        RequestCode.GuaranteeRequest,
        true,
        GRActions.actionSetContractsGR,
        GRActions.actionViewExceptionContractsGR,
    ));
}

export function* sagaCheckUrlForBHR(action: LocationChangedArgs) {
    if (action.location.pathname !== PATH_REQUEST_BATCH_ROUTE) { return; }

    yield put(GRTasksActions.actionTaskGetJurisdictionRegionsRun());
    yield put(
        CoreTasksActions.actionTaskGetContractsRun(
            RequestCode.GuaranteeRequest,
            true,
            GRActions.actionSetContractsGR,
            GRActions.actionViewExceptionContractsGR,
        ),
    );

    // При переходе из заявления на гарантию с типом 44-ФЗ, считаем по умолчанию, что это 44-ФЗ (сомвестные закупки)
    const guaranteeType = yield select(GRSelectors.getTypeGR);

    if (guaranteeType) {
        if (UtilGR.isType44FZ(guaranteeType)) {
            yield put(BHRActions.actionSetGuaranteeTypeBHR(BatchFillingGuaranteeType.Tender44FZJointPurchases));
        }
    }
}

export function* sagaCheckUrlForBHRList(action: LocationChangedArgs) {
    if (action.location.pathname !== PATH_REQUEST_BATCH_LIST_ROUTE) { return; }

    yield put(TaskActions.actionTaskGetListBHRRun());
}

export default function* root() {
    yield listenLocationChange(sagaCheckUrlForBHR);
    yield listenLocationChange(sagaCheckUrlForBHRId);
    yield listenLocationChange(sagaCheckUrlForBHRList);
}
