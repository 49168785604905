import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { MDASH } from '@ak-front/core/constants/all';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { InternationalPhoneInput } from '@alfalab/core-components/international-phone-input';
import { Typography } from '@alfalab/core-components/typography';

import { SendConfirmationRequestChannel } from '#/src/client/api/fetch-client/fetch-client-dto';
import { PasswordRecoveryChangePassword } from '#/src/client/pages/external/password-recovery/change-password';
import { PasswordRecoveryConfirmation } from '#/src/client/pages/external/password-recovery/confirmation';
import { PasswordRecoveryNavigation } from '#/src/client/pages/external/password-recovery/navigation';
import { TaskActions } from '#/src/client/store/actions/creators';
import { getStatusTaskSendConfirmation } from '#/src/client/store/selectors/task';
import { formatConfirmationPhone } from '#/src/client/utils/user';

enum Step {
    SendConfimation = 'SendConfimation',
    CheckConfirmation = 'CheckConfirmation',
    ChangePassword = 'ChangePassword',
}

type PasswordRecoveryEmailProps = {
    onNavigationBack: () => void;
};

export const PasswordRecoveryPhone: React.VFC<PasswordRecoveryEmailProps> = ({ onNavigationBack }) => {
    const dispatch = useDispatch();

    const [step, setStep] = useState<Step>(Step.SendConfimation);
    const [phone, setPhone] = useState<string>('');

    const statusTaskSendConfirmation = useSelector(getStatusTaskSendConfirmation);

    const handleChange = (value: string) => setPhone(value);

    const handleClick = () => {
        dispatch(
            TaskActions.actionTaskSendConfirmationRun(
                SendConfirmationRequestChannel.Phone,
                formatConfirmationPhone(phone),
                () => setStep(Step.CheckConfirmation),
            ),
        );
    };

    const handleBack = () => {
        switch (step) {
            case Step.SendConfimation:
                onNavigationBack();
                break;
            case Step.CheckConfirmation:
                setStep(Step.SendConfimation);
                break;
        }
    };

    const handleConfirmationSuccess = () => setStep(Step.ChangePassword);

    switch (step) {
        case Step.SendConfimation:
            return (
                <PasswordRecoveryNavigation onBack={handleBack}>
                    <Typography.TitleResponsive tag='h1' view='medium' color='primary' font='system'>
                        Восстановление данных по номеру телефона
                    </Typography.TitleResponsive>
                    <Gap size='m' />
                    <Typography.Text view='primary-medium' color='primary'>
                        Введите номер телефона, который указан в профиле в Альфа-Кредите
                    </Typography.Text>
                    <Gap size='2xl' />
                    <InternationalPhoneInput
                        label='Номер телефона'
                        value={phone}
                        clearableCountryCode={true}
                        size='m'
                        block={true}
                        onChange={handleChange}
                    />
                    <Gap size='xl' />
                    <Button
                        value={step}
                        view='primary'
                        size='m'
                        block={true}
                        loading={statusTaskSendConfirmation}
                        onClick={handleClick}
                    >
                        Продолжить
                    </Button>
                </PasswordRecoveryNavigation>
            );
        case Step.CheckConfirmation:
            return (
                <PasswordRecoveryNavigation onBack={handleBack}>
                    <PasswordRecoveryConfirmation
                        channel={SendConfirmationRequestChannel.Phone}
                        title='Введите код из сообщения'
                        text={`Отправили код на ${phone}. Если смс не пришла, вернитесь назад, проверьте номер телефона и попробуйте отправить смс еще раз`}
                        value={formatConfirmationPhone(phone)}
                        onSuccess={handleConfirmationSuccess}
                    />
                </PasswordRecoveryNavigation>
            );
        case Step.ChangePassword:
            return (
                <PasswordRecoveryChangePassword
                    text={`Отправили логин на email, а временный пароль в ${MDASH} смс. На следующем шаге пароль нужно изменить на постоянный`}
                />
            );
        default:
            return null;
    }
};

PasswordRecoveryPhone.displayName = nameof(PasswordRecoveryPhone);
