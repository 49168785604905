import React from 'react';

import { FormControl } from '@ak-front/core/client/types';
import { GuaranteeFillingConditions } from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-conditions';

export const BatchFillingConditionsGR: React.FC<FormControl> = React.memo(({ waiting, disabled }) => (
    <GuaranteeFillingConditions waiting={ waiting } disabled={ disabled } />
));

BatchFillingConditionsGR.displayName = nameof(BatchFillingConditionsGR);
