export const DEFAULT_ACCESSION_AGREEMENT_NAME = 'Соглашение о подключении';

/**
 * Названия прав доступа
 */
/** @constant {string} - Запрет кредитов */
export const DISALLOW_RIGHTS_CREDIT_REQUEST_ALL = 'CreditRequest.All';
/** @constant {string} - Запрет создания и редактирования заявлений на кредиты */
export const DISALLOW_RIGHTS_CREDIT_REQUEST_CREATE_OR_EDIT = 'CreditRequest.CreateOrEdit';
/** @constant {string} - Запрет гарантий */
export const DISALLOW_RIGHTS_GUARANTEE_REQUEST_ALL = 'GuaranteeRequest.All';
/** @constant {string} - Запрет создания и редактирования заявлений на гарантии */
export const DISALLOW_RIGHTS_GUARANTEE_REQUEST_CREATE_OR_EDIT = 'GuaranteeRequest.CreateOrEdit';
// TODO импортировать эту константу из пакета bank-request (когда он вольется в ufr-ak-ui)
/** @constant {string} - Запрет подписания документов банка */
export const DISALLOW_RIGHTS_BANK_REQUEST_SIGN = 'BankRequest.Sign';
