import React from 'react';
import { Grid } from '@alfalab/core-components/grid';

import { FormControl } from '@ak-front/core/client/types';
import { GuaranteeFillingTextAdditionalConditions } from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-text/guarantee-filling-text-additional-conditions';

export const BatchFillingTextGR: React.FC<FormControl> = React.memo((
    { disabled, waiting },
) => (
    <Grid.Row>
        <Grid.Col width={ { desktop: '12', tablet: { s: '12', m: '8' }, mobile: '12' } }>
            <GuaranteeFillingTextAdditionalConditions waiting={ waiting } disabled={ disabled } />
        </Grid.Col>
    </Grid.Row>
));

BatchFillingTextGR.displayName = nameof(BatchFillingTextGR);
