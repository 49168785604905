import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';

import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';

import { fieldsNames } from '@ak-front/guarantee-request/client/validations';
import { useScrollToError } from '@ak-front/core/client/utils/hook';

import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { validationShallowEqual } from '@ak-front/core/client/validations';
import { Dictionary, FormControl } from '@ak-front/core/client/types';
import { CoreCurrencyUtils } from '@ak-front/core/client/utils';
import {
    FeePercentField,
    FeePeriodField,
} from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-fee/fields';

type BatchGuaranteeFillingFeeControlsProps = FormControl & {
    feePeriods?: Dictionary;
    minSum: number;
    currencyCode?: CoreCurrencyUtils.Currency;
};

export const BatchGuaranteeFillingFeeControls: React.FC<BatchGuaranteeFillingFeeControlsProps> = React.memo(({
    waiting, disabled, feePeriods = [],
}) => {
    const validationErrors = useSelector(GRSelectors.getValidationErrorsGR, validationShallowEqual(fieldsNames.fee));
    const refFee = useScrollToError<HTMLDivElement>(validationErrors, fieldsNames.fee);

    return (
        <div ref={ refFee }>
            <Grid.Row>
                <Grid.Col width="12">
                    <Grid.Row>
                        <Grid.Col width={ { desktop: '6', tablet: '6', mobile: '12' } }>
                            <LineIntent top="xl">
                                <Skeleton visible={ waiting } size="4xl">
                                    <FeePercentField
                                        waiting={ waiting }
                                        disabled={ disabled }
                                        error={ validationErrors[fieldsNames.fee.feePercent] }
                                    />
                                </Skeleton>
                            </LineIntent>
                        </Grid.Col>
                        <Grid.Col width={ { desktop: '6', tablet: '6', mobile: '12' } }>
                            <LineIntent top="xl">
                                <Skeleton visible={ waiting } size="4xl">
                                    <FeePeriodField
                                        waiting={ waiting }
                                        disabled={ disabled }
                                        feePeriods={ feePeriods }
                                        error={ validationErrors[fieldsNames.fee.feePeriod] }
                                    />
                                </Skeleton>
                            </LineIntent>
                        </Grid.Col>
                    </Grid.Row>
                </Grid.Col>
            </Grid.Row>
        </div>
    );
});

BatchGuaranteeFillingFeeControls.displayName = nameof(BatchGuaranteeFillingFeeControls);
