import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TypographyWrapper } from '@ak-front/core/client';
import { LINK_USER_HELP } from '@ak-front/core/constants/all';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { Modal as ModalResponsive } from '@alfalab/core-components/modal';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { AppContacts } from '#/src/client/components/app-contacts';
import { PATH_PASSWORD_RECOVERY_ROUTE } from '#/src/client/constants/routes';

export const SignInFormHelp: React.VFC = () => {
    const history = useHistory();

    const [open, setOpen] = useState<boolean>(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    const handleClickRecoveryPassword = () => history.push(PATH_PASSWORD_RECOVERY_ROUTE);

    return (
        <Space direction='horizontal' fullWidth={true}>
            <Button size='xs' view='ghost' block={true} onClick={handleClickRecoveryPassword}>
                <TypographyWrapper align='left'>
                    <Typography.Text view='primary-small' weight='medium' color='primary'>
                        Забыли логин или пароль?
                    </Typography.Text>
                </TypographyWrapper>
            </Button>
            <Button size='xs' view='ghost' block={true} onClick={handleOpen}>
                <TypographyWrapper align='right'>
                    <Typography.Text view='primary-small' weight='medium' color='primary'>
                        Помощь
                    </Typography.Text>
                </TypographyWrapper>
            </Button>
            <ModalResponsive open={open} size='s' onClose={handleClose}>
                <ModalResponsive.Header title='Помощь' hasCloser={true} />
                <ModalResponsive.Content>
                    <Link view='primary' href={LINK_USER_HELP} target='_blank'>
                        Ответы на часто задаваемые вопросы
                    </Link>
                    <Gap size='2xl' />
                    <AppContacts />
                </ModalResponsive.Content>
            </ModalResponsive>
        </Space>
    );
};

SignInFormHelp.displayName = nameof(SignInFormHelp);
