/* eslint-disable no-undef */
import React from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { FileUploadItem } from '@alfalab/core-components/file-upload-item';

import { FormControl } from '@ak-front/core/client/types';
import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';
import { GRActions } from '@ak-front/guarantee-request/client/store/actions';

import { useDidUpdate, useScrollToError } from '@ak-front/core/client/utils/hook';
import { getAcceptExtensions } from '@ak-front/core/client/utils/file';

import { FileInfoAttachmentModelType } from '@ak-front/core/client/api/fetch-client';
import { RequestCode } from '@ak-front/core/client/types/request';

import { CoreFileSelectors } from '@ak-front/core/client/store/selectors';
import { CoreTasksActions, CoreFileActions } from '@ak-front/core/client/store/actions/creators';

import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { AttachFile } from '@ak-front/core/client/components/attach-file';
import { validationShallowEqual } from '@ak-front/core/client/validations';
import { batchFieldsNames } from '#/src/client/validations';
import { BHRActions, TaskActions } from '#/src/client/store/actions/creators';
import { BHRSelectors, TaskSelectors } from '#/src/client/store/selectors';

import {
    REGISTRY_GUARANTEE_FILE_MAX_SIZE,
    REGISTRY_GUARANTEE_FILE_ACCEPT_EXTENSIONS,
} from '#/src/client/constants/batch';

import { BatchFillingFileDescription } from './batch-fiiling-gr-file-description';
import { BatchFillingFileError } from './batch-fiiling-gr-file-error';

const fileError: React.ReactNode = (
    <LineIntent top="2xs">
        При проверке файла обнаружены ошибки.
        Ошибки отмечены в реестре красным цветом – скачайте файл с ошибками, проверьте их и исправьте
    </LineIntent>
);

const validationFields = {
    file: batchFieldsNames.file,
};

export const BatchFillingFile: React.FC<FormControl> = React.memo(({ disabled }) => {
    const dispatch = useDispatch();

    const type = useSelector(GRSelectors.getTypeGR);
    const errors = useSelector(BHRSelectors.getErrorsBHR);

    const [file] = useSelector(CoreFileSelectors.getAttachedFilesFS);

    const statusTaskCreateBatchRequest = useSelector(TaskSelectors.getStatusTaskCreateBHR);

    const validationErrors = useSelector(GRSelectors.getValidationErrorsGR, validationShallowEqual(validationFields));
    const ref = useScrollToError<HTMLDivElement>(validationErrors, validationFields);

    useDidUpdate(() => {
        if (file) {
            dispatch(BHRActions.actionLoadFileDataBHR(RequestCode.GuaranteeRequest, file, { type }));
        }
    }, [file]);

    const handleAttachmentFiles = (files: File[]) => {
        batch(() => {
            dispatch(GRActions.actionValidationErrorsGR({ ...validationErrors, file: undefined }));
            dispatch(CoreTasksActions.actionTaskAttachmentFilesRun(files, {
                fileType: FileInfoAttachmentModelType.Other,
                validationParams: {
                    acceptedFiles: REGISTRY_GUARANTEE_FILE_ACCEPT_EXTENSIONS,
                    maxFileSize: REGISTRY_GUARANTEE_FILE_MAX_SIZE,
                    maxFilesSize: REGISTRY_GUARANTEE_FILE_MAX_SIZE,
                },
            }));
        });
    };

    const handleClickDelete = () => {
        batch(() => {
            dispatch(GRActions.actionValidationErrorsGR({ ...validationErrors, file: undefined }));
            dispatch(BHRActions.actionSetGuaranteeRequestsBHR([]));
            dispatch(CoreFileActions.actionSetAttachmentFilesFS([]));
        });
    };

    const handleClickDownload = () => {
        dispatch(TaskActions.actionTaskDownloadErrorsFileBHRRun());
    };

    const fileItem = file ? (
        <FileUploadItem
            id="bhr-attachment-file-registry-guaranties"
            name={ file.fileName }
            size={ file.size }
            showDelete={ !statusTaskCreateBatchRequest }
            downloadLink={ errors.length ? 'link' : '' }
            uploadStatus={ errors.length ? 'ERROR' : 'SUCCESS' }
            error={ errors.length ? fileError : '' }
            onDelete={ handleClickDelete }
            onDownload={ handleClickDownload }
        />
    ) : null;

    return (
        <div ref={ ref }>
            <AttachFile
                id="bhr-attachment-file"
                title="Вложите файл с реестром"
                description={ <BatchFillingFileDescription type={ type } /> }
                text={ `Размер файла не должен превышать ${REGISTRY_GUARANTEE_FILE_MAX_SIZE} МБ` }
                subText={ <BatchFillingFileError error={ validationErrors[validationFields.file] } /> }
                multiple={ false }
                accept={ getAcceptExtensions(REGISTRY_GUARANTEE_FILE_ACCEPT_EXTENSIONS) }
                disabled={ disabled || Boolean(file) }
                onAttachmentFiles={ handleAttachmentFiles }
            >
                { fileItem }
            </AttachFile>
        </div>
    );
});

BatchFillingFile.displayName = nameof(BatchFillingFile);
