import Exception from '@ak-front/core/client/exception';

import { TaskAction } from '#/src/client/store/actions/constants/task';
import { DefaultActionMeta } from '#/src/client/types';

export const actionTaskSignInRun = (
    login: string,
    password: string,
    callbackAuthSuccess?: (redirectUrl: string) => void,
    callbackAuthFail?: (error: Exception, code?: string) => void,
) => ({
    type: TaskAction.TASK_SIGN_IN_RUN as const,
    payload: { task: { status: true, message: 'Входим...' } },
    meta: { login, password, callbackAuthSuccess, callbackAuthFail },
});

export const actionTaskSignInStop = () => ({
    type: TaskAction.TASK_SIGN_IN_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

export const actionTaskSignInCertificateRun = (
    callbackAuthSuccess?: (redirectUrl: string) => void,
    callbackAuthFail?: (error: Exception, code?: string) => void,
) => ({
    type: TaskAction.TASK_SIGN_IN_CERTIFICATE_RUN as const,
    payload: { task: { status: true, message: 'Входим...' } },
    meta: { callbackAuthSuccess, callbackAuthFail },
});

export const actionTaskSignInCertificateStop = () => ({
    type: TaskAction.TASK_SIGN_IN_CERTIFICATE_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

export const actionTaskSignUpCertificateRun = () => ({
    type: TaskAction.TASK_SIGN_UP_CERTIFICATE_RUN as const,
    payload: { task: { status: true, message: 'Регистрируем...' } },
});

export const actionTaskSignUpCertificateStop = () => ({
    type: TaskAction.TASK_SIGN_UP_CERTIFICATE_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

export const actionTaskChangePasswordRun = (
    currentPassword: string,
    newPassword: string,
    callbackSuccess?: () => void,
    callbackFail?: (error: Exception, code?: string) => void,
) => ({
    type: TaskAction.TASK_CHANGE_PASSWORD_RUN as const,
    payload: { task: { status: true, message: 'Изменяем...' } },
    meta: { currentPassword, newPassword, callbackSuccess, callbackFail },
});
export const actionTaskChangePasswordStop = () => ({
    type: TaskAction.TASK_CHANGE_PASSWORD_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

export const actionTaskSignOutRun = () => ({
    type: TaskAction.TASK_SIGN_OUT_RUN as const,
    payload: { task: { status: true, message: 'Выходим...' } },
});

export const actionTaskSignOutStop = () => ({
    type: TaskAction.TASK_SIGN_OUT_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

export const actionTaskSendConfirmationRun = (channel: string, value: string, callback?: () => void) => ({
    type: TaskAction.TASK_SEND_CONFIRMATION_RUN as const,
    payload: { task: { status: true, message: 'Отправляем...' } },
    meta: { channel, value, callback },
});

export const actionTaskSendConfirmationStop = () => ({
    type: TaskAction.TASK_SEND_CONFIRMATION_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

export const actionTaskCheckConfirmationRun = (
    channel: string,
    code: string,
    value: string,
    callback?: (result: boolean) => void,
) => ({
    type: TaskAction.TASK_CHECK_CONFIRMATION_RUN as const,
    payload: { task: { status: true, message: 'Проверяем...' } },
    meta: { channel, code, value, callback },
});

export const actionTaskCheckConfirmationStop = () => ({
    type: TaskAction.TASK_CHECK_CONFIRMATION_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

/*
    Пакетная загрузка
 */
export const actionTaskGetListBHRRun = () => ({
    type: TaskAction.TASK_GET_LIST_BHR_RUN as const,
    payload: { task: { status: true, message: undefined } },
});

export const actionTaskGetListBHRStop = () => ({
    type: TaskAction.TASK_GET_LIST_BHR_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

export const actionTaskCreateBHRRun = (meta?: DefaultActionMeta) => ({
    type: TaskAction.TASK_CREATE_BHR_RUN as const,
    payload: { task: { status: true, message: 'Создаем...' } },
    meta,
});

export const actionTaskCreateBHRStop = () => ({
    type: TaskAction.TASK_CREATE_BHR_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

export const actionTaskRemoveBHRRun = (meta?: DefaultActionMeta) => ({
    type: TaskAction.TASK_REMOVE_BHR_RUN as const,
    payload: { task: { status: true, message: 'Удаляем...' } },
    meta,
});

export const actionTaskRemoveBHRStop = () => ({
    type: TaskAction.TASK_REMOVE_BHR_STOP as const,
    payload: { task: { status: false, message: undefined } },
});

export const actionTaskGetBHRRun = (id: string, withIntervalUpdate = false) => ({
    type: TaskAction.TASK_GET_BHR_RUN as const,
    payload: { task: { status: true } },
    meta: { id, withIntervalUpdate },
});

export const actionTaskGetBHRStop = () => ({
    type: TaskAction.TASK_GET_BHR_STOP as const,
    payload: { task: { status: false } },
});

export const actionTaskDownloadErrorsFileBHRRun = () => ({
    type: TaskAction.TASK_DOWNLOAD_ERRORS_BHR_RUN as const,
    payload: { task: { status: true } },
});
export const actionTaskDownloadErrorsFileBHRStop = () => ({
    type: TaskAction.TASK_DOWNLOAD_ERRORS_BHR_STOP as const,
    payload: { task: { status: false } },
});

export const actionTaskLoadMoreBatchesRun = () => ({
    type: TaskAction.TASK_LOAD_MORE_BATCHES_RUN as const,
    payload: { task: { status: true, message: undefined } },
});

export const actionTaskLoadMoreBatchesStop = () => ({
    type: TaskAction.TASK_LOAD_MORE_BATCHES_STOP as const,
    payload: { task: { status: false, message: undefined } },
});
