import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';
import { Button } from '@alfalab/core-components/button';

import { FormControl } from '@ak-front/core/client/types';

import { CoreCertificateActions } from '@ak-front/core/client/store/actions/creators';

import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { GuaranteeDisallowedRightsSignBoundary } from '@ak-front/guarantee-request/client/components/guarantee-boundary/guarantee-disallowed-rights-sign-boundary';

export const ButtonSignAndProcess: React.FC<FormControl> = React.memo(({ waiting, disabled }) => {
    const dispatch = useDispatch();

    const handleSignAndSend = useCallback(() => {
        dispatch(CoreCertificateActions.actionOpenSigningModalCP(true));
    }, []);

    return (
        <GuaranteeDisallowedRightsSignBoundary>
            <Grid.Col width={ { desktop: 'auto', tablet: 'auto', mobile: '12' } }>
                <LineIntent bottom="m">
                    <Skeleton width="available" visible={ waiting }>
                        <Button
                            id="bhr-sign-and-process"
                            view="primary"
                            block={ true }
                            size="m"
                            disabled={ disabled }
                            onClick={ handleSignAndSend }
                        >
                            Отправить на подпись
                        </Button>
                    </Skeleton>
                </LineIntent>
            </Grid.Col>
        </GuaranteeDisallowedRightsSignBoundary>
    );
});

ButtonSignAndProcess.displayName = nameof(ButtonSignAndProcess);
