import React from 'react';
import { useSelector } from 'react-redux';

import { StalemateBaseError } from '#/src/client/components/error/stalemate';
import { UserSelectors } from '#/src/client/store/selectors';

export const SignUpOffBoundary: React.FC<React.PropsWithChildren> = React.memo(({ children }) => {
    const isSignInUpOff = useSelector(UserSelectors.isSignInUpOffAH);

    if (isSignInUpOff) {
        return (
            <StalemateBaseError
                title="Технические работы"
                text="Вход через УКЭП недоступен."
                additionalText="Обратитесь к вашему менеджеру или свяжитесь с поддержкой"
            />
        );
    }

    return <React.Fragment>{ children }</React.Fragment>;
});

SignUpOffBoundary.displayName = nameof(SignUpOffBoundary);
