import React from 'react';
import { LAQUO, RAQUO } from '@ak-front/core/constants';

import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';
import { NBSP } from 'arui-private/lib/formatters';

import { documents, findLastDocumentVersion } from '#/src/client/utils/dictionary';

export const CheckboxConfirmingLabel: React.VFC = () => (
    <Typography.Text view="primary-medium" monospaceNumbers={ true } color="primary">
        Нажимая кнопку
        { NBSP }
        { LAQUO }
        Зарегистрироваться
        { RAQUO }
        ,
        вы
        { NBSP }
        подтверждаете, что согласны с
        { NBSP }
        условиями
        { NBSP }
        <Link
            view="default"
            href={ findLastDocumentVersion(documents.UsageAgreementLegalEntities.versions).relativePath }
        >
            Договора об
            { NBSP }
            использовании Альфа-Кредит
        </Link>
    </Typography.Text>
);

CheckboxConfirmingLabel.displayName = nameof(CheckboxConfirmingLabel);
