import React from 'react';

import Content from 'arui-private/core/content';
import Header from 'arui-private/core/header';
import Logo from 'arui-private/core/logo';
import Page from 'arui-private/core/page';

import { PageExternalFooter } from '#/src/client/pages/external/footer';

import './index.css';

export const PageExternal: React.FC<React.PropsWithChildren> = ({ children }) => (
    <Page
        className='page-external'
        hasOffset={false}
        header={<Header logo={<Logo view='icon' theme='alfa-on-white' />} hasSidebar={false} />}
    >
        <Content>{children}</Content>
        <div className='page__footer'>
            <PageExternalFooter />
        </div>
    </Page>
);

PageExternal.displayName = nameof(PageExternal);
