import React from 'react';

import './index.css';

type TColor = 'white' | 'light-primary' | undefined;

type TDividerProps = {
    id?: string;
    className?: string;
    color?: TColor;
};

const Divider: React.FC<TDividerProps> = React.memo(({ id, className = '', color }) => {
    const dividerColor = color ? ` divider_color_${color}` : '';
    const includeClassName = className ? ` ${className}` : '';

    return <hr id={ id } className={ `divider ${dividerColor}${includeClassName}` } />;
});

export default Divider;
