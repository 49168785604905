import React, { SVGProps } from 'react';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Typography } from '@alfalab/core-components/typography';
import { colorLightBgPrimary, colorLightGraphicSecondary } from '@alfalab/core-components/vars';
import { ChevronRightMIcon } from '@alfalab/icons-glyph/ChevronRightMIcon';

import './index.css';

type PasswordRecoverySelectItemProps = {
    value: string;
    icon: React.FC<SVGProps<SVGSVGElement>>;
    text: string;
    onClick: (step: string) => void;
};

export const PasswordRecoverySelectItem: React.VFC<PasswordRecoverySelectItemProps> = ({
    value,
    icon: Icon,
    text,
    onClick,
}) => {
    const handleClick = (event: React.MouseEvent) => onClick(event.currentTarget.getAttribute('value')!);

    return (
        <Button value={value} view='ghost' block={true} size='s' onClick={handleClick}>
            <GenericWrapper className='password-recovery-select__item' alignItems='center'>
                <SuperEllipse size={48} backgroundColor={colorLightBgPrimary}>
                    <Icon color={colorLightGraphicSecondary} />
                </SuperEllipse>
                <Gap size='m' direction='horizontal' />
                <GenericWrapper justifyContent='between' alignItems='center' grow={true}>
                    <Typography.Text view='primary-medium' weight='bold' color='primary'>
                        {text}
                    </Typography.Text>
                    <Gap size='m' direction='horizontal' />
                    <ChevronRightMIcon />
                </GenericWrapper>
            </GenericWrapper>
        </Button>
    );
};

PasswordRecoverySelectItem.displayName = nameof(PasswordRecoverySelectItem);
