import { ApplicationState } from '../reducers';

/** Сообщение пользователю при входе в систему */
export const getMessageTaskSignIn = (state: ApplicationState) => state.tasks.taskSignIn?.message;
/** Процесс входа в систему заупущен */
export const getStatusTaskSignIn = (state: ApplicationState) => state.tasks.taskSignIn?.status || false;

export const getMessageTaskSignInCertificate = (state: ApplicationState) => state.tasks.taskSignInCertificate?.message;
export const getStatusTaskSignInCertificate = (state: ApplicationState) =>
    state.tasks.taskSignInCertificate?.status || false;

export const getMessageTaskSignUpCertificate = (state: ApplicationState) => state.tasks.taskSignUpCertificate?.message;
export const getStatusTaskSignUpCertificate = (state: ApplicationState) =>
    state.tasks.taskSignUpCertificate?.status || false;

export const getMessageTaskAuthentication = (state: ApplicationState) => state.tasks.taskAuthentication?.message;
export const getStatusTaskAuthentication = (state: ApplicationState) => state.tasks.taskAuthentication?.status || false;

export const getStatusTaskChangePassword = (state: ApplicationState) => state.tasks.taskChangePassword?.status || false;

/** Сообщение пользователю при выходе из систему */
export const getMessageTaskSignOut = (state: ApplicationState) => state.tasks.taskSignOut?.message;
/** Процесс выхода из системы заупущен */
export const getStatusTaskSignOut = (state: ApplicationState) => state.tasks.taskSignOut?.status || false;

export const getStatusTaskSendConfirmation = (state: ApplicationState) =>
    state.tasks.taskSendConfirmation?.status || false;
export const getStatusTaskCheckConfirmation = (state: ApplicationState) =>
    state.tasks.taskCheckConfirmation?.status || false;

/** Процесс получения списка пакетов в пакетной загрузке */
export const getStatusTaskListBHR = (state: ApplicationState) => state.tasks.taskGetListBatchRequest?.status || false;
/** Процесс получения списка пакетов в пакетной загрузке */
export const getStatusTaskListMoreBHR = (state: ApplicationState) =>
    state.tasks.taskGetListBatchMoreRequest?.status || false;
/** Процесс создания пакетной загрузки */
export const getStatusTaskCreateBHR = (state: ApplicationState) => state.tasks.taskCreateBatchRequest?.status || false;
/** Сообщения создания пакетной загрузки */
export const getMessageTaskCreateBHR = (state: ApplicationState) => state.tasks.taskCreateBatchRequest?.message;
/** Процесс удаления пакетной загрузки */
export const getStatusTaskRemoveBHR = (state: ApplicationState) => state.tasks.taskRemoveBatchRequest?.status || false;

/** Запрос на заявку пакетной обработки запущен */
export const getStatusTaskGetBHR = (state: ApplicationState) => state.tasks.taskGetBatchRequest?.status || false;
/** Запрос на скачивание файла с ошибками */
export const getStatusTaskDownloadErrorsFileBHR = (state: ApplicationState) =>
    state.tasks.taskDownloadErrorsFileBatchRequest?.status || false;
