import React from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Space } from '@alfalab/core-components/space';

import { PageExternal } from '#/src/client/pages/external';
import { PasswordRecoverySelect } from '#/src/client/pages/external/password-recovery/select';

export const PasswordRecoveryPage: React.VFC = () => (
        <PageExternal>
            <Space size={0} direction='vertical' fullWidth={true} wrap={false}>
                <Gap size='7xl' />
                <Grid.Row gutter='0' justify='center'>
                    <Grid.Col width={{ desktop: '6', tablet: '6', mobile: '12' }}>
                        <PasswordRecoverySelect />
                    </Grid.Col>
                </Grid.Row>
            </Space>
        </PageExternal>
    );

PasswordRecoveryPage.displayName = nameof(PasswordRecoveryPage);
