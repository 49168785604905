import React from 'react';
import { RouteComponentProps } from 'react-router';
import { shallowEqual, useSelector } from 'react-redux';

import { RequestCode } from '@ak-front/core/client/types/request';

import {
    getImageByStatusCode,
    getPageTitleByStatusCode,
    getPageTextByStatusCode,
    getActionByStatusCode,
} from '@ak-front/core/client/components/error';

import { Form, FormError } from '@ak-front/core/client/components/forms';
import { SigningModal } from '@ak-front/core/client/components/modals';
import { TaskSelectors, BHRSelectors } from '#/src/client/store/selectors';
import { BatchView } from './batch-view';
import { BatchFilling } from './batch-filling';

type BatchFormProps = {
    id?: string;
};

export const BatchForm: React.VFC<RouteComponentProps<BatchFormProps>> = React.memo(({ match }) => {
    const { id } = match.params;

    const organizationInn = useSelector(BHRSelectors.getOrganizationInnBHR) || '';
    const organizationId = useSelector(BHRSelectors.getOrganizationIdBHR) || 0;
    const docNumber = useSelector(BHRSelectors.getDocNumberBHR) || '';

    const exception = useSelector(BHRSelectors.getExceptionBHR, shallowEqual);

    const statusTaskGet = useSelector(TaskSelectors.getStatusTaskGetBHR);

    if (exception) {
        const { statusCode, requestId } = exception;

        return (
            <FormError
                requestId={ requestId }
                image={ getImageByStatusCode(statusCode) }
                title={ getPageTitleByStatusCode(statusCode) }
                text={ getPageTextByStatusCode(statusCode) }
                action={ getActionByStatusCode(statusCode) }
            />
        );
    }

    return (
        <Form title={ `Пакет заявлений ${docNumber ? `№ ${docNumber}` : ''}` } waiting={ statusTaskGet }>
            { id ? <BatchView /> : <BatchFilling waiting={ statusTaskGet } /> }
            <SigningModal
                organizationId={ organizationId }
                organizationInn={ organizationInn }
                requestCode={ RequestCode.BatchRequest }
            />
        </Form>
    );
});

BatchForm.displayName = nameof(BatchForm);
