import { put } from 'redux-saga/effects';
import { CRActions, CRTasksActions } from '@ak-front/credit-request/client/store';
import { CoreTasksActions } from '@ak-front/core/client/store/actions/creators';
import { RequestCode } from '@ak-front/core/client/types';

export function* sagaLoadDataForRequestCR(idRequest: string) {
    yield put(CRTasksActions.actionTaskGetCRRun(idRequest));
}

export function* sagaLoadDataForRequestNewCR() {
    yield put(CoreTasksActions.actionTaskGetContractsRun(
        RequestCode.CreditRequest,
        true,
        CRActions.actionSetContractsCR,
        CRActions.actionViewExceptionContractsCR,
    ));
}
