import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import { FormControl } from '@ak-front/core/client/types';

import { CoreTasksSelectors } from '@ak-front/core/client/store/selectors';
import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';
import { GRActions } from '@ak-front/guarantee-request/client/store/actions';

import { formatDate } from '@ak-front/core/client/utils/date';
import { useScrollToError } from '@ak-front/core/client/utils/hook';
import { fieldsNames } from '@ak-front/guarantee-request/client/validations';

import { LineIntent } from '@ak-front/core/client/components/line-intent';
import {
    SelectWithSpin,
    SelectWithSpinOnChangeEvent,
    SelectWithSpinOptionShape,
} from '@ak-front/core/client/components/select-with-spin';
import { validationShallowEqual } from '@ak-front/core/client/validations';
import { BHRActions } from '../../../../../../store/actions/creators';

const validationFields = {
    idDeal: fieldsNames.idDeal,
    idClient: fieldsNames.idClient,
};

export const BatchFillingContractGR: React.FC<FormControl> = React.memo(({ ...rest }) => {
    const dispatch = useDispatch();

    const contractId = useSelector(GRSelectors.getContractIdGR);
    const contracts = useSelector(GRSelectors.getContractsGR, (left, right) => left.length === right.length);

    const statusTaskGetContracts = useSelector(CoreTasksSelectors.getStatusTaskGetContracts);
    const statusTaskGetContractProperties = useSelector(CoreTasksSelectors.getStatusTaskGetContractProperties);

    const messageTaskGetContracts = useSelector(CoreTasksSelectors.getMessageTaskGetContracts);

    const validationErrors = useSelector(GRSelectors.getValidationErrorsGR, validationShallowEqual(validationFields));
    const refContract = useScrollToError<HTMLInputElement>(validationErrors, validationFields);

    const waiting = statusTaskGetContracts
        || statusTaskGetContractProperties;

    const disabled = rest.disabled
        || contracts.length <= 1
        || waiting;

    const options = contracts
        .sort((a, b) => {
            if (Boolean(a.expired) === Boolean(b.expired)) {
                return 0;
            }

            return a.expired ? 1 : -1;
        })
        .map<SelectWithSpinOptionShape>(({
            idDeal, docNumber, docDate, docDateEnd, docTypeName, name, inn, expired,
        }) => {
            const documentNumber: string = docNumber ? ` № ${docNumber}` : '';
            const nameAndInn: string = idDeal ? `${name}, ИНН ${inn}` : '';

            return {
                key: `${idDeal}`,
                content: (
                    <React.Fragment>
                        <Grid.Row gutter="0">
                            <Typography.Text view="primary-small" color="secondary">
                                { nameAndInn }
                            </Typography.Text>
                        </Grid.Row>
                        <Grid.Row gutter="0">
                            <LineIntent top="2xs">
                                { `${docTypeName}${documentNumber} от ${formatDate(docDate)} до ${formatDate(docDateEnd)}` }
                            </LineIntent>
                        </Grid.Row>
                    </React.Fragment>
                ),
                disabled: expired,
            };
        });

    const handleSelectContract = useCallback(({ selected }: SelectWithSpinOnChangeEvent) => {
        const value = Number(selected?.key);

        if (value !== contractId) {
            const contract = contracts.find((f) => f.idDeal === value);

            if (contract) {
                batch(() => {
                    dispatch(GRActions.actionSelectContractWithOrganizationGR(contract, contractId));
                    dispatch(BHRActions.actionSetGuaranteeTypeBHR(undefined));
                });
            }
        }
    }, [contracts.length, contractId]);

    return (
        <SelectWithSpin
            id="bhr-gr-contract"
            ref={ refContract }
            label={ contractId ? '' : 'Рамочный договор' }
            size="m"
            block={ true }
            autoSelect={ true }
            selected={ `${contractId}` }
            options={ options }
            disabled={ disabled }
            waiting={ waiting }
            waitingMessage={ messageTaskGetContracts }
            error={ validationErrors[validationFields.idDeal] || validationErrors[validationFields.idClient] }
            onChange={ handleSelectContract }
            onAutoChange={ handleSelectContract }
        />
    );
});

BatchFillingContractGR.displayName = nameof(BatchFillingContractGR);
