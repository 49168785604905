import React from 'react';
import { useHistory } from 'react-router';

import { Button } from '@alfalab/core-components/button';
import { PATH_SIGN_IN_ROUTE } from '#/src/shared/constants/user';

type SignInButtonProps = {
    disabled?: boolean;
};

export const SignInButton = React.memo(({ disabled }: SignInButtonProps) => {
    const history = useHistory();

    const handleClick = () => {
        history.push(PATH_SIGN_IN_ROUTE);
    };

    return (
        <Button
            id="sign-in"
            view="secondary"
            size="s"
            block={ true }
            disabled={ disabled }
            onClick={ handleClick }
        >
            Войти в систему
        </Button>
    );
});

SignInButton.displayName = nameof(SignInButton);
