import * as Api from '#/src/client/api/fetch-client';
import { BatchRequest } from '#/src/client/types';

/** Получение списка пакетных загрузок */
export const getBatchRequests = (limit: number, offset: number) => (
    new Api.FetchClient().getBatchRequests(limit, offset)
);

/** Получение пакетной загрузки по идентефикатору */
export const getBatchRequestById = (id: string, createdDateTime?: Date) => (
    new Api.FetchClient().getBatchRequestById(id, createdDateTime)
);

/** Создание пакетной загрузки */
export const createBatchRequest = (batchRequest: BatchRequest): Promise<Api.BatchRequestResponseModelSchema> => {
    const model = new Api.BatchRequestModel();
    model.init(batchRequest);

    return new Api.FetchClient().createBatchRequest(model);
};

/** Запустить пакет с заявлениями на обработку */
export const processBatchRequest = (id: string) => (
    new Api.FetchClient().processBatchRequest(id)
);

/** Создание пакетной загрузки */
export const removeBatchRequest = (id: string) => (
    new Api.FetchClient().removeBatchRequest(id)
);

/** Скачивание файла с ошибками для пакетной загрузки */
export const getErrorsFile = (payload: Api.BatchRequestErrorsPayload) => {
    const model = new Api.BatchRequestErrorsPayload();
    model.init(payload);

    return new Api.FetchClient().getBatchErrorsFile(model);
};
