import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Typography } from '@alfalab/core-components/typography';
import { RadioGroup } from '@alfalab/core-components/radio-group';
import { Tag } from '@alfalab/core-components/tag';
import { NBSP } from '@ak-front/core/client/constants/formatters';

import { GuaranteeRequestModelIssueType as IssueType } from '@ak-front/guarantee-request/client/api/fetch-client';
import { FormControl } from '@ak-front/core/client/types';

import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';
import { GRActions } from '@ak-front/guarantee-request/client/store/actions';

import { useScrollToError } from '@ak-front/core/client/utils/hook';
import { UtilGR } from '@ak-front/guarantee-request/client/utils/request';
import { fieldsNames } from '@ak-front/guarantee-request/client/validations';
import { validationShallowEqual } from '@ak-front/core/client/validations';
import { batchGuaranteeIssueTypeDictionary } from '#/src/client/utils/dictionary';

const validationFields = {
    issueType: fieldsNames.type.issueType,
};

export const BatchFillingIssueTypeGR: React.FC<FormControl> = React.memo(({ disabled }) => {
    const dispatch = useDispatch();

    const type = useSelector(GRSelectors.getTypeGR);
    const issueType = useSelector(GRSelectors.getIssueTypeGR);
    const validationErrors = useSelector(GRSelectors.getValidationErrorsGR, validationShallowEqual(validationFields));

    const refIssueType = useScrollToError<HTMLInputElement>(validationErrors, validationFields);

    const tags = batchGuaranteeIssueTypeDictionary.filter(({ value }) => (
        !UtilGR.isIssueTypeItemHidden(type, value)
    ));

    const tagsElements = tags.map(({ text, value }) => (
        <Tag key={ value } value={ value } name={ text } disabled={ disabled }>
            { text }
        </Tag>
    ));

    const handleSelectIssueType = useCallback((
        event?: React.ChangeEvent | React.MouseEvent,
        payload?: { value: string; name?: string },
    ) => {
        const value = payload?.value as IssueType;

        if (value && value !== issueType) {
            dispatch(GRActions.actionSelectIssueTypeGR(value));
        }
    }, [issueType]);

    return tags.length === 1 ? (
        <Typography.Text view="primary-medium" color="primary">
            Тип способа выдачи:
            { NBSP }
            { tags[0].text }
        </Typography.Text>
    ) : (
        <RadioGroup
            label="Тип способа выдачи"
            direction="horizontal"
            type="tag"
            value={ issueType }
            ref={ refIssueType }
            disabled={ disabled }
            dataTestId="guarantee-issue-type"
            error={ validationErrors[validationFields.issueType] }
            onChange={ handleSelectIssueType }
        >
            { tagsElements }
        </RadioGroup>
    );
});

BatchFillingIssueTypeGR.displayName = nameof(BatchFillingIssueTypeGR);
