import React from 'react';
import { BaseForm } from '@ak-front/core/client/components/forms';
import { Colors, Icon } from '@ak-front/core/client/components/icon';
import { NBSP } from '@ak-front/core/client/constants/formatters';

import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { Link } from '@alfalab/core-components/link';
import { ExclamationMIcon } from '@alfalab/icons-glyph/ExclamationMIcon';
import Content from 'arui-private/core/content';
import Header from 'arui-private/core/header';
import Page from 'arui-private/core/page';
import { StatusScreen } from 'arui-private/core/status-screen';

import './index.css';

export const SupportBrowserPage: React.FC = () => {
    const subtitle = (
        <React.Fragment>
            Скачайте один из популярных браузеров:
            { NBSP }
            <Link href="https://browser.yandex.ru/old/?banerid=6500000000&lang=ru">Яндекс Браузер</Link>
            ,
            <br />
            <Link href="https://www.google.com/intl/ru_ALL/chrome/fallback">Chrome</Link>
            ,
            { NBSP }
            <Link href="https://www.microsoft.com/en-us/edge/server/download?form=MA13FJ">Microsoft Edge</Link>
            { NBSP }
            или
            { NBSP }
            <Link href="https://www.mozilla.org/ru/firefox/new">Mozilla Firefox</Link>
        </React.Fragment>
    );

    return (
        <Page
            className="page-internal"
            hasOffset={ false }
            header={ <Header hasSidebar={ false } /> }
        >
            <Content>
                <BaseForm>
                    <StatusScreen
                        className="support-browser__status-screen"
                        title="Internet Explorer не поддерживается"
                        subtitle={ subtitle as unknown as string }
                        topAddons={ <SuperEllipse backgroundColor={ Colors.attention }><Icon color="primary-inverted" icon={ ExclamationMIcon } /></SuperEllipse> }
                        useBackgroundPlate={ true }
                    />
                </BaseForm>
            </Content>
        </Page>
    );
};

SupportBrowserPage.displayName = nameof(SupportBrowserPage);
