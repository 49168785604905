import { combineReducers } from 'redux';
import {
    bankRequestRootReducer,
    BankRequestRootState,
    bankRequestRootState,
} from '@ak-front/bank-request/client/store/reducers';
import { coreReducer, CoreState,defaultCoreState } from '@ak-front/core/client/store/reducers';
import {
    creditRequestRootReducer,
    CreditRequestRootState,
    creditRequestRootState,
} from '@ak-front/credit-request/client/store/reducers';
import {
dealReportRootReducer,
    DealReportRootState, dealReportRootState, } from '@ak-front/deal-report/client/store/reducers';
import {
    documentAggregationRootReducer,
    DocumentAggregationRootState,
    documentAggregationRootState,
} from '@ak-front/document-aggregation/client/store/reducers';
import {
    documentQueryRequestRootReducer,
    DocumentQueryRequestRootState,
    documentQueryRequestRootState,
} from '@ak-front/document-query-request/client/store/reducers';
import {
    documentRequestRootReducer,
    DocumentRequestRootState,
    documentRequestRootState,
} from '@ak-front/document-request/client/store/reducers';
import {
    guaranteeRequestRootReducer,
    GuaranteeRequestRootState,
    guaranteeRequestRootState,
} from '@ak-front/guarantee-request/client/store/reducers';
import {
    incomingGuaranteeRootReducer,
    IncomingGuaranteeRootState,
    incomingGuaranteeRootState,
} from '@ak-front/incoming-guarantee/client/store/reducers';
import {
    trancheChangeRequestRootReducer,
    TrancheChangeRequestRootState,
    trancheChangeRequestRootState,
} from '@ak-front/tranche-change-request/client/store/reducers';

import batchRequestReducer, { BatchRequestState, defaultBatchRequestState } from './request/batch';
import { defaultTaskState,tasksReducer, TaskState } from './task';
import { defaultUserState,userReducer, UserState } from './user';

type RequestState = {
    readonly batch: BatchRequestState;
};

export type ApplicationState = CoreState &
    BankRequestRootState &
    DocumentAggregationRootState &
    DocumentQueryRequestRootState &
    DocumentRequestRootState &
    CreditRequestRootState &
    GuaranteeRequestRootState &
    IncomingGuaranteeRootState &
    TrancheChangeRequestRootState &
    DealReportRootState & {
    readonly user: UserState;
    readonly tasks: TaskState;
    readonly request: RequestState;
};

const defaultRequestState: RequestState = {
    batch: defaultBatchRequestState,
};

export const defaultApplicationState: ApplicationState = {
    ...defaultCoreState,
    user: defaultUserState,
    tasks: defaultTaskState,
    ...documentAggregationRootState,
    ...documentQueryRequestRootState,
    ...documentRequestRootState,
    ...guaranteeRequestRootState,
    ...incomingGuaranteeRootState,
    ...trancheChangeRequestRootState,
    ...creditRequestRootState,
    ...bankRequestRootState,
    request: defaultRequestState,
    ...dealReportRootState,
};

export const rootReducer = () => combineReducers<ApplicationState>({
    ...coreReducer(),
    user: userReducer,
    tasks: tasksReducer,
    ...documentAggregationRootReducer(),
    ...documentQueryRequestRootReducer(),
    ...documentRequestRootReducer(),
    ...guaranteeRequestRootReducer(),
    ...incomingGuaranteeRootReducer(),
    ...trancheChangeRequestRootReducer(),
    ...creditRequestRootReducer(),
    ...bankRequestRootReducer(),
    request: combineReducers<RequestState>({
        batch: batchRequestReducer,
    }),
    ...dealReportRootReducer(),
});
