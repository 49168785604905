import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import { GuaranteeRequest } from '@ak-front/guarantee-request/client/types';

import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { VisibleBoundary } from '@ak-front/core/client/components/visible-boundary';
import { CoreTasksSelectors } from '@ak-front/core/client/store/selectors';
import { UserRole } from '@ak-front/core/client/components/user-role';
import { RoleCode } from '@ak-front/core/client/types/app';
import { BHRSelectors, TaskSelectors } from '#/src/client/store/selectors';
import { BatchRequestFilterStatus, BatchRequestsGroupsByStatus } from '#/src/client/types';
import { UtilBHR } from '#/src/client/utils/batch';
import { BatchViewFilter } from './batch-view-filter';
import { BatchViewInfo } from './batch-view-info';
import { BatchViewInProgressInfo } from './batch-view-in-porgress-info';
import { BatchViewRequests } from './batch-view-requests';
import { ButtonSignAndProcess, ButtonRemove } from '../batch-button-panel/buttons';

export const BatchView: React.FC = React.memo(() => {
    const statusTaskGet = useSelector(TaskSelectors.getStatusTaskGetBHR);
    const statusTaskDelete = useSelector(TaskSelectors.getStatusTaskRemoveBHR);
    const statusTaskSignAndSend = useSelector(CoreTasksSelectors.getStatusTaskSigningAndSendBank);

    const type = useSelector(BHRSelectors.getTypeGRBHR);
    const status = useSelector(BHRSelectors.getStatusBHR);

    const requests = useSelector(BHRSelectors.getRequestsBHR);

    const requestGroupsByStatus: BatchRequestsGroupsByStatus = React.useMemo(
        () => UtilBHR.createGroupRequestsByStatus(requests as GuaranteeRequest[]),
        [requests],
    );

    const requestsCount = React.useMemo(
        () => {
            const result: Record<BatchRequestFilterStatus, number> = {
                [BatchRequestFilterStatus.All]: 0,
                [BatchRequestFilterStatus.Draft]: 0,
                [BatchRequestFilterStatus.ToBeSigned]: 0,
                [BatchRequestFilterStatus.SendToBank]: 0,
            };

            Object.keys(requestGroupsByStatus).forEach((s) => {
                result[s] = requestGroupsByStatus[s].length;
            });

            return result;
        },
        [requestGroupsByStatus],
    );

    const disabled = statusTaskDelete || statusTaskSignAndSend;
    const isInProgress = UtilBHR.isInProgress(status);

    return (
        <React.Fragment>
            <VisibleBoundary visible={ isInProgress }>
                <LineIntent top="xl">
                    <BatchViewInProgressInfo waiting={ statusTaskGet } />
                </LineIntent>
            </VisibleBoundary>
            <LineIntent top="xl">
                <BatchViewInfo waiting={ statusTaskGet } />
            </LineIntent>
            <VisibleBoundary visible={ !isInProgress }>
                <LineIntent top="3xl">
                    <Grid.Row>
                        <Grid.Col width="auto">
                            <Skeleton width="available" visible={ statusTaskGet }>
                                <Typography.Title view="medium" font="system" tag="h1" color="primary">
                                    Список заявлений
                                </Typography.Title>
                            </Skeleton>
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col width="12">
                            <LineIntent top="xl">
                                <Skeleton width="available" visible={ statusTaskGet } size="xl">
                                    <BatchViewFilter requestsCount={ requestsCount } disabled={ disabled } />
                                </Skeleton>
                            </LineIntent>
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col width="12">
                            <LineIntent top="xl">
                                <Skeleton width="available" visible={ statusTaskGet } size="xl">
                                    <BatchViewRequests
                                        options={ { guaranteeType: type } }
                                        requests={ requestGroupsByStatus }
                                    />
                                </Skeleton>
                            </LineIntent>
                        </Grid.Col>
                    </Grid.Row>
                </LineIntent>
                <LineIntent top="3xl">
                    <Grid.Row justify="left" gutter={ { desktop: '24', tablet: '24', mobile: '0' } }>
                        <VisibleBoundary visible={ Boolean(requestGroupsByStatus[BatchRequestFilterStatus.ToBeSigned].length) }>
                            <UserRole role={ RoleCode.Signatory }>
                                <ButtonSignAndProcess waiting={ statusTaskGet } disabled={ disabled } />
                            </UserRole>
                        </VisibleBoundary>
                        <ButtonRemove waiting={ statusTaskGet } disabled={ disabled } />
                    </Grid.Row>
                </LineIntent>
            </VisibleBoundary>
        </React.Fragment>
    );
});

BatchView.displayName = nameof(BatchView);
