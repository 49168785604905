import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';

import { CoreTasksSelectors } from '@ak-front/core/client/store/selectors';
import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';

import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { FormPlate, FormSection } from '@ak-front/core/client/components/forms';
import { VisibleBoundary } from '@ak-front/core/client/components/visible-boundary';
import { GuaranteeContractErrorBoundary } from '@ak-front/guarantee-request/client/components/guarantee-boundary/guarantee-contract-error-boundary';
import { GuaranteeFillingContractPropertiesErrorBoundary } from '@ak-front/guarantee-request/client/components/guarantee-boundary/guarantee-contract-properties-error-boundary';
import { GuaranteeFillingFeeTitle } from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-fee/guarantee-filling-fee-title';
import { GuaranteeFillingPrincipal } from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-principal';
import { GuaranteeFillingTextTitle } from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-text/guarantee-filling-text-title';
import { GuaranteeFeeEmptyPeriodsWarning } from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-fee/guarantee-fee-empty-periods-warning';
import { BHRSelectors, TaskSelectors } from '#/src/client/store/selectors';

import { ButtonCreate } from '#/src/client/components/request/batch-form/batch-button-panel/buttons';
import { UtilBHR } from '#/src/client/utils/batch';
import { BatchFillingContractGR } from './batch-filling-gr/batch-filling-gr-contract';
import { BatchFillingTypeGR } from './batch-filling-gr/batch-filling-gr-type';
import { BatchFillingIssueTypeGR } from './batch-filling-gr/batch-filling-gr-issue-type';
import { BatchFillingAdditionalDataGR } from './batch-filling-gr/batch-filling-gr-additional-data';
import { BatchFillingFactPrincipalGR } from './batch-filling-gr/batch-filling-gr-fact-principal';
import { BatchFillingBeneficiaryGR } from './batch-filling-gr/batch-filling-gr-beneficiary';
import { BatchFillingTextGR } from './batch-filling-gr/batch-filling-gr-text';
import { BatchFillingConditionsGR } from './batch-filling-gr/batch-filling-gr-conditions';
import { BatchFillingFeeGR } from './batch-filling-gr/batch-filling-gr-fee';
import { BatchFillingFile } from './batch-filling-gr/batch-filling-gr-file';

type BatchFillingProps = {
    waiting?: boolean;
};

export const BatchFilling: React.FC<BatchFillingProps> = React.memo(({ ...rest }) => {
    const version = useSelector(GRSelectors.getVersionOfContractPropertyCodeGR);
    const contractId = useSelector(GRSelectors.getContractIdGR);

    const guaranteeType = useSelector(BHRSelectors.getGuaranteeTypeBHR);

    const exceptionContractProperties = useSelector(GRSelectors.getExceptionContractPropertiesGR);

    const statusTaskGetContracts = useSelector(CoreTasksSelectors.getStatusTaskGetContracts);
    const statusTaskGetContractProperties = useSelector(CoreTasksSelectors.getStatusTaskGetContractProperties);
    const statusTaskGetFactPrincipals = useSelector(CoreTasksSelectors.getStatusTaskGetFactPrincipals);
    const statusTaskCreateBHR = useSelector(TaskSelectors.getStatusTaskCreateBHR);

    const disabled = statusTaskCreateBHR
        || statusTaskGetContracts
        || statusTaskGetContractProperties;

    const waiting = rest.waiting || statusTaskGetContracts || statusTaskGetContractProperties;
    const isType44FZ = UtilBHR.isGuaranteeType44FZ(guaranteeType) || UtilBHR.isGuaranteeType44FZJointPurchases(guaranteeType);

    return (
        <LineIntent top="xl">
            <FormPlate>
                <GuaranteeContractErrorBoundary>
                    <Grid.Row>
                        <Grid.Col width="12">
                            <LineIntent>
                                <Skeleton visible={ rest.waiting && !contractId } size="xl">
                                    <BatchFillingContractGR disabled={ disabled } />
                                </Skeleton>
                            </LineIntent>
                        </Grid.Col>
                    </Grid.Row>
                    <GuaranteeFillingContractPropertiesErrorBoundary isEmpty={ true }>
                        <Grid.Row>
                            <Grid.Col width="12">
                                <GuaranteeFeeEmptyPeriodsWarning waiting={ waiting } disabled={ disabled } />
                            </Grid.Col>
                        </Grid.Row>
                    </GuaranteeFillingContractPropertiesErrorBoundary>
                    <BatchFillingFactPrincipalGR
                        waiting={ waiting || statusTaskGetFactPrincipals }
                        disabled={ disabled }
                    />
                </GuaranteeContractErrorBoundary>
            </FormPlate>
            <GuaranteeContractErrorBoundary isEmpty={ true }>
                <GuaranteeFillingContractPropertiesErrorBoundary>
                    <LineIntent top="xl">
                        <VisibleBoundary visible={ Boolean(contractId) }>
                            <FormPlate>
                                <FormSection title="Тип гарантии" waiting={ waiting }>
                                    <Grid.Row>
                                        <Grid.Col width="auto">
                                            <LineIntent top="xl">
                                                <Skeleton visible={ waiting } size="4xl">
                                                    <BatchFillingTypeGR
                                                        key={ guaranteeType }
                                                        waiting={ waiting }
                                                        disabled={ disabled || statusTaskGetFactPrincipals }
                                                    />
                                                </Skeleton>
                                            </LineIntent>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Grid.Row>
                                        <Grid.Col width="12">
                                            <VisibleBoundary visible={ Boolean(guaranteeType) }>
                                                <LineIntent top="xl">
                                                    <BatchFillingIssueTypeGR
                                                        waiting={ waiting }
                                                        disabled={ disabled }
                                                    />
                                                </LineIntent>
                                                <LineIntent>
                                                    <BatchFillingAdditionalDataGR
                                                        waiting={ waiting }
                                                        disabled={ disabled }
                                                    />
                                                </LineIntent>
                                            </VisibleBoundary>
                                        </Grid.Col>
                                    </Grid.Row>
                                </FormSection>
                            </FormPlate>
                        </VisibleBoundary>
                    </LineIntent>
                    <VisibleBoundary visible={ Boolean(guaranteeType) }>
                        <VisibleBoundary visible={ UtilBHR.isGuaranteeTypeFNS(guaranteeType) }>
                            <LineIntent top="xl">
                                <FormPlate>
                                    <BatchFillingBeneficiaryGR waiting={ waiting } disabled={ disabled } />
                                </FormPlate>
                            </LineIntent>
                        </VisibleBoundary>
                        <VisibleBoundary visible={ isType44FZ }>
                            <LineIntent top="m">
                                <GuaranteeFillingPrincipal
                                    waiting={ waiting }
                                    disabled={ disabled }
                                />
                            </LineIntent>
                        </VisibleBoundary>
                        <VisibleBoundary visible={ UtilBHR.isGuaranteeType44FZJointPurchases(guaranteeType) }>
                            <LineIntent top="xl">
                                <FormPlate>
                                    <FormSection title="Условия гарантии" waiting={ waiting }>
                                        <BatchFillingConditionsGR waiting={ waiting } disabled={ disabled } />
                                    </FormSection>
                                </FormPlate>
                            </LineIntent>
                        </VisibleBoundary>
                        <VisibleBoundary visible={ isType44FZ }>
                            <LineIntent top="xl">
                                <FormPlate>
                                    <FormSection title={ <GuaranteeFillingTextTitle title="Текст гарантии" /> } waiting={ waiting }>
                                        <BatchFillingTextGR waiting={ waiting } disabled={ disabled } />
                                    </FormSection>
                                </FormPlate>
                            </LineIntent>
                        </VisibleBoundary>
                        <LineIntent top="xl">
                            <FormPlate>
                                <FormSection
                                    title={ <GuaranteeFillingFeeTitle title="Вознаграждение банка за выдачу" /> }
                                    waiting={ waiting }
                                >
                                    <BatchFillingFeeGR
                                        waiting={ waiting }
                                        disabled={ disabled }
                                        version={ version }
                                    />
                                </FormSection>
                            </FormPlate>
                        </LineIntent>
                        <VisibleBoundary visible={ !UtilBHR.isGuaranteeType44FZJointPurchases(guaranteeType) }>
                            <LineIntent top="xl">
                                <FormPlate>
                                    <BatchFillingFile waiting={ waiting } disabled={ disabled } />
                                </FormPlate>
                            </LineIntent>
                        </VisibleBoundary>
                    </VisibleBoundary>
                </GuaranteeFillingContractPropertiesErrorBoundary>
            </GuaranteeContractErrorBoundary>
            <LineIntent top="3xl">
                <ButtonCreate
                    waiting={ waiting }
                    disabled={ disabled || !contractId || Boolean(exceptionContractProperties) }
                />
            </LineIntent>
        </LineIntent>
    );
});

BatchFilling.displayName = nameof(BatchFilling);
