import React, { useCallback } from 'react';

import { AlfaMetricsAction, AlfaMetricsCategory, RequestCode } from '@ak-front/core/client/types';
import { RequestFeedbackInline } from '@ak-front/core/client/components/request-feedback-inline';
import { useDispatch, useSelector } from 'react-redux';
import { CoreAlfaMetricaActions } from '@ak-front/core/client';
import { getRoleU } from '@ak-front/core/client/store/selectors/user';

type RequestFeedbackProps = {
    /**
     * Код заявления
     */
    requestCode: RequestCode;
};

export const RequestFeedback: React.FC<RequestFeedbackProps> = React.memo(({ requestCode }) => {
    const dispatch = useDispatch();

    const role = useSelector(getRoleU);

    const handleSubmit = useCallback((rate: number, comment: string) => {
        dispatch(CoreAlfaMetricaActions.actionSendAlfaMetrica({
            category: AlfaMetricsCategory[requestCode],
            action: AlfaMetricsAction.Feedback,
            additionalData: { rate: `${rate}`, comment, role: `${role}` },
        }));
    }, [requestCode]);

    return <RequestFeedbackInline onSubmit={ handleSubmit } />;
});

RequestFeedback.displayName = nameof(RequestFeedback);
