import React, { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { Textarea } from '@alfalab/core-components/textarea';
import RateModal from 'arui-private/core/rate-modal';

import { ZIndexModal } from '@ak-front/core/constants';
import { actionSendAlfaMetrica } from '@ak-front/core/client/store/actions/creators/alfa-metrica';
import { AlfaMetricsCategory, AlfaMetricsAction } from '@ak-front/core/client/types/alfa-metrics';

type AppFeedbackModalProps = {
    visible: boolean;
};

type AppFeedbackModalEvents = {
    onSubmit?: (rate: number, comment: string) => void;
    onClose?: () => void;
};

const rateBarHints = ['Совсем неудобно', 'Неудобно', 'Могло быть удобнее', 'Удобно', 'Очень удобно'];

export const AppFeedbackModal: React.FC<AppFeedbackModalProps & AppFeedbackModalEvents> = React.memo((
    { visible, onSubmit, onClose },
) => {
    const dispatch = useDispatch();

    const [comment, setComment] = useState<string>('');

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }

        setComment('');
    }, [onClose]);

    const handleChange = useCallback((
        _: React.ChangeEvent<HTMLTextAreaElement>,
        payload: { value: string },
    ) => {
        setComment(payload?.value || '');
    }, []);

    const handleSubmit = (rate: number) => {
        if (onSubmit) {
            onSubmit(rate, comment);
        } else {
            dispatch(actionSendAlfaMetrica({
                category: AlfaMetricsCategory.App,
                action: AlfaMetricsAction.Feedback,
                additionalData: { rate: `${rate}`, comment },
            }));
        }
    };

    return (
        <RateModal
            size="m"
            disableEscapeKeyDown={ true }
            disableBackdropClick={ true }
            showDeclineButton={ true }
            hasCloser={ true }
            open={ visible }
            zIndex={ ZIndexModal }
            header={ (
                <React.Fragment>
                    Оцените удобство
                    <br />
                    Альфа-Кредита
                </React.Fragment>
            ) }
            description="Насколько комфортно пользоваться сервисом?"
            rateBarHints={ rateBarHints }
            descriptionSuccess="Вы помогаете нам сделать Альфа-Кредит лучше!"
            onSubmit={ handleSubmit }
            onClose={ handleClose }
        >
            <Textarea
                block={ true }
                label="Что мы можем улучшить?"
                maxLength={ 512 }
                value={ comment }
                onChange={ handleChange }
            />
        </RateModal>
    );
});
