export type Credentials = {
    login: string;
    password: string;
};
export type ChangePassword = {
    currentPassword: string;
    newPassword: string;
    confirmPassword: string;
};

export type Login = Pick<Credentials, 'login'>;
export type Password = Pick<Credentials, 'password'>;
export enum ChangePasswordModalStep {
    Change = 'Change',
    Final = 'Final',
}

export type UserAttribute = {
    email?: string;
    phoneNumber?: string;
};

export type UserData = {
    lastName?: string;
    firstName?: string;
    patronymicName?: string;
    attributes: Readonly<UserAttribute>;
};
