import React from 'react';
import { LINK_CORP_DASHBOARD } from '@ak-front/core/constants';

import Content from 'arui-private/core/content';
import Header from 'arui-private/core/header';
import Page from 'arui-private/core/page';

import { AppMenu } from '#/src/client/components/app-menu';
import { AppNavigation } from '#/src/client/components/app-navigation';

import './index.css';

export const PageInternal: React.FC<React.PropsWithChildren> = ({ children }) => (
    <Page
        className='page-internal'
        header={<Header rootUrl={LINK_CORP_DASHBOARD} menu={<AppMenu />} rightContent={<AppNavigation />} />}
    >
        <Content>{children}</Content>
    </Page>
);

PageInternal.displayName = nameof(PageInternal);
