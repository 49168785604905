import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';
import { Status } from '@alfalab/core-components/status';
import { ProgressBar } from '@alfalab/core-components/progress-bar';

import { FormControl } from '@ak-front/core/client/types';

import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { FormPlate } from '@ak-front/core/client/components/forms';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { RequestCode } from '@ak-front/core/client/types/request';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import { getRequestStatus, requestStatusAll } from '#/src/client/utils/dictionary';
import { UtilBHR } from '#/src/client/utils/batch';
import { BHRSelectors } from '#/src/client/store/selectors';

export const BatchViewInProgressInfo: React.FC<FormControl> = React.memo(({ waiting }) => {
    const batchRequest = useSelector(BHRSelectors.getBHR);
    const requestsCount = useSelector(BHRSelectors.getRequestsCountBHR);
    const idRequests = batchRequest.idRequests || [];

    const statusInfo = getRequestStatus(RequestCode.BatchRequest, requestStatusAll[batchRequest.status!]);
    const { progress, time } = UtilBHR.getProgressInfo(batchRequest.createdDateTime, requestsCount, idRequests.length);

    return (
        <FormPlate>
            <Grid.Row>
                <Grid.Col width="12">
                    <LineIntent>
                        <Skeleton width="available" visible={ waiting } size="xl">
                            <Status view="soft" color={ statusInfo.color }>
                                { statusInfo.text }
                            </Status>
                        </Skeleton>
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                <Grid.Col width="12">
                    <LineIntent top="m">
                        <Skeleton width="available" visible={ waiting } size="xl">
                            <Typography.Text view="primary-medium" color="primary">
                                Вы можете не
                                { NBSP }
                                дожидаться окончания загрузки
                                { NBSP }
                                и
                                { NBSP }
                                перейти
                                { NBSP }
                                в
                                { NBSP }
                                другой раздел. Реестр продолжит загружаться
                            </Typography.Text>
                        </Skeleton>
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row>
                <Grid.Col width="12">
                    <LineIntent top="m">
                        <Skeleton width="available" visible={ waiting } size="xl">
                            <Typography.Text view="primary-medium" color="primary" weight="bold">
                                Всего заявлений:
                                { NBSP }
                                { requestsCount }
                            </Typography.Text>
                        </Skeleton>
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row justify="left">
                <Grid.Col width="12">
                    <LineIntent top="xs">
                        <Skeleton width="available" visible={ waiting } size="4xl">
                            <ProgressBar value={ progress } view="link" />
                        </Skeleton>
                    </LineIntent>
                    <LineIntent top="2xs">
                        <Skeleton width="available" visible={ waiting } size="4xl">
                            <Typography.Text view="secondary-large" color="secondary">
                                Примерное время:
                                { NBSP }
                                { time }
                            </Typography.Text>
                        </Skeleton>
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
        </FormPlate>
    );
});

BatchViewInProgressInfo.displayName = nameof(BatchViewInProgressInfo);
