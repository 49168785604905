import React, { useState } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { CoreCertificateActions, CoreTasksActions } from '@ak-front/core/client';
import { checkPlugin } from '@ak-front/core/client/api/crypto-pro-api';
import Exception from '@ak-front/core/client/exception';
import { useDidMount } from '@ak-front/core/client/utils/hook';

import { Button } from '@alfalab/core-components/button';
import { Tooltip } from '@alfalab/core-components/tooltip';

import { SignInFormCertificateModal } from '#/src/client/pages/external/sign/in/form/certificate-modal';
import { SignInFormCspErrorPluginModal } from '#/src/client/pages/external/sign/in/form/csp-error-plugin-modal';
import { actionTaskSignInCertificateRun } from '#/src/client/store/actions/creators/task';
import { TaskSelectors, UserSelectors } from '#/src/client/store/selectors';

import './index.css';

type ButtonCertificateProps = {
    disabled?: boolean;
    onClick?: () => void;
    onSuccess?: (redirectUrl: string) => void;
    onFail?: (error: Exception, code?: string) => void;
};

export const SignInFormButtonCertificate: React.VFC<ButtonCertificateProps> = ({
    disabled,
    onClick,
    onSuccess,
    onFail,
}) => {
    const dispatch = useDispatch();

    const [isAllowedToReadCertificates, setIsAllowedToReadCertificates] = useState<boolean>(false);
    const [openPluginError, setPluginError] = useState<boolean>(false);
    const [openCertificates, setOpenCertificates] = useState<boolean>(false);
    const isSignInUpOff = useSelector(UserSelectors.isSignInUpOffAH);
    const statusTaskSignInCertificate = useSelector(TaskSelectors.getStatusTaskSignInCertificate);

    const handleSuccess = (returnUrl: string) => onSuccess?.(returnUrl);

    const handleFail = (error: Exception, code?: string) => onFail?.(error, code);

    const handleSignInCertificate = (thumbprint?: string) => {
        batch(() => {
            dispatch(CoreCertificateActions.actionSelectCertificateCP(thumbprint!));
            dispatch(actionTaskSignInCertificateRun(handleSuccess, handleFail));
        });
    };

    const handleOpenCertificates = async () => {
        try {
            await checkPlugin();

            if (isAllowedToReadCertificates) {
                setOpenCertificates(true);
            } else {
                dispatch(CoreTasksActions.actionTaskGetUserCertificatesRun());
            }
        } catch {
            setPluginError(true);
        }

        onClick?.();
    };

    const handleCloseCertificates = () => setOpenCertificates(false);
    const handleClosePluginError = () => setPluginError(false);

    useDidMount(() => {
        let requestid = 0;

        const onMessage = (messageEvent: MessageEvent) => {
            const message = messageEvent.data;

            switch (typeof message) {
                case 'object':
                    if (message.destination === 'nmcades') {
                        switch (message.method) {
                            case 'CreateObject':
                                if (requestid === 0 && message.params[0]?.value === 'CAPICOM.Store') {
                                    requestid = message.requestid;
                                }
                                break;
                        }
                    }

                    if (message.data?.type === 'result' && requestid === message.data?.requestid) {
                        setOpenCertificates(true);
                        setIsAllowedToReadCertificates(true);
                    }
                    break;
            }
        };

        window.addEventListener('message', onMessage);

        return () => window.removeEventListener('message', onMessage);
    });

    return (
        <React.Fragment>
            <Tooltip
                targetClassName='button-certificate__tooltip'
                view='tooltip'
                trigger='hover'
                content='Вход сработает, если вы хоть раз использовали УКЭП в Альфа-Кредите'
                position='right'
            >
                <Button
                    dataTestId='sign-in-certificate'
                    view='tertiary'
                    size='s'
                    block={true}
                    loading={statusTaskSignInCertificate}
                    disabled={disabled || isSignInUpOff || statusTaskSignInCertificate}
                    onClick={handleOpenCertificates}
                >
                    Войти через УКЭП
                </Button>
            </Tooltip>
            <SignInFormCertificateModal
                open={openCertificates}
                onSignIn={handleSignInCertificate}
                onClose={handleCloseCertificates}
            />
            <SignInFormCspErrorPluginModal open={openPluginError} onClose={handleClosePluginError} />
        </React.Fragment>
    );
};

SignInFormButtonCertificate.displayName = nameof(SignInFormButtonCertificate);
