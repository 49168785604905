import React from 'react';
import { Icon } from '@ak-front/core/client/components/icon';
import { LineIntent } from '@ak-front/core/client/components/line-intent';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { PasswordInput } from '@alfalab/core-components/password-input';
import { Typography } from '@alfalab/core-components/typography';
import { KeyMIcon } from '@alfalab/icons-glyph/KeyMIcon';

import { ChangePasswordForm, useChangePasswordCurrentForm } from '#/src/client/components/forms/change-password';

import './index.css';

type ChangePasswordFormWrapperProps = {
    onClose: () => void;
};

export const ChangePasswordFormWrapper: React.VFC<ChangePasswordFormWrapperProps> = ({ onClose }) => {
    const [
        currentPassword,
        errorCurrentPassword,
        loading,
        disabled,
        state,
        openNotification,
        openChipsValidation,
        statuses,
        onChangePassword,
        onChangeNewPassword,
        onFocusNewPassword,
        onBlurNewPassword,
        onChangeConfirmPassword,
        onFocusConfirmPassword,
        onCloseNotification,
        onChangeCurrentPassword,
        onFocusCurrentPassword,
    ] = useChangePasswordCurrentForm();

    return (
        <React.Fragment>
            <LineIntent className='change-password-modal__form-wrapper__icon' bottom='xl'>
                <Icon color='secondary' icon={KeyMIcon} />
            </LineIntent>
            <Typography.TitleResponsive tag='h1' view='small' font='system' color='primary'>
                Смена пароля
            </Typography.TitleResponsive>
            <Gap size='xl' />
            <PasswordInput
                id='current-password'
                size='m'
                block={true}
                label='Текущий пароль'
                value={currentPassword}
                disabled={disabled}
                error={errorCurrentPassword}
                onChange={onChangeCurrentPassword}
                onFocus={onFocusCurrentPassword}
            />
            <Gap size='m' />
            <ChangePasswordForm
                state={state}
                openChipsValidation={openChipsValidation}
                openNotification={openNotification}
                statuses={statuses}
                disabled={disabled}
                onBlurNewPassword={onBlurNewPassword}
                onChangeNewPassword={onChangeNewPassword}
                onFocusNewPassword={onFocusNewPassword}
                onChangeConfirmPassword={onChangeConfirmPassword}
                onFocusConfirmPassword={onFocusConfirmPassword}
                onCloseNotification={onCloseNotification}
            />
            <Grid.Row justify='left'>
                <Grid.Col width={{ desktop: '4', tablet: '4', mobile: '12' }}>
                    <Gap size='2xl' />
                    <Button
                        id='change-password'
                        view='primary'
                        size='s'
                        block={true}
                        loading={loading}
                        onClick={onChangePassword}
                    >
                        Сохранить
                    </Button>
                </Grid.Col>
                <Grid.Col width={{ desktop: '4', tablet: '4', mobile: '12' }}>
                    <Gap size='2xl' />
                    <Button id='cancel' view='secondary' size='s' block={true} disabled={disabled} onClick={onClose}>
                        Отменить
                    </Button>
                </Grid.Col>
            </Grid.Row>
        </React.Fragment>
    );
};

ChangePasswordFormWrapper.displayName = nameof(ChangePasswordFormWrapper);
