import React from 'react';
import { useSelector } from 'react-redux';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import Exception from '@ak-front/core/client/exception';
import { CoreCertificateSelectors } from '@ak-front/core/client/store/selectors';
import { LINK_USER_HELP } from '@ak-front/core/constants/all';

import { Button } from '@alfalab/core-components/button';
import { Link } from '@alfalab/core-components/link';
import { RepeatMIcon } from '@alfalab/icons-glyph/RepeatMIcon';

import { StalemateBaseError } from '#/src/client/components/error/stalemate';
import { UserSelectors } from '#/src/client/store/selectors';

import { SignOtherCertificates } from '../other-certificates';

export const SignUpCSPErrorBoundary: React.FC<React.PropsWithChildren> = React.memo(({ children }) => {
    const [, otherCertificates] = useSelector(CoreCertificateSelectors.getCertificateGroups);
    const cryptoProException = useSelector(CoreCertificateSelectors.getExceptionCP);
    const userException = useSelector(UserSelectors.getExceptionAH);

    const getError = (exception: Exception) => {
        const element = otherCertificates.length
            ? <SignOtherCertificates certificates={ otherCertificates } /> : null;

        const link = (
            <React.Fragment>
                Посмотрите
                { NBSP }
                <Link view="default" target="_blank" href={ LINK_USER_HELP }>раздел помощь</Link>
                { NBSP }
                или свяжитесь с поддержкой
            </React.Fragment>
        );

        const handleClick = () => window.location.reload();

        return (
            <StalemateBaseError
                title="Ошибка плагина КриптоПро"
                text={ exception.message }
                additionalText={ link }
                code={ exception.requestId }
            >
                { element }
                <LineIntent top="2xl">
                    <Button
                        id="update"
                        view="secondary"
                        size="s"
                        leftAddons={ <RepeatMIcon /> }
                        onClick={ handleClick }
                    >
                        Обновить страницу
                    </Button>
                </LineIntent>
            </StalemateBaseError>
        );
    };

    if (cryptoProException?.isErrorCryptoProviderForUser) {
        return getError(cryptoProException);
    }

    if (userException?.isErrorCryptoProviderForUser) {
        return getError(userException);
    }

    return <React.Fragment>{ children }</React.Fragment>;
});

SignUpCSPErrorBoundary.displayName = nameof(SignUpCSPErrorBoundary);
