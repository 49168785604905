import {
    all, call, fork, CallEffect, put, select, takeEvery, delay,
} from 'redux-saga/effects';
import { stringify as queryStringStringify } from 'query-string';

import * as GuaranteeApi from '@ak-front/guarantee-request/client/api/request-api';
import * as FileApi from '@ak-front/core/client/api/file-api';
import * as DaDataApi from '@ak-front/core/client/api/dadata-api';

import {
    ISuggestPurchaseLotsListItemCustomer,
    ISuggestPurchaseOrganizerModel,
} from '@ak-front/guarantee-request/client/api/fetch-client';
import { GuaranteeRequest } from '@ak-front/guarantee-request/client/types';

import { GRActions } from '@ak-front/guarantee-request/client/store/actions';
import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';
import { GRSagas } from '@ak-front/guarantee-request/client/store/sagas';

import * as FileHelper from '@ak-front/core/client/utils/file';
import { isDateValue } from '@ak-front/core/client/utils/date';

import {
    PATH_ROOT_ROUTE,
    DEFAULT_RECORD_LIMIT,
    DEFAULT_RECORD_OFFSET,
} from '@ak-front/core/constants';

import Exception, { ExceptionInitiator } from '@ak-front/core/client/exception';
import { ValidationParameterType, validate, ValidationResultType } from '@ak-front/core/client/validations';
import { RequestCode } from '@ak-front/core/client/types/request';
import { CoreNotificationManagerActions, CoreFileActions } from '@ak-front/core/client/store/actions/creators';
import { AttachedFile } from '@ak-front/core/client/types/file';
import { CoreFileSagas } from '@ak-front/core/client/store/sagas';
import { UtilGR } from '@ak-front/guarantee-request/client/utils';
import { IDaDataOrganizationModel } from '@ak-front/core/client/api/fetch-client';
import { actionSendAlfaMetrica } from '@ak-front/core/client/store/actions/creators/alfa-metrica';
import { AlfaMetricsAction, AlfaMetricsCategory } from '@ak-front/core/client/types';
import { BHRSelectors } from '#/src/client/store/selectors';
import { REGISTRY_GUARANTEE_FILE_RECORDS_MAX_SIZE } from '#/src/client/constants/batch';
import { PATH_REQUEST_BATCH_ROUTE } from '#/src/shared/constants/routes';
import {
    batchRequestGuaranteeSchema,
    isFileStructureValid,
    ValidationContextBatchGuaranteeRequestType,
} from '#/src/client/validations';
import { getRequestStatuses } from '#/src/client/utils/dictionary';
import { initReadXlsxFile } from '#/src/client/utils/spreadsheet';
import { UtilBHR } from '#/src/client/utils/batch';
import * as BatchSelectors from '#/src/client/store/selectors/request/batch';
import { BHRActions, BHRActionsType } from '#/src/client/store/actions/creators';
import { BHRAction } from '#/src/client/store/actions/constants/request/batch';
import {
    BatchFillingGuaranteeType,
    BatchRequest,
    BatchRequestForm,
    BatchRequestGuaranteeFileOptions,
    DefaultActionMeta,
} from '#/src/client/types';
import * as BatchApi from '#/src/client/api/batch-api';
import * as Api from '#/src/client/api/fetch-client';

/** Минимальная валидация заявления на гарантию */
export function* sagaMaxValidationGuaranteeBHR() {
    const guaranteeType: BatchFillingGuaranteeType = yield select(BHRSelectors.getGuaranteeTypeBHR);
    const guaranteeRequest: GuaranteeRequest = yield select(GRSelectors.getGR);
    const guaranteeRequests: GuaranteeRequest[] = yield select(BatchSelectors.getRequestsBHR);

    const validationContext: ValidationContextBatchGuaranteeRequestType = {
        guaranteeRequest,
        guaranteeRequests,
        guaranteeType,
    };
    const validationParameter: ValidationParameterType<GuaranteeRequest> = {
        obj: guaranteeRequest, schema: batchRequestGuaranteeSchema, context: validationContext,
    };

    const { valid, errors }: ValidationResultType = yield call(validate, validationParameter);

    yield put(GRActions.actionValidationErrorsGR(errors));

    return valid;
}

/** Получение списка пакетов */
export function* sagaGetListBHR() {
    try {
        const batches: BatchRequest[] = yield call(
            BatchApi.getBatchRequests,
            DEFAULT_RECORD_LIMIT,
            DEFAULT_RECORD_OFFSET,
        );

        const batchesCount = batches?.length || 0;

        yield put(BHRActions.actionSetListBHR(batches, batchesCount < DEFAULT_RECORD_LIMIT));
    } catch (ex) {
        yield put(BHRActions.actionExceptionBHR(ex as Exception));
    }
}

/** Получение заявлений в пакете */
export function* sagaFetchRequestsBHR() {
    try {
        const { idRequests = [] }: BatchRequest = yield select(BatchSelectors.getBHR);

        const requests: GuaranteeRequest[] = [];
        const limit = 40;
        let offset = 0;
        let count = 0;

        do {
            const ids = idRequests.slice(offset, offset + limit);

            if(ids.length === 0){
                break;
            }

            const partOfRequests: GuaranteeRequest[] = yield call(
                GuaranteeApi.getGuaranteeRequests,
                limit,
                0,
                undefined,
                undefined,
                ids,
            );

            count = ids.length;
            offset += limit;

            requests.push(...partOfRequests);
        } while (limit <= count);

        const orderedRequests = requests.sort((a, b) => idRequests.indexOf(a.id!) - idRequests.indexOf(b.id!));

        yield put(BHRActions.actionSetGuaranteeRequestsBHR(orderedRequests));
    } catch (ex) {
        yield put(BHRActions.actionExceptionBHR(ex as Exception));
    }
}

/** Получение пакета */
export function* sagaGetBHR(id: string) {
    try {
        const batchRequest: BatchRequest = yield call(BatchApi.getBatchRequestById, id);

        yield put(BHRActions.actionSetBHR(batchRequest));

        if (!UtilBHR.isInProgress(batchRequest.status)) {
            yield call(sagaFetchRequestsBHR);
        }
    } catch (ex) {
        yield put(BHRActions.actionExceptionBHR(ex as Exception));
    }
}

/** Получение пакета через интервал времени */
export function* sagaGetBatchRequestWithIntervalBHR() {
    try {
        let batchRequest: BatchRequest = yield select(BatchSelectors.getBHR);

        while (UtilBHR.isInProgress(batchRequest.status)) {
            yield delay(15000);
            yield call(sagaGetBHR, batchRequest.id!);

            batchRequest = yield select(BatchSelectors.getBHR);

            if (!UtilBHR.isInProgress(batchRequest.status)) {
                yield call(sagaGetBHR, batchRequest.id!);
            }
        }
    } catch (ex) {
        yield put(BHRActions.actionExceptionBHR(ex as Exception));
    }
}

export function* sagaSignGuaranteeRequest(idRequest: string, idClient: number, idDealState?: number) {
    const idFileRequest = yield call(GuaranteeApi.createRequestPrintForm, idRequest);

    return yield call(CoreFileSagas.sagaAddUserSignatoreFS, idFileRequest, idClient, idDealState);
}

export function* sagaSignGuaranteeRequestsByIds(idClient: number, requestIds: string[] = [], idDealState?: number) {
    const uniqueRequestIds = requestIds.filter((id, index, self) => self.indexOf(id) === index);

    const lastRequestId = uniqueRequestIds.pop();

    if (lastRequestId) {
        yield call(sagaSignGuaranteeRequest, lastRequestId, idClient, idDealState);

        if (uniqueRequestIds.length) {
            const promises: CallEffect[] = uniqueRequestIds.map((idRequest) => (
                call(sagaSignGuaranteeRequest, idRequest, idClient, idDealState)
            ));

            yield all(promises);
        }
    }
}

/** Подписание заявлений гарантии для пакетной загрузки */
function* sagaSigningGuarantiesBHR(idClient: number, idDealState?: number) {
    const { id, guaranteesCommonData } = yield select(BatchSelectors.getBHR);
    const guaranteeRequests = yield select(BatchSelectors.getRequestsBHR);
    const guaranteeRequestsIds = guaranteeRequests
        .filter(({ status }) => status === Api.GuaranteeRequestModelStatus.ToBeSigned)
        .map(({ id }) => id);

    if (guaranteeRequests.length) {
        yield call(sagaSignGuaranteeRequestsByIds, idClient, guaranteeRequestsIds, idDealState);

        yield put(actionSendAlfaMetrica({
            category: AlfaMetricsCategory.BatchRequest,
            action: AlfaMetricsAction.SendBank,
            additionalData: {
                id,
                idClient: `${idClient || ''}`,
                type: guaranteesCommonData?.type,
                quantity: guaranteeRequests.length,
                quantityOfSigned: guaranteeRequestsIds.length,
            },
        }));
    } else {
        yield put(CoreNotificationManagerActions.actionAddNotificationSuccess(
            'В пакете нет заявлений в статусе "На подпись"',
        ));
    }
}

/** Подпись файлов и создание пакета */
export function* sagaClientSigningAndSendToBankBHR() {
    const idClient: number | undefined = yield select(BatchSelectors.getOrganizationIdBHR);
    const idDealState: number | undefined = yield select(BatchSelectors.getContractStateIdBHR);

    const { id } = yield select(BatchSelectors.getBHR);

    yield call(sagaSigningGuarantiesBHR, idClient!, idDealState);
    yield call(BatchApi.processBatchRequest, id);
    yield call(sagaGetBHR, id);

    yield put(CoreNotificationManagerActions.actionAddNotificationSuccess(
        'Заявления успешно подписаны и отправляются в банк',
    ));
}

function* sagaCreateGuaranteeRequestByCustomer(customer: ISuggestPurchaseLotsListItemCustomer) {
    const gr = yield select(GRSelectors.getGR);
    const subtype = yield select(GRSelectors.get44SubtypeGR);

    const { organization, identificationCodePurchases } = customer;

    let daDataOrganization: IDaDataOrganizationModel | undefined;

    const {
        name = '', inn = '', kpp = '', ogrnSource: ogrn = '', factAddress = '', phone = '', email = '', regionCode, idEIS,
    } = (organization || {}) as ISuggestPurchaseOrganizerModel;

    try {
        if (inn) {
            /** Подгрузка дополнительных данных из DaData */
            daDataOrganization = yield call(DaDataApi.findDaDataOrganization, inn!);
        }
    } catch (e) {
        // do something
    }

    const jurisdictions = yield select(GRSelectors.getJurisdictionsGR);
    const jurisdiction = jurisdictions.find((r) => r.regionCodes?.includes(regionCode));

    const subjectRegions = yield select(GRSelectors.getSubjectRegionsGR);
    const subjectRegion = subjectRegions.find((r) => r.regionCodes?.includes(regionCode));

    return {
        ...gr,
        sum: UtilGR.getPurchaseAmountBySubtype(subtype, customer),
        additionalData: {
            tender44: {
                ...gr.additionalData.tender44,
                purchaseIdentificationCode: identificationCodePurchases,
            },
        },
        beneficiaryInfo: {
            name,
            inn,
            kpp,
            ogrn,
            oktmo: daDataOrganization?.oktmo,
            email,
            phone,
            registrationNumber: idEIS,
            subjectRfName: subjectRegion?.regionName,
            subjectRfCode: regionCode,
            beneficiaryBankInfo: {},
            beneficiaryContactInfo: { legalAddress: factAddress },
        },
        jurisdiction:
            jurisdiction
                ? [jurisdiction.abbr, jurisdiction.name].filter(Boolean).join(' ')
                : undefined,
    };
}

function* sagaBuildBatchRequestBHR(formData: BatchRequestForm) {
    switch (formData.requestCode) {
    case RequestCode.GuaranteeRequest: {
        const batchGuaranteeType = yield select(BHRSelectors.getGuaranteeTypeBHR);
        const guaranteeRequest = yield select(GRSelectors.getGR);

        switch (batchGuaranteeType) {
        case BatchFillingGuaranteeType.Tender44FZJointPurchases: {
            const customersList = yield select(GRSelectors.get44PurchaseCustomersListGR);

            const createGuaranteeCommands = yield all(
                customersList.map((customer) => call(sagaCreateGuaranteeRequestByCustomer, customer)),
            );

            return {
                requestCode: formData.requestCode,
                guaranteesCommonData: { ...guaranteeRequest },
                createGuaranteeCommands,
            };
        }
        case BatchFillingGuaranteeType.FNS:
        case BatchFillingGuaranteeType.Tender44FZ:
        case BatchFillingGuaranteeType.Rent:
        default:
            return {
                requestCode: formData.requestCode,
                guaranteesCommonData: { ...guaranteeRequest },
                createGuaranteeCommands: formData.guaranteeRequests.map(
                    (gr) => UtilBHR.mergeGuaranteeRequests(guaranteeRequest, gr),
                ),
            };
        }
    }
    default:
        return {};
    }
}

/** Создание сущности пакетной загрузки  */
export function* sagaCreateBHR(meta?: DefaultActionMeta) {
    yield put(BHRActions.actionSetErrorsBHR());

    yield call(GRSagas.sagaSupplementDataOnOrganizationWithDaData);

    const batchRequestForm: BatchRequestForm = yield select(BatchSelectors.getFormBHR);

    try {
        const batchRequest: BatchRequest = yield call(sagaBuildBatchRequestBHR, batchRequestForm);

        const { id, grouppedErrors = [] } = yield call(BatchApi.createBatchRequest, batchRequest);

        if (Boolean(grouppedErrors.length) && Boolean(grouppedErrors.find((errors) => Object.keys(errors).length))) {
            switch (batchRequestForm.requestCode) {
            case RequestCode.GuaranteeRequest: {
                const guaranteeType: Api.GuaranteeRequestModelType = yield select(GRSelectors.getTypeGR);

                const { formErrors, fileErrors } = UtilBHR.getGuaranteeErrors(guaranteeType, grouppedErrors);

                yield put(GRActions.actionValidationErrorsGR(formErrors));
                yield put(BHRActions.actionSetErrorsBHR(fileErrors));

                break;
            }
            }
            return false;
        }

        yield put(actionSendAlfaMetrica({
            category: AlfaMetricsCategory.BatchRequest,
            action: AlfaMetricsAction.CreateDraft,
            additionalData: {
                id,
                idClient: `${batchRequest.guaranteesCommonData?.principalInfo?.idClient || ''}`,
                type: batchRequestForm.guaranteeType || '',
                quantity: `${batchRequest.createGuaranteeCommands?.length || ''}`,
            },
        }));

        yield put(CoreFileActions.actionSetAttachmentFilesFS([]));
        yield put(CoreNotificationManagerActions.actionAddNotificationSuccess('Пакет с заявлениями успешно создан'));

        yield fork(GRSagas.sagaSend44FzPrincipalInfoFieldsFillSourceMetric);
        yield call(GRSagas.sagaSavePrincipalContactDetailsToCache);

        return meta?.history?.push(`${PATH_REQUEST_BATCH_ROUTE}/${id}`);
    } catch (e) {
        const exception = e as Exception;

        if (exception.isErrorValidationForUser) {
            yield put(GRActions.actionValidationErrorsGR(exception.errors!));
        } else {
            yield put(CoreNotificationManagerActions.actionAddNotificationError(exception));
        }

        throw e;
    }
}

/** Создание сущности пакетной загрузки  */
export function* sagaRemoveBHR(meta?: DefaultActionMeta) {
    try {
        const batchRequest: BatchRequest = yield select(BatchSelectors.getBHR);

        if (batchRequest.id) {
            yield call(BatchApi.removeBatchRequest, batchRequest.id);

            yield put(CoreNotificationManagerActions.actionAddNotificationSuccess('Пакет успешно удален'));

            return meta?.history?.push(
                `${PATH_ROOT_ROUTE}?${queryStringStringify({
                    requestCode: RequestCode.GuaranteeRequest,
                    statuses: getRequestStatuses(RequestCode.GuaranteeRequest, false, true),
                })}`,
            );
        }

        return false;
    } catch (e) {
        yield put(CoreNotificationManagerActions.actionAddNotificationError(e as Exception));

        throw e;
    }
}

export function* sagaProcessBatchGuaranteeFileDataBHR(file?: AttachedFile, options?: BatchRequestGuaranteeFileOptions) {
    if (!file) {
        return;
    }

    const sourceData = yield call(yield call(initReadXlsxFile), FileHelper.getBlobByUrl(file.blobUrl!));

    // Отсекаем первую строку, потому что она является заголовком таблицы
    const data = sourceData.slice(1);

    if (data.length > REGISTRY_GUARANTEE_FILE_RECORDS_MAX_SIZE) {
        throw new Exception({
            message: `Максимальное количество заявлений создаваемых в одном пакете - ${REGISTRY_GUARANTEE_FILE_RECORDS_MAX_SIZE}`,
            initiator: ExceptionInitiator.Client,
        });
    }

    if (!isFileStructureValid(data, { requestCode: RequestCode.GuaranteeRequest, guaranteeType: options?.type })) {
        throw new Exception({
            message: 'Некорректная структура шаблона реестра заявлений',
            initiator: ExceptionInitiator.Client,
        });
    }

    const rows = data.map((row) => row.map((cell) => (
        isDateValue(cell)
            ? [
                (`0${cell.getDate()}`).slice(-2),
                (`0${cell.getMonth() + 1}`).slice(-2),
                cell.getFullYear(),
            ].join('.') : cell
    )));

    switch (options?.type) {
    case Api.GuaranteeRequestModelType.FNS:
        yield put(
            BHRActions.actionSetGuaranteeRequestsBHR(UtilBHR.parseGuaranteeFNSFile(rows), rows),
        );
        break;
    case Api.GuaranteeRequestModelType.Tender_44_FZ:
        yield put(
            BHRActions.actionSetGuaranteeRequestsBHR(UtilBHR.parseGuaranteeTender44FzFile(rows), rows),
        );
        break;
    case Api.GuaranteeRequestModelType.Rent:
        yield put(
            BHRActions.actionSetGuaranteeRequestsBHR(UtilBHR.parseGuaranteeRentFile(rows), rows),
        );
        break;
    }
}

export function* sagaLoadBatchFileDataBHR(
    action: Extract<BHRActionsType, { type: BHRAction.LOAD_FILE_DATA_BHR }>,
) {
    yield put(BHRActions.actionSetErrorsBHR());

    const { requestCode, file, options } = action.payload;

    try {
        switch (requestCode) {
        case RequestCode.GuaranteeRequest:
            yield call(sagaProcessBatchGuaranteeFileDataBHR, file, options);
            break;
        }
    } catch (e) {
        const exception = e as Exception;

        yield put(CoreFileActions.actionSetAttachmentFilesFS([]));
        yield put(CoreNotificationManagerActions.actionAddNotificationError(
            new Exception({
                message: exception.message,
                initiator: ExceptionInitiator.Client,
            }),
        ));
    }
}

export function* sagaDownloadErrorsFileBHR() {
    try {
        const payload = {
            data: yield select(BatchSelectors.getSourceDataBHR),
            errors: yield select(BatchSelectors.getErrorsBHR),
            options: {
                requestCode: Api.BatchRequestErrorsPayloadOptionsRequestCode.GuaranteeRequest,
                guaranteeType: yield select(GRSelectors.getTypeGR),
            },
        };

        const file: Api.FileResponse = yield call(BatchApi.getErrorsFile, payload as Api.BatchRequestErrorsPayload);

        if (!file.data) {
            throw new Exception({ message: 'Файл поврежден', initiator: ExceptionInitiator.Client });
        }

        yield call(
            FileHelper.saveFile,
            file.fileName || 'Ошибки реестровых гарантий (ошибки).xlsx',
            file.data,
        );
    } catch (ex) {
        yield put(CoreNotificationManagerActions.actionAddNotificationError(ex as Exception));

        throw ex;
    }
}

export function* sagaLoadMoreBatches() {
    try {
        const batchList: BatchRequest[] = yield select(BatchSelectors.getListBHR);
        const offset: number = batchList.length;

        const batches: BatchRequest[] = yield call(
            BatchApi.getBatchRequests,
            DEFAULT_RECORD_LIMIT,
            DEFAULT_RECORD_OFFSET + offset,
        );

        const batchesCount = batches?.length || 0;

        yield put(
            BHRActions.actionAddBatches(batches, batchesCount < DEFAULT_RECORD_LIMIT),
        );
    } catch (ex) {
        yield put(CoreNotificationManagerActions.actionAddNotificationError(ex as Exception));
    }
}

export function* sagaDownloadRequestPrintFormFile(
    action: Extract<BHRActionsType, { type: BHRAction.DOWNLOAD_REQUEST_PRINT_FORM_FILE_BHR }>,
) {
    try {
        const file: Api.FileResponse = yield call(FileApi.getContentById, action.payload.idFile);

        if (!file.data) {
            throw new Exception({ message: 'Файл поврежден', initiator: ExceptionInitiator.Client });
        }

        yield call(FileHelper.saveFile, file.fileName, file.data);
    } catch (ex) {
        yield put(CoreNotificationManagerActions.actionAddNotificationError(ex as Exception));
    }
}

export default function* root() {
    yield takeEvery(BHRAction.LOAD_FILE_DATA_BHR, sagaLoadBatchFileDataBHR);
    yield takeEvery(BHRAction.DOWNLOAD_REQUEST_PRINT_FORM_FILE_BHR, sagaDownloadRequestPrintFormFile);
}
