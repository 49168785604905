export enum TaskAction {
    TASK_SIGN_IN_RUN = '@@ak/task/TASK_SIGN_IN_RUN',
    TASK_SIGN_IN_STOP = '@@ak/task/TASK_SIGN_IN_STOP',

    TASK_SIGN_IN_CERTIFICATE_RUN = '@@ak/task/TASK_SIGN_IN_CERTIFICATE_RUN',
    TASK_SIGN_IN_CERTIFICATE_STOP = '@@ak/task/TASK_SIGN_IN_CERTIFICATE_STOP',

    TASK_SIGN_UP_CERTIFICATE_RUN = '@@ak/task/TASK_SIGN_UP_CERTIFICATE_RUN',
    TASK_SIGN_UP_CERTIFICATE_STOP = '@@ak/task/TASK_SIGN_UP_CERTIFICATE_STOP',

    TASK_CHANGE_PASSWORD_RUN = '@@ak/task/TASK_CHANGE_PASSWORD_RUN',
    TASK_CHANGE_PASSWORD_STOP = '@@ak/task/TASK_CHANGE_PASSWORD_STOP',

    TASK_SIGN_OUT_RUN = '@@ak/task/TASK_SIGN_OUT_RUN',
    TASK_SIGN_OUT_STOP = '@@ak/task/TASK_SIGN_OUT_STOP',

    TASK_SEND_CONFIRMATION_RUN = '@@ak/task/TASK_SEND_CONFIRMATION_RUN',
    TASK_SEND_CONFIRMATION_STOP = '@@ak/task/TASK_SEND_CONFIRMATION_STOP',

    TASK_CHECK_CONFIRMATION_RUN = '@@ak/task/TASK_CHECK_CONFIRMATION_RUN',
    TASK_CHECK_CONFIRMATION_STOP = '@@ak/task/TASK_CHECK_CONFIRMATION_STOP',

    TASK_GET_LIST_BHR_RUN = '@@ak/request/batch/TASK_GET_LIST_BHR_RUN',
    TASK_GET_LIST_BHR_STOP = '@@ak/request/batch/TASK_GET_LIST_BHR_STOP',

    TASK_CREATE_BHR_RUN = '@@ak/request/batch/TASK_CREATE_BHR_RUN',
    TASK_CREATE_BHR_STOP = '@@ak/request/batch/TASK_CREATE_BHR_STOP',

    TASK_REMOVE_BHR_RUN = '@@ak/request/batch/TASK_REMOVE_BHR_RUN',
    TASK_REMOVE_BHR_STOP = '@@ak/request/batch/TASK_REMOVE_BHR_STOP',

    TASK_GET_BHR_RUN = '@@ak/request/batch/TASK_GET_BHR_RUN',
    TASK_GET_BHR_STOP = '@@ak/request/batch/TASK_GET_BHR_STOP',

    TASK_DOWNLOAD_ERRORS_BHR_RUN = '@@ak/request/batch/TASK_DOWNLOAD_ERRORS_BHR_RUN',
    TASK_DOWNLOAD_ERRORS_BHR_STOP = '@@ak/request/batch/TASK_DOWNLOAD_ERRORS_BHR_STOP',

    TASK_LOAD_MORE_BATCHES_RUN = '@@ak/request/current/TASK_LOAD_MORE_BATCHES_RUN',
    TASK_LOAD_MORE_BATCHES_STOP = '@@ak/request/current/TASK_LOAD_MORE_BATCHES_STOP',
}
