import React, { useCallback } from 'react';
import { useDispatch, useSelector, batch } from 'react-redux';

import { RadioGroup } from '@alfalab/core-components/radio-group';
import { Tag } from '@alfalab/core-components/tag';

import { FormControl } from '@ak-front/core/client/types';

import { GRActions } from '@ak-front/guarantee-request/client/store/actions';
import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';

import { useScrollToError } from '@ak-front/core/client/utils/hook';
import { fieldsNames } from '@ak-front/guarantee-request/client/validations';
import { validationShallowEqual } from '@ak-front/core/client/validations';
import { batchGuaranteeTypeDictionary } from '#/src/client/utils/dictionary';

import { BatchFillingGuaranteeType } from '#/src/client/types';
import { BHRActions } from '#/src/client/store/actions/creators';
import { BHRSelectors } from '#/src/client/store/selectors';
import { UtilBHR } from '#/src/client/utils/batch';

const validationFields = {
    type: fieldsNames.type.type,
};

export const BatchFillingTypeGR: React.FC<FormControl> = React.memo(({ disabled }) => {
    const dispatch = useDispatch();

    const guaranteeType = useSelector(BHRSelectors.getGuaranteeTypeBHR);
    const validationErrors = useSelector(GRSelectors.getValidationErrorsGR, validationShallowEqual(validationFields));

    const refType = useScrollToError<HTMLInputElement>(validationErrors, validationFields);

    const tags = batchGuaranteeTypeDictionary.map(({ value, text }) => (
        <Tag key={ value } value={ value } disabled={ disabled }>
            { text }
        </Tag>
    ));

    const handleSelectType = useCallback((
        _?: React.ChangeEvent | React.MouseEvent,
        payload?: { value: string; name?: string },
    ) => {
        const value = payload?.value as BatchFillingGuaranteeType;

        if (value && value !== guaranteeType) {
            batch(() => {
                dispatch(BHRActions.actionSetGuaranteeTypeBHR(value));
                dispatch(GRActions.actionSelectTypeGR(UtilBHR.mapBatchGuaranteeTypeToGuaranteeType(value)!));
            });
        }
    }, [guaranteeType]);

    return (
        <RadioGroup
            direction="horizontal"
            type="tag"
            value={ guaranteeType }
            ref={ refType }
            disabled={ disabled }
            dataTestId="guarantee-type"
            error={ validationErrors[validationFields.type] }
            onChange={ handleSelectType }
        >
            { tags }
        </RadioGroup>
    );
});

BatchFillingTypeGR.displayName = nameof(BatchFillingTypeGR);
