import React from 'react';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { Modal as ModalResponsive } from '@alfalab/core-components/modal';
import { Typography } from '@alfalab/core-components/typography';

import { LINK_CSP_PLUGIN } from '#/src/client/constants';

type SignInFormCspErrorPluginModalProps = {
    open: boolean;
    onClose: () => void;
};

export const SignInFormCspErrorPluginModal: React.VFC<SignInFormCspErrorPluginModalProps> = ({ open, onClose }) => (
    <ModalResponsive open={open} size='m' onClose={onClose}>
        <ModalResponsive.Header title='Не найден плагин КриптоПро' hasCloser={true} />
        <ModalResponsive.Content flex={true}>
            <Gap size='xl' />
            <Typography.Text>Скачайте его на сайте КриптоПро или войдите другим способом</Typography.Text>
            <br />
            <Gap size='xl' />
            <Link view='primary' href={LINK_CSP_PLUGIN} target='_blank'>
                Как скачать и установить плагин КриптоПро
            </Link>
            <Gap size='xs' />
        </ModalResponsive.Content>
        <ModalResponsive.Footer sticky={true}>
            <Button onClick={onClose}>Понятно</Button>
        </ModalResponsive.Footer>
    </ModalResponsive>
);

SignInFormCspErrorPluginModal.displayName = nameof(SignInFormCspErrorPluginModal);
