import React from 'react';

import { Typography } from '@alfalab/core-components/typography';
import { Link } from '@alfalab/core-components/link';
import { Amount, CurrencyCodes } from '@alfalab/core-components/amount';
import { Status } from '@alfalab/core-components/status';
import { NBSP } from '@ak-front/core/client/constants/formatters';

import { GuaranteeRequestModelType as GuaranteeType } from '@ak-front/guarantee-request/client/api/fetch-client';
import { GuaranteeRequest } from '@ak-front/guarantee-request/client/types';

import { UtilGR } from '@ak-front/guarantee-request/client/utils/request';
import { formatDate } from '@ak-front/core/client/utils/date';
import { RequestCode } from '@ak-front/core/client/types/request';
import { getRequestStatus, requestStatusAll } from '#/src/client/utils/dictionary';

type BatchViewTableSettingsCol = {
    name: string;
    title: string;
    getValue: (request: GuaranteeRequest, callback?: (...args: any) => void) => React.ReactNode;
    width?: number;
    align?: 'left' | 'right';
}

type BatchViewTableSettings = Partial<Record<RequestCode, Partial<Record<GuaranteeType, BatchViewTableSettingsCol[]>>>>;

const getDocNumberValue: BatchViewTableSettingsCol['getValue'] = (request: GuaranteeRequest, callback?: () => void) => (
    <Link view="default" onClick={ callback }>{ request.docNumber }</Link>
);

const getIssueDateValue: BatchViewTableSettingsCol['getValue'] = (request: GuaranteeRequest) => (
    formatDate(request.issueDate)
);

const getSumValue: BatchViewTableSettingsCol['getValue'] = (request: GuaranteeRequest) => (
    request.sum
        ? (
            <Amount
                value={ request.sum * 100 }
                currency={ request.currencyCode as CurrencyCodes }
                minority={ 100 }
                view="withZeroMinorPart"
                bold="full"
                transparentMinor={ true }
            />
        ) : null
);

const getStatusValue: BatchViewTableSettingsCol['getValue'] = (request: GuaranteeRequest) => {
    const { color, text } = getRequestStatus(
        RequestCode.GuaranteeRequest,
        requestStatusAll[request.status!],
    );

    return <Status view="soft" color={ color }>{ text }</Status>;
};

const getBeneficiaryInfoValue: BatchViewTableSettingsCol['getValue'] = (request: GuaranteeRequest) => {
    const { name, inn } = request.beneficiaryInfo || {};

    return (
        <React.Fragment>
            { name }
            { NBSP }
            <br />
            { inn ? (
                <Typography.Text view="primary-small" color="secondary">
                    ИНН №
                    { NBSP }
                    { inn }
                </Typography.Text>
            ) : '' }
        </React.Fragment>
    );
};

export const tableSettings: BatchViewTableSettings = {
    [RequestCode.GuaranteeRequest]: {
        [GuaranteeType.FNS]: [
            {
                name: 'docNumber', title: 'Заявление', getValue: getDocNumberValue,
            },
            {
                name: 'purposeCode',
                title: 'Разновидность',
                getValue: (request: GuaranteeRequest) => (
                    UtilGR.getPurposeCodeTitle(request.additionalData?.fns?.purposeCode)
                ),
            },
            {
                name: 'issueDate', title: 'Дата выдачи', getValue: getIssueDateValue, width: 135,
            },
            {
                name: 'sum', title: 'Сумма', align: 'right', getValue: getSumValue,
            },
            {
                name: 'status', title: 'Статус', align: 'right', getValue: getStatusValue,
            },
        ],
        [GuaranteeType.Tender_44_FZ]: [
            {
                name: 'docNumber', title: 'Заявление', getValue: getDocNumberValue,
            },
            {
                name: 'notice',
                title: 'Извещение',
                getValue: (request: GuaranteeRequest) => request.additionalData?.tender44?.notificationNumber,
            },
            {
                name: 'issueDate', title: 'Дата выдачи', getValue: getIssueDateValue, width: 135,
            },
            {
                name: 'beneficiaryInfo', title: 'Бенефициар', getValue: getBeneficiaryInfoValue,
            },
            {
                name: 'sum', title: 'Сумма', align: 'right', getValue: getSumValue,
            },
            {
                name: 'status', title: 'Статус', align: 'right', getValue: getStatusValue,
            },
        ],
        [GuaranteeType.Rent]: [
            {
                name: 'docNumber', title: 'Заявление', getValue: getDocNumberValue,
            },
            {
                name: 'contractDescription',
                title: 'Наименование договора аренды',
                getValue: (request: GuaranteeRequest) => request.additionalData?.rent?.contractDescription,
            },
            {
                name: 'issueDate', title: 'Дата выдачи', getValue: getIssueDateValue, width: 135,
            },
            {
                name: 'beneficiaryInfo', title: 'Бенефициар', getValue: getBeneficiaryInfoValue,
            },
            {
                name: 'sum', title: 'Сумма', align: 'right', getValue: getSumValue,
            },
            {
                name: 'status', title: 'Статус', align: 'right', getValue: getStatusValue,
            },
        ],
    },
};
