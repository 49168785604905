/**
 * Саги (менеджеры процессов) для управления сложной асинхронной логикой
 */
import { all, fork } from 'redux-saga/effects';
import {
    BankRequestSagaContext, BRPageSagas, BRSagas, BRTasksSagas,
} from '@ak-front/bank-request/client/store/sagas';
import { CoreFileSagas, CoreSagaContext, CoreTasksSagas } from '@ak-front/core/client/store/sagas';
import { RequestCode } from '@ak-front/core/client/types/request';
import { PATH_ROOT_ROUTE } from '@ak-front/core/constants';
import {
    CreditRequestSagaContext,
    CRPageSagas,
    CRSagas,
    CRTasksSagas,
} from '@ak-front/credit-request/client/store/sagas';
import { DealReportPageSagas, DealReportTasksSagas } from '@ak-front/deal-report/client/store/sagas';
import {
    DADataSagas,
    DATasksSagas,
} from '@ak-front/document-aggregation/client/store/sagas';
import {
    DocumentQueryRequestSagaContext,
    DQRPageSagas,
    DQRSagas,
    DQRTasksSagas,
} from '@ak-front/document-query-request/client/store/sagas';
import {
    DocumentRequestSagaContext,
    DRPageSagas,
    DRSagas,
    DRTasksSagas,
} from '@ak-front/document-request/client/store/sagas';
import { 
    GRPageSagas,
    GRSagas, 
    GRTasksSagas, 
    GuaranteeRequestSagaContext
 } from '@ak-front/guarantee-request/client/store/sagas';
import {
    IGFilterSagas,
    IGPageSagas,
    IGSagas,
    IGTasksSagas
 } from '@ak-front/incoming-guarantee/client/store/sagas';
import {
    TCRFilterSagas,
    TCRPageSagas,
    TCRSagas,
    TCRTasksSagas,
} from '@ak-front/tranche-change-request/client/store/sagas';
import { stringify as queryStringStringify } from 'query-string';

import batchPageSaga from './pages/batch-page';
import headPageSaga from './pages/head-page';
import { sagaLoadDataForRequestBR } from './request/bank';
import batchSaga, { sagaClientSigningAndSendToBankBHR } from './request/batch';
import { sagaLoadDataForRequestCR, sagaLoadDataForRequestNewCR } from './request/credit';
import { sagaLoadDataForRequestDR } from './request/document';
import { sagaLoadDataForRequestDQR } from './request/document-query';
import { sagaLoadDataForRequestGR, sagaLoadDataForRequestNewGR } from './request/guarantee';
import taskInfoSaga from './task';

export const getCoreSagaContextObject = (): CoreSagaContext
    & DocumentQueryRequestSagaContext
    & DocumentRequestSagaContext
    & GuaranteeRequestSagaContext
    & CreditRequestSagaContext
    & BankRequestSagaContext => ({
    core: {
        sagaSigningAndSend: {
            [RequestCode.DocumentQueryRequest]: DQRSagas.sagaClientSigningAndSendToBankDQR,
            [RequestCode.DocumentRequest]: DRSagas.sagaClientSigningAndSendToBankDR,
            [RequestCode.CreditRequest]: CRSagas.sagaClientSigningAndSendToBankCR,
            [RequestCode.GuaranteeRequest]: GRSagas.sagaClientSigningAndSendToBankGR,
            [RequestCode.BankRequest]: BRSagas.sagaClientSigningAndSendToBankBR,
            [RequestCode.BatchRequest]: sagaClientSigningAndSendToBankBHR,
            [RequestCode.TrancheChangeRequest]: TCRSagas.sagaClientSigningAndSendToBankTCR,
        },
    },
    documentQueryRequest: {
        sagaLoadDataForRequest: sagaLoadDataForRequestDQR,
    },
    documentRequest: {
        sagaLoadDataForRequestNew: DRPageSagas.sagaLoadOrganizationsDR,
        sagaLoadDataForRequest: sagaLoadDataForRequestDR,
        redirectToPathAfterDelete: `${PATH_ROOT_ROUTE}?${queryStringStringify({
            requestCode: RequestCode.DocumentRequest,
        })}`,
    },
    guaranteeRequest: {
        sagaLoadContracts: sagaLoadDataForRequestNewGR,
        sagaLoadDataForRequestNew: sagaLoadDataForRequestNewGR,
        sagaLoadDataForRequest: sagaLoadDataForRequestGR,
        redirectToPathAfterDelete: `${PATH_ROOT_ROUTE}?${queryStringStringify({
            requestCode: RequestCode.GuaranteeRequest,
        })}`,
    },
    creditRequest: {
        sagaLoadContracts: sagaLoadDataForRequestNewCR,
        sagaLoadDataForRequestNew: sagaLoadDataForRequestNewCR,
        sagaLoadDataForRequest: sagaLoadDataForRequestCR,
        redirectToPathAfterDelete: `${PATH_ROOT_ROUTE}?${queryStringStringify({
            requestCode: RequestCode.CreditRequest,
        })}`,
    },
    bankRequest: {
        sagaLoadDataForRequest: sagaLoadDataForRequestBR,
    },
});

export default function* rootSaga() {
    yield all([
        fork(batchPageSaga),
        fork(headPageSaga),
        fork(taskInfoSaga),
        fork(batchSaga),

        fork(CoreTasksSagas.root),
        fork(CoreFileSagas.root),

        fork(DADataSagas.root),
        fork(DATasksSagas.root),

        fork(DQRPageSagas.root),
        fork(DQRTasksSagas.root),

        fork(DRSagas.root),
        fork(DRPageSagas.root),
        fork(DRTasksSagas.root),

        fork(GRSagas.root),
        fork(GRPageSagas.root),
        fork(GRTasksSagas.root),

        fork(IGSagas.root),
        fork(IGFilterSagas.root),
        fork(IGTasksSagas.root),
        fork(IGPageSagas.root),

        fork(TCRSagas.root),
        fork(TCRFilterSagas.root),
        fork(TCRPageSagas.root),
        fork(TCRTasksSagas.root),

        fork(CRSagas.root),
        fork(CRPageSagas.root),
        fork(CRTasksSagas.root),

        fork(BRSagas.root),
        fork(BRPageSagas.root),
        fork(BRTasksSagas.root),

        fork(DealReportPageSagas.root),
        fork(DealReportTasksSagas.root),
    ]);
}
