import { GuaranteeRequest } from '@ak-front/guarantee-request/client/types';
import Exception from '@ak-front/core/client/exception';

import { RequestCode } from '@ak-front/core/client/types/request';
import { AttachedFile } from '@ak-front/core/client/types/file';
import { DeepPartial } from '@ak-front/core/types/utils';
import { BHRAction } from '#/src/client/store/actions/constants/request/batch';
import {
    BatchFillingGuaranteeType,
    BatchRequest,
    BatchRequestErrors,
    BatchRequestFileOptions,
    BatchRequestFilterStatus,
} from '#/src/client/types';

/** Установка списка пакетных загрузок */
export const actionSetListBHR = (batches: BatchRequest[], eof: boolean) => ({
    type: BHRAction.SET_LIST_BHR as const,
    payload: { batches, eof },
});

/** Добавление пакетный заданий в общий список */
export const actionAddBatches = (batches: BatchRequest[], eof: boolean) => ({
    type: BHRAction.ADD_TO_LIST_BHR as const,
    payload: { batches, eof },
});

export const actionSetBHR = (batchRequest: BatchRequest) => ({
    type: BHRAction.SET_BHR as const,
    payload: { batchRequest },
});

export const actionSetGuaranteeTypeBHR = (guaranteeType?: BatchFillingGuaranteeType) => ({
    type: BHRAction.SET_GUARANTEE_TYPE_BHR as const,
    payload: { guaranteeType },
});

export const actionLoadFileDataBHR = (
    requestCode: RequestCode,
    file?: AttachedFile,
    options?: BatchRequestFileOptions,
) => ({
    type: BHRAction.LOAD_FILE_DATA_BHR as const,
    payload: { requestCode, file, options },
});

export const actionDownloadRequestPrintFormFileBHR = (idFile: string) => ({
    type: BHRAction.DOWNLOAD_REQUEST_PRINT_FORM_FILE_BHR as const,
    payload: { idFile },
});

export const actionSetGuaranteeRequestsBHR = (
    guaranteeRequests: Array<DeepPartial<GuaranteeRequest>> = [],
    sourceData: Array<Array<string | number | Date | undefined>> = [],
) => ({
    type: BHRAction.SET_GUARANTEE_REQUESTS_BHR as const,
    payload: { guaranteeRequests, sourceData },
});

/** Установка значения статуса в фильтре  */
export const actionSetFilterStatusBHR = (status: BatchRequestFilterStatus) => ({
    type: BHRAction.SET_FILTER_STATUS_BHR as const,
    payload: { status },
});

/** Ошибки в файле на пакетную загрузку */
export const actionSetErrorsBHR = (errors: BatchRequestErrors[] = []) => ({
    type: BHRAction.SET_FILE_ERRORS_BHR as const,
    payload: { errors },
});

/** Исключение */
export const actionExceptionBHR = (exception: Exception) => ({
    type: BHRAction.EXCEPTION_BHR as const,
    payload: { exception },
});
