import { RequestCode } from '@ak-front/core/client/types/request';
import { TValidationErrors } from '@ak-front/core/client/types/validation';
import { normalizeDate } from '@ak-front/core/client/utils/date';
import { countFormatter } from '@ak-front/core/client/utils/number';
import { DeepPartial } from '@ak-front/core/types/utils';
import {
    AdditionalDataFnsPurposeCode as PurposeCode,
    AdditionalDataTenderSubtype as TenderSubtype,
    GuaranteeRequestModelStatus as GuaranteeStatus,
    GuaranteeRequestModelType,
    GuaranteeRequestModelType as GuaranteeType,
    GuaranteeRequestTextInfoModelType as TextType,
    IAdditionalDataFns,
    IAdditionalDataRent,
} from '@ak-front/guarantee-request/client/api/fetch-client';
import {
    GuaranteeAdditionalData,
    GuaranteeAdditionalDataTender,
    GuaranteeRequest,
} from '@ak-front/guarantee-request/client/types';
import { fieldsNames as fieldsGR } from '@ak-front/guarantee-request/client/validations';

import { BatchRequestModelStatus, BatchRequestModelStatus as BatchStatus } from '#/src/client/api/fetch-client';
import {
    BatchFillingGuaranteeType,
    BatchRequest,
    BatchRequestErrors,
    BatchRequestFilterStatus,
    BatchRequestsGroupsByStatus,
} from '#/src/client/types';

import { documents, findLastDocumentVersion } from './dictionary';

export class UtilBHR {
    static readonly guaranteeValidationFormFields: string[] = [
        fieldsGR.idDeal,
        fieldsGR.idClient,
        fieldsGR.type.type,
        fieldsGR.type.issueType,
        fieldsGR.factPrincipal.idClient,
        fieldsGR.beneficiary.beneficiaryName,
        fieldsGR.beneficiary.beneficiaryInn,
        fieldsGR.beneficiary.beneficiaryOgrn,
        fieldsGR.beneficiary.beneficiaryKpp,
        fieldsGR.beneficiary.beneficiaryLegalAddress,
        fieldsGR.beneficiary.beneficiaryFnsCode,
        fieldsGR.beneficiary.beneficiaryFtsCode,
        fieldsGR.fee.feePercent,
        fieldsGR.fee.feePeriod,
    ];

    static readonly errorsFileOptions: Record<string, object> = {
        [RequestCode.GuaranteeRequest]: {
            [GuaranteeType.FNS]: {
                headerColumns: [
                    { header: 'Дата выдачи гарантии', key: fieldsGR.conditions.issueDate, width: 20 },
                    { header: 'Дата вступления гарантии в силу', key: fieldsGR.conditions.forceEntryDate, width: 20 },
                    { header: 'Окончание действия гарантии', key: fieldsGR.conditions.endDate, width: 20 },
                    { header: 'Сумма предела обязательств гаранта', key: fieldsGR.sum.sum, width: 30 },
                ],
            },
            [GuaranteeType.Tender_44_FZ]: {
                headerColumns: [
                    { header: 'Название бенефициара', key: fieldsGR.beneficiary.beneficiaryName, width: 50 },
                    { header: 'ИНН бенефициара', key: fieldsGR.beneficiary.beneficiaryInn, width: 20 },
                    { header: 'КПП бенефициара', key: fieldsGR.beneficiary.beneficiaryKpp, width: 20 },
                    { header: 'ОКТМО бенефициара', key: fieldsGR.beneficiary.beneficiaryOktmo, width: 20 },
                    { header: 'Адрес бенефициара', key: fieldsGR.beneficiary.beneficiaryLegalAddress, width: 65 },
                    { header: 'Субъект РФ бенефициара', key: fieldsGR.beneficiary.beneficiarySubjectRfName, width: 25 },
                    { header: 'Телефон бенефициара', key: 'beneficiaryInfo.phone', width: 25 },
                    { header: 'Электронная почта бенефициара', key: 'beneficiaryInfo.email', width: 30 },
                    { header: 'Дата выдачи', key: fieldsGR.conditions.issueDate, width: 20 },
                    { header: 'Дата вступления в силу', key: fieldsGR.conditions.forceEntryDate, width: 20 },
                    { header: 'Дата окончания действия', key: fieldsGR.conditions.endDate, width: 20 },
                    { header: 'Сумма', key: fieldsGR.sum.sum, width: 30 },
                    { header: 'Подсудность', key: fieldsGR.text.jurisdiction, width: 30 },
                    { header: 'Объект закупки', key: fieldsGR.additionalData.tender44.subject44, width: 100 },
                    { header: 'Номер извещения', key: fieldsGR.additionalData.tender44.notificationNumber44, width: 35 },
                    { header: 'Дата извещения', key: fieldsGR.additionalData.tender44.notificationDate44, width: 20 },
                    { header: 'ИКЗ', key: fieldsGR.additionalData.tender44.identificationCode, width: 60 },
                ],
            },
            [GuaranteeType.Rent]: {
                headerColumns: [
                    { header: 'Наименование договора аренды', key: fieldsGR.additionalData.rent.contractDescription, width: 60 },
                    { header: 'Название бенефициара', key: fieldsGR.beneficiary.beneficiaryName, width: 50 },
                    { header: 'ИНН бенефициара', key: fieldsGR.beneficiary.beneficiaryInn, width: 20 },
                    { header: 'КПП бенефициара', key: fieldsGR.beneficiary.beneficiaryKpp, width: 20 },
                    { header: 'Адрес бенефициара', key: fieldsGR.beneficiary.beneficiaryLegalAddress, width: 65 },
                    { header: 'Дата выдачи', key: fieldsGR.conditions.issueDate, width: 20 },
                    { header: 'Дата вступления в силу', key: fieldsGR.conditions.forceEntryDate, width: 20 },
                    { header: 'Дата окончания действия', key: fieldsGR.conditions.endDate, width: 20 },
                    { header: 'Сумма', key: fieldsGR.sum.sum, width: 30 },
                    { header: 'Подсудность', key: fieldsGR.text.jurisdiction, width: 30 },
                    { header: 'Адрес арендуемой площади (необязательно)', key: fieldsGR.additionalData.rent.address, width: 80 },
                ],
            },
        },
    };

    static isInProgress(status?: BatchStatus) {
        return status === BatchStatus.Created || status === BatchRequestModelStatus.InProgress;
    }

    static isGuaranteeTypeFNS(guaranteeType?: BatchFillingGuaranteeType) {
        return guaranteeType === BatchFillingGuaranteeType.FNS;
    }

    static isGuaranteeType44FZ(guaranteeType?: BatchFillingGuaranteeType) {
        return guaranteeType === BatchFillingGuaranteeType.Tender44FZ;
    }

    static isGuaranteeType44FZJointPurchases(guaranteeType?: BatchFillingGuaranteeType) {
        return guaranteeType === BatchFillingGuaranteeType.Tender44FZJointPurchases;
    }

    static mapBatchGuaranteeTypeToGuaranteeType(batchGuaranteeType?: BatchFillingGuaranteeType) {
        switch (batchGuaranteeType) {
        case BatchFillingGuaranteeType.FNS:
            return GuaranteeType.FNS;
        case BatchFillingGuaranteeType.Tender44FZ:
        case BatchFillingGuaranteeType.Tender44FZJointPurchases:
            return GuaranteeRequestModelType.Tender_44_FZ;
        case BatchFillingGuaranteeType.Rent:
            return GuaranteeRequestModelType.Rent;
        default:
            return undefined;
        }
    }

    static createGroupRequestsByStatus(requests: GuaranteeRequest[] = []): BatchRequestsGroupsByStatus {
        const requestGroups: BatchRequestsGroupsByStatus = {
            [BatchRequestFilterStatus.All]: requests,
            [BatchRequestFilterStatus.Draft]: [],
            [BatchRequestFilterStatus.ToBeSigned]: [],
            [BatchRequestFilterStatus.SendToBank]: [],
        };

        requests.forEach((request) => {
            switch (request.status) {
            case GuaranteeStatus.Draft:
            case GuaranteeStatus.InProgress:
                requestGroups[BatchRequestFilterStatus.Draft].push(request);
                break;
            case GuaranteeStatus.ToBeSigned:
                requestGroups[BatchRequestFilterStatus.ToBeSigned].push(request);
                break;
            case GuaranteeStatus.SendingToBank:
            case GuaranteeStatus.Pending:
            case GuaranteeStatus.Issued:
            case GuaranteeStatus.Rejected:
            case GuaranteeStatus.NeedsWork:
                requestGroups[BatchRequestFilterStatus.SendToBank].push(request);
                break;
            default:
                break;
            }
        });

        return requestGroups;
    }

    static getProgressInfo(createdDateTime?: Date, requestsCount = 0, createdRequestsCount = 0) {
        if (!createdDateTime) {
            return { progress: 0, time: 'неизвестно' };
        }

        const timePassed = new Date().getTime() - new Date(createdDateTime).getTime();
        const timeLeft: number = Math.ceil(
            ((requestsCount - createdRequestsCount) * ((timePassed / 1000) / requestsCount)) / 60,
        );

        return {
            progress: (createdRequestsCount / requestsCount) * 100,
            time: countFormatter(timeLeft, ['минута', 'минуты', 'минут']),
        };
    }

    static getRequestData(batchRequest?: BatchRequest) {
        switch ((batchRequest || {}).requestCode) {
        case RequestCode.GuaranteeRequest:
            return batchRequest?.guaranteesCommonData;
        default:
            return null;
        }
    }

    static getGuaranteeRegistryTemplateExample(type?: GuaranteeType): string {
        switch (type) {
        case GuaranteeType.FNS:
            return findLastDocumentVersion(documents.RegisterGuaranteesFederalTaxServiceExample.versions).relativePath;
        case GuaranteeType.Tender_44_FZ:
            return findLastDocumentVersion(documents.RegisterGuarantees44FZExample.versions).relativePath;
        case GuaranteeType.Rent:
            return findLastDocumentVersion(documents.RegisterGuaranteesRentExample.versions).relativePath;
        default:
            return '';
        }
    }

    static parseGuaranteeFNSFile(rows: Array<Array<string | number | Date>>): Array<DeepPartial<GuaranteeRequest>> {
        return rows.map((row) => {
            const [issueDate, date, endDate, sum] = row;

            return {
                issueDate: issueDate ? normalizeDate(issueDate as string) : undefined,
                forceEntryDate: { date: date ? normalizeDate(date as string) : undefined },
                endDate: endDate ? normalizeDate(endDate as string) : undefined,
                sum: sum as number,
                additionalData: {
                    fns: {
                        purposeCode: PurposeCode.FNS_204,
                    },
                },
                guaranteeTextInfo: {
                    type: TextType.Generated,
                },
            };
        });
    }

    static parseGuaranteeTender44FzFile(
        rows: Array<Array<string | number | Date>>,
    ): Array<DeepPartial<GuaranteeRequest>> {
        return rows.map((row) => {
            const [
                beneficiaryName,
                beneficiaryInn,
                beneficiaryKpp,
                beneficiaryOktmo,
                beneficiaryAddress,
                beneficiarySubjectRFName,
                beneficiaryPhone,
                beneficiaryEmail,
                issueDate,
                date,
                endDate,
                sum,
                jurisdiction,
                subject,
                notificationNumber,
                notificationDate,
                purchaseIdentificationCode,
            ] = row;

            return {
                issueDate: issueDate ? normalizeDate(issueDate as string) : undefined,
                forceEntryDate: { date: date ? normalizeDate(date as string) : undefined },
                endDate: endDate ? normalizeDate(endDate as string) : undefined,
                sum: sum as number,
                additionalData: {
                    tender44: {
                        subject: subject as string,
                        purchaseIdentificationCode: purchaseIdentificationCode as string,
                        notificationNumber: notificationNumber as string,
                        notificationDate: notificationDate ? normalizeDate(notificationDate as string) : undefined,
                        subtype: TenderSubtype.Contract,
                        shouldBeSentToEis: true,
                    },
                },
                beneficiaryInfo: {
                    name: beneficiaryName as string,
                    inn: `${beneficiaryInn}`,
                    kpp: `${beneficiaryKpp}`,
                    oktmo: `${beneficiaryOktmo}`,
                    subjectRfName: `${beneficiarySubjectRFName}`,
                    phone: `${beneficiaryPhone}`,
                    email: `${beneficiaryEmail}`,
                    beneficiaryContactInfo: {
                        legalAddress: beneficiaryAddress as string,
                    },
                },
                guaranteeTextInfo: {
                    type: TextType.Generated,
                },
                jurisdiction: jurisdiction as string,
            };
        });
    }

    static parseGuaranteeRentFile(
        rows: Array<Array<string | number | Date>>,
    ): Array<DeepPartial<GuaranteeRequest>> {
        return rows.map((row) => {
            const [
                contractDescription,
                beneficiaryName,
                beneficiaryInn,
                beneficiaryKpp,
                beneficiaryAddress,
                issueDate,
                date,
                endDate,
                sum,
                jurisdiction,
                address,
            ] = row;

            return {
                issueDate: issueDate ? normalizeDate(issueDate as string) : undefined,
                forceEntryDate: { date: date ? normalizeDate(date as string) : undefined },
                endDate: endDate ? normalizeDate(endDate as string) : undefined,
                sum: sum as number,
                additionalData: {
                    rent: {
                        contractDescription: contractDescription as string,
                        address: address as string,
                    },
                },
                beneficiaryInfo: {
                    name: beneficiaryName as string,
                    inn: `${beneficiaryInn}`,
                    kpp: `${beneficiaryKpp}`,
                    beneficiaryContactInfo: {
                        legalAddress: beneficiaryAddress as string,
                    },
                },
                guaranteeTextInfo: {
                    type: TextType.Generated,
                },
                jurisdiction: jurisdiction as string,
            };
        });
    }

    static mergeGuaranteeRequests(...guaranteeRequests: Array<DeepPartial<GuaranteeRequest>>) {
        return guaranteeRequests.reduce((resultGR, currentGR) => {
            let additionalData: GuaranteeAdditionalData = {};

            switch (resultGR.type) {
            case GuaranteeType.FNS:
                additionalData.fns = {
                    ...(resultGR.additionalData?.fns || {}) as IAdditionalDataFns,
                    ...(currentGR.additionalData?.fns || {}) as IAdditionalDataFns,
                };
                break;
            case GuaranteeType.Tender_44_FZ:
                additionalData.tender44 = {
                    ...(resultGR.additionalData?.tender44 || {}) as GuaranteeAdditionalDataTender,
                    ...(currentGR.additionalData?.tender44 || {}) as GuaranteeAdditionalDataTender,
                };
                break;
            case GuaranteeType.Rent:
                additionalData.rent = {
                    ...(resultGR.additionalData?.rent || {}) as IAdditionalDataRent,
                    ...(currentGR.additionalData?.rent || {}) as IAdditionalDataRent,
                };
                break;
            default:
                additionalData = (resultGR.additionalData || {}) as GuaranteeAdditionalData;
                break;
            }

            return { ...resultGR, ...currentGR, additionalData };
        }, guaranteeRequests[0]);
    }

    static getGuaranteeErrors(type: GuaranteeType, errors: BatchRequestErrors[] = []) {
        const fileErrors: BatchRequestErrors[] = errors.map((grErrors) => {
            const guaranteeFileErrors = {};

            (this.errorsFileOptions[RequestCode.GuaranteeRequest][type]?.headerColumns || [])
                .forEach((fieldOptions) => {
                    if (grErrors[fieldOptions.key]) {
                        guaranteeFileErrors[fieldOptions.key] = grErrors[fieldOptions.key];
                    }
                });

            return guaranteeFileErrors;
        });

        const formErrors: TValidationErrors = {};

        if (errors.length) {
            const [grFormErrors] = errors;

            this.guaranteeValidationFormFields.forEach((fieldName) => {
                if (grFormErrors[fieldName]?.length) {
                    [formErrors[fieldName]] = grFormErrors[fieldName];
                }
            });
        }

        return { fileErrors, formErrors };
    }
}
