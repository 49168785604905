import { call, delay, put, takeEvery } from 'redux-saga/effects';

import { TaskAction } from '#/src/client/store/actions/constants/task';
import { TaskActions, TaskActionsType } from '#/src/client/store/actions/creators';

import * as BatchSagas from './request/batch';
import * as UserSagas from './user';

function* sagaTaskSignIn(action: Extract<TaskActionsType, { type: TaskAction.TASK_SIGN_IN_RUN }>) {
    try {
        yield call(
            UserSagas.sagaSignIn,
            action.meta.login,
            action.meta.password,
            action.meta.callbackAuthSuccess,
            action.meta.callbackAuthFail,
        );
        yield put(TaskActions.actionTaskSignInStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskSignInStop());
    }
}

function* sagaTaskSignInCertificate(
    action: Extract<TaskActionsType, { type: TaskAction.TASK_SIGN_IN_CERTIFICATE_RUN }>,
) {
    try {
        yield call(
            UserSagas.sagaSignInCertificate,
            false,
            action.meta.callbackAuthSuccess,
            action.meta.callbackAuthFail,
        );
        yield put(TaskActions.actionTaskSignInCertificateStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskSignInCertificateStop());
    }
}

function* sagaTaskSignUpCertificate() {
    try {
        yield call(UserSagas.sagaSignUpCertificate);
        yield call(UserSagas.sagaSignInCertificate, true);
        yield put(TaskActions.actionTaskSignUpCertificateStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskSignUpCertificateStop());
    }
}

function* sagaTaskChangePassword(action: Extract<TaskActionsType, { type: TaskAction.TASK_CHANGE_PASSWORD_RUN }>) {
    try {
        yield call(
            UserSagas.sagaChangePassword,
            action.meta.currentPassword,
            action.meta.newPassword,
            action.meta.callbackSuccess,
            action.meta.callbackFail,
        );

        yield put(TaskActions.actionTaskChangePasswordStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskChangePasswordStop());
    }
}

function* sagaTaskSignOut() {
    try {
        yield call(UserSagas.sagaSignOut);
        yield put(TaskActions.actionTaskSignOutStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskSignOutStop());
    }
}

function* sagaTaskSendConfirmation(action: Extract<TaskActionsType, { type: TaskAction.TASK_SEND_CONFIRMATION_RUN }>) {
    try {
        yield delay(100);
        yield call(UserSagas.sagaSendConfirmation, action.meta.channel, action.meta.value);

        yield put(TaskActions.actionTaskSendConfirmationStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskSendConfirmationStop());
    }

    if (action.meta.callback) {
        yield call(action.meta.callback);
    }
}

function* sagaTaskCheckConfirmation(
    action: Extract<TaskActionsType, { type: TaskAction.TASK_CHECK_CONFIRMATION_RUN }>,
) {
    let result = false;

    try {
        yield delay(100);
        result = yield call(UserSagas.sagaCheckConfirmation, action.meta.channel, action.meta.code, action.meta.value);

        yield put(TaskActions.actionTaskCheckConfirmationStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskCheckConfirmationStop());
    }

    if (action.meta.callback) {
        yield call(action.meta.callback, result);
    }
}

/*
    Пакетная загрузка
 */

function* sagaTaskGetListBatchRequest() {
    try {
        yield call(BatchSagas.sagaGetListBHR);
        yield put(TaskActions.actionTaskGetListBHRStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskGetListBHRStop());
    }
}

function* sagaTaskGetBatchRequest(action: Extract<TaskActionsType, { type: TaskAction.TASK_GET_BHR_RUN }>) {
    try {
        yield call(BatchSagas.sagaGetBHR, action.meta.id);
        yield put(TaskActions.actionTaskGetBHRStop());

        if (action.meta.withIntervalUpdate) {
            yield call(BatchSagas.sagaGetBatchRequestWithIntervalBHR);
        }
    } catch (ex) {
        yield put(TaskActions.actionTaskGetBHRStop());
    }
}

export function* sagaTaskCreateBatchRequest(
    action: Extract<TaskActionsType, { type: TaskAction.TASK_CREATE_BHR_RUN }>,
) {
    try {
        const valid = yield call(BatchSagas.sagaMaxValidationGuaranteeBHR);

        if (valid) {
            yield call(BatchSagas.sagaCreateBHR, action.meta);
        }

        yield put(TaskActions.actionTaskCreateBHRStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskCreateBHRStop());
    }
}

export function* sagaTaskRemoveBatchRequest(
    action: Extract<TaskActionsType, { type: TaskAction.TASK_REMOVE_BHR_RUN }>,
) {
    try {
        yield call(BatchSagas.sagaRemoveBHR, action.meta);
        yield put(TaskActions.actionTaskRemoveBHRStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskRemoveBHRStop());
    }
}

export function* sagaTaskDownloadErrorsFileBatchRequest() {
    try {
        yield call(BatchSagas.sagaDownloadErrorsFileBHR);

        yield put(TaskActions.actionTaskDownloadErrorsFileBHRStop());
    } catch (e) {
        yield put(TaskActions.actionTaskDownloadErrorsFileBHRStop());
    }
}

function* sagaTaskLoadMoreBatches() {
    try {
        yield call(BatchSagas.sagaLoadMoreBatches);
        yield put(TaskActions.actionTaskLoadMoreBatchesStop());
    } catch (ex) {
        yield put(TaskActions.actionTaskLoadMoreBatchesStop());
    }
}

export default function* root() {
    yield takeEvery(TaskAction.TASK_SIGN_IN_RUN, sagaTaskSignIn);
    yield takeEvery(TaskAction.TASK_SIGN_IN_CERTIFICATE_RUN, sagaTaskSignInCertificate);
    yield takeEvery(TaskAction.TASK_SIGN_UP_CERTIFICATE_RUN, sagaTaskSignUpCertificate);
    yield takeEvery(TaskAction.TASK_CHANGE_PASSWORD_RUN, sagaTaskChangePassword);
    yield takeEvery(TaskAction.TASK_SIGN_OUT_RUN, sagaTaskSignOut);
    yield takeEvery(TaskAction.TASK_SEND_CONFIRMATION_RUN, sagaTaskSendConfirmation);
    yield takeEvery(TaskAction.TASK_CHECK_CONFIRMATION_RUN, sagaTaskCheckConfirmation);
    yield takeEvery(TaskAction.TASK_GET_LIST_BHR_RUN, sagaTaskGetListBatchRequest);
    yield takeEvery(TaskAction.TASK_GET_BHR_RUN, sagaTaskGetBatchRequest);
    yield takeEvery(TaskAction.TASK_DOWNLOAD_ERRORS_BHR_RUN, sagaTaskDownloadErrorsFileBatchRequest);
    yield takeEvery(TaskAction.TASK_CREATE_BHR_RUN, sagaTaskCreateBatchRequest);
    yield takeEvery(TaskAction.TASK_REMOVE_BHR_RUN, sagaTaskRemoveBatchRequest);
    yield takeEvery(TaskAction.TASK_LOAD_MORE_BATCHES_RUN, sagaTaskLoadMoreBatches);
}
