import React from 'react';
import { TypographyWrapper } from '@ak-front/core/client';

import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { Space } from '@alfalab/core-components/space';

import { PageExternal } from '#/src/client/pages/external';
import { SignInFormHelp } from '#/src/client/pages/external/sign/in/form/help';
import { SignInFormTitle } from '#/src/client/pages/external/sign/in/form/title';

import { SignInForm } from './form';

export const SignInPage: React.VFC = () => (
    <PageExternal>
        <Space size={0} direction='vertical' fullWidth={true} wrap={false}>
            <Gap size='6xl' />
            <Grid.Row gutter='0' justify='center'>
                <Grid.Col width={{ desktop: '6', tablet: '6', mobile: '12' }}>
                    <TypographyWrapper align='left'>
                        <SignInFormTitle />
                    </TypographyWrapper>
                    <Gap size='2xl' />
                    <SignInForm />
                    <SignInFormHelp />
                </Grid.Col>
            </Grid.Row>
        </Space>
    </PageExternal>
);

SignInPage.displayName = nameof(SignInPage);
