import React from 'react';

import { Link } from '@alfalab/core-components/link';
import { NBSP } from '@ak-front/core/client/constants/formatters';

import { GuaranteeRequestModelType } from '@ak-front/guarantee-request/client/api/fetch-client';

import { UtilBHR } from '#/src/client/utils/batch';

type BatchFillingFileDescriptionProps = {
    type?: GuaranteeRequestModelType;
};

export const BatchFillingFileDescription: React.FC<BatchFillingFileDescriptionProps> = React.memo(({ type }) => (
    <React.Fragment>
        Пример в
        { NBSP }
        <Link
            view="default"
            rel="noopener"
            href={ UtilBHR.getGuaranteeRegistryTemplateExample(type) }
        >
            шаблоне реестра гарантий
        </Link>
    </React.Fragment>
));

BatchFillingFileDescription.displayName = nameof(BatchFillingFileDescription);
