import update from 'immutability-helper';
import { Reducer } from 'redux';

import Exception from '@ak-front/core/client/exception';
import { UserAction } from '#/src/client/store/actions/constants/user';
import { UserActionsType } from '#/src/client/store/actions/creators';
import { UserData, ChangePasswordModalStep } from '#/src/client/types/user';

export type UserState = {
    readonly code?: string;
    readonly loginUrl?: string;
    readonly externalLinks?: Record<string, string>;
    readonly token?: string;
    readonly identifiers?: string;
    /** Отвечает на отключение ф-ла регистрации и входа по сертификату */
    readonly isSignInUpOff?: boolean;
    readonly data: Readonly<UserData>;
    readonly changePasswordModalStep?: ChangePasswordModalStep;
    readonly openChangePasswordModal?: boolean;
    readonly exception?: Exception;
};

export const defaultUserState: UserState = {
    data: {
        attributes: {},
    },
};
export const userReducer: Reducer<UserState, UserActionsType> = (
    state: UserState = defaultUserState,
    action: UserActionsType,
) => {
    switch (action.type) {
    case UserAction.SET_DATA_U:
        return update(state, {
            code: { $set: action.payload.code },
            data: { $set: action.payload.user },
        });
    case UserAction.SET_CODE_AH:
        return update(state, {
            code: { $set: action.payload.code },
        });
    case UserAction.SET_STEP_CHANGE_PASSWORD_MODAL_AH:
        return update(state, {
            changePasswordModalStep: { $set: action.payload.step },
        });
    case UserAction.OPEN_CHANGE_PASSWORD_MODAL_AH:
        return update(state, {
            openChangePasswordModal: { $set: action.payload.open },
        });
    case UserAction.EXCEPTION_U:
        return update(state, {
            exception: { $set: action.payload.exception },
        });
    default:
        return state;
    }
};
