import React from 'react';
import { Icon } from '@ak-front/core/client/components/icon';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { TypographyWrapper } from '@ak-front/core/client/components/typography';

import { Button } from '@alfalab/core-components/button';
import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';
import { CheckmarkOnCircleMIcon } from '@alfalab/icons-glyph/CheckmarkOnCircleMIcon';

type ChangePasswordSuccessProps = {
    disabled?: boolean;
    onClick?: () => void;
};

export const ChangePasswordSuccess: React.VFC<ChangePasswordSuccessProps> = ({ disabled, onClick }) => (
    <Grid.Row gutter='0' justify='center'>
        <Grid.Col width='auto'>
            <TypographyWrapper align='center'>
                <Icon color='positive' size='4xl' icon={CheckmarkOnCircleMIcon} />
            </TypographyWrapper>
            <LineIntent top='xl'>
                <TypographyWrapper align='center'>
                    <Typography.TitleResponsive tag='h1' view='small' font='system' color='primary'>
                        Пароль успешно изменён
                    </Typography.TitleResponsive>
                </TypographyWrapper>
            </LineIntent>
            <LineIntent top='m' bottom='xl'>
                <TypographyWrapper align='center'>
                    <Typography.Text view='primary-medium' color='primary'>
                        Ваша учётная запись надёжно защищена
                    </Typography.Text>
                </TypographyWrapper>
            </LineIntent>
            <Grid.Row gutter='0' justify='center'>
                <Grid.Col width={{ desktop: '4', tablet: '4', mobile: '12' }}>
                    <Button id='fine' view='secondary' size='s' block={true} loading={disabled} onClick={onClick}>
                        Хорошо
                    </Button>
                </Grid.Col>
            </Grid.Row>
        </Grid.Col>
    </Grid.Row>
);

ChangePasswordSuccess.displayName = nameof(ChangePasswordSuccess);
