import React, { useCallback } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { PrintForm } from '@ak-front/core/client/components';
import { BaseForm } from '@ak-front/core/client/components/forms';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { getSelectedCertificate } from '@ak-front/core/client/store/selectors/certificate';
import { ZIndexModal } from '@ak-front/core/constants';

import { Button } from '@alfalab/core-components/button';
import { Grid } from '@alfalab/core-components/grid';
import { Modal as ModalResponsive } from '@alfalab/core-components/modal';
import Content from 'arui-private/core/content';

import { DEFAULT_ACCESSION_AGREEMENT_NAME } from '#/src/client/constants/user';
import { TaskActions } from '#/src/client/store/actions/creators';
import { UserSelectors } from '#/src/client/store/selectors';
import { getStatusTaskSignUpCertificate } from '#/src/client/store/selectors/task';
import { getAccessionAgreementPrintForm } from '#/src/client/utils/print-form';

type AccessionAgreementModalProps = {
    open?: boolean;
};

type AccessionAgreementModalEvents = {
    onClose?: () => void;
};

export const AccessionAgreementModal = React.memo(({ open, onClose }:
    AccessionAgreementModalProps & AccessionAgreementModalEvents) => {
    const dispatch = useDispatch();

    const { inn, clientType, clientName } = useSelector(
        UserSelectors.getIdentifiersAH,
        (left, right) => left.inn === right.inn
            && left.clientType === right.clientType
            && left.clientName === right.clientName,
    );

    const selectedCertificate = useSelector(getSelectedCertificate);

    const statusTaskSignUpCertificate = useSelector(getStatusTaskSignUpCertificate);

    const content = getAccessionAgreementPrintForm({
        fileName: DEFAULT_ACCESSION_AGREEMENT_NAME,
        clientType,
        clientInn: inn,
        clientName,
        o: selectedCertificate?.subjectAttributes.o,
        ogrn: selectedCertificate?.subjectAttributes.ogrn,
        ogrnIp: selectedCertificate?.subjectAttributes.ogrnIp,
        sn: selectedCertificate?.subjectAttributes.sn,
        g: selectedCertificate?.subjectAttributes.g,
        inn: selectedCertificate?.subjectAttributes.inn,
        snils: selectedCertificate?.subjectAttributes.snils,
    });

    const handleSignUp = useCallback(() => dispatch(TaskActions.actionTaskSignUpCertificateRun()), []);

    return (
        <ModalResponsive
            open={ Boolean(open) }
            fullscreen={ true }
            disableFocusLock={ true }
            zIndex={ ZIndexModal }
            onClose={ onClose }
        >
            <ModalResponsive.Header hasCloser={ true } />
            <ModalResponsive.Content>
                <Content>
                    <BaseForm>
                        <PrintForm id="user-accession-agreement" content={ content } />
                        <Grid.Row>
                            <Grid.Col width={ { desktop: { s: '3', m: '2' }, tablet: '4', mobile: '12' } }>
                                <LineIntent top="2xl">
                                    <Button
                                        id="sign-up-certificate"
                                        view="primary"
                                        size="m"
                                        block={ true }
                                        loading={ statusTaskSignUpCertificate }
                                        onClick={ handleSignUp }
                                    >
                                        Подписать
                                    </Button>
                                </LineIntent>
                            </Grid.Col>
                        </Grid.Row>
                    </BaseForm>
                </Content>
            </ModalResponsive.Content>
        </ModalResponsive>
    );
});

AccessionAgreementModal.displayName = nameof(AccessionAgreementModal);
