import Exception from '@ak-front/core/client/exception';
import { SignInResponseCode } from '#/src/client/api/fetch-client';
import { UserAction } from '#/src/client/store/actions/constants/user';
import { ChangePasswordModalStep, UserData } from '#/src/client/types/user';

export const actionSetDataAH = (code: SignInResponseCode, user: UserData) => ({
    type: UserAction.SET_DATA_U as const,
    payload: { code, user },
});

export const actionSetCodeAH = (code: SignInResponseCode) => ({
    type: UserAction.SET_CODE_AH as const,
    payload: { code },
});

export const actionOpenPasswordChangeModalAH = (open: boolean) => ({
    type: UserAction.OPEN_CHANGE_PASSWORD_MODAL_AH as const,
    payload: { open },
});

export const actionSetStepChangePasswordModalAH = (step: ChangePasswordModalStep) => ({
    type: UserAction.SET_STEP_CHANGE_PASSWORD_MODAL_AH as const,
    payload: { step },
});

export const actionExceptionAH = (exception?: Exception) => ({
    type: UserAction.EXCEPTION_U as const,
    payload: { exception },
});
