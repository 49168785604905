export enum BHRAction {
    SET_LIST_BHR = '@@ak/request/batch/SET_LIST_BHR',
    ADD_TO_LIST_BHR = '@@ak/request/batch/ADD_TO_LIST_BHR',

    SET_BHR = '@@ak/request/batch/SET_BHR',
    SET_GUARANTEE_TYPE_BHR = '@@ak/request/batch/SET_GUARANTEE_TYPE_BHR',

    LOAD_FILE_DATA_BHR = '@@ak/request/batch/LOAD_FILE_DATA_BHR',
    SET_GUARANTEE_REQUESTS_BHR = '@@ak/request/batch/SET_GUARANTEE_REQUESTS_BHR',
    DOWNLOAD_REQUEST_PRINT_FORM_FILE_BHR = '@@ak/request/batch/DOWNLOAD_REQUEST_PRINT_FORM_FILE_BHR',

    SET_FILTER_STATUS_BHR = '@@ak/request/batch/SET_FILTER_STATUS_BHR',

    SET_FILE_ERRORS_BHR = '@@ak/request/batch/SET_FILE_ERRORS_BHR',
    EXCEPTION_BHR = '@@ak/request/batch/EXCEPTION_BHR',
}
