import { ApplicationState } from '#/src/client/store/reducers';
import { ClientType } from '#/src/client/types/client';
import { IDENTIFIER_SIGN_UP_FACTOR } from '#/src/shared/constants/user';

export const getUserAH = (state: ApplicationState) => state.user.data;
export const getFirstNameAH = (state: ApplicationState) => state.user.data.firstName;
export const getLastNameAH = (state: ApplicationState) => state.user.data.lastName;
export const getPatronymicNameAH = (state: ApplicationState) => state.user.data.patronymicName;
export const getUserCodeAH = (state: ApplicationState) => state.user.code;

export const getChangePasswordModalStepAH = (state: ApplicationState) => state.user.changePasswordModalStep;

export const getOpenChangePasswordModalAH = (state: ApplicationState) => state.user.openChangePasswordModal;

export const getExceptionAH = (state: ApplicationState) => state.user.exception;

export const getLoginUrlAH = (state: ApplicationState) => state.user.loginUrl;
export const getTokenAH = (state: ApplicationState) => state.user.token;
export const getIdentifiersAH = (state: ApplicationState) => {
    if (!state.user.identifiers) {
        return {
            inn: '', snils: '', clientName: '',
        };
    }

    const [, encodedInn, encodedSnils, encodedClientType, ...encodedClientName] = state.user.identifiers.split('.');
    const inn = Number(encodedInn) / IDENTIFIER_SIGN_UP_FACTOR;
    const snils = Number(encodedSnils) / IDENTIFIER_SIGN_UP_FACTOR;
    const clientType = Number(encodedClientType) / IDENTIFIER_SIGN_UP_FACTOR;
    const clientName = new TextDecoder()
        .decode(new Uint8Array(encodedClientName.map((n) => Number(n || 0) / IDENTIFIER_SIGN_UP_FACTOR)));

    const innStr = inn ? `${inn}` : '';
    const snilsStr = snils ? `${snils}` : '';

    return {
        inn: innStr, snils: snilsStr, clientType: clientType as ClientType, clientName,
    };
};

export const isSignInUpOffAH = (state: ApplicationState) => state.user.isSignInUpOff || false;
