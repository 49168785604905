import React from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';

import {
    GuaranteeRequestModelFeePeriod as FeePeriod,
    PropertyInfoModelCode as ContractPropertyCode,
} from '@ak-front/guarantee-request/client/api/fetch-client';

import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';

import { feePeriodDictionary } from '@ak-front/guarantee-request/client/utils/dictionary';

import { PrintFormVersion } from '@ak-front/core/client/types/deal-property';
import { FormControl } from '@ak-front/core/client/types';
import { CoreCurrencyUtils } from '@ak-front/core/client/utils';
import { ApplicationState } from '#/src/client/store/reducers';
import { BatchGuaranteeFillingFeeControls } from './batch-filling-fee-controls';

type BatchFillingFeeProps = FormControl & {
    version: PrintFormVersion;
};

export const BatchFillingFeeGR: React.FC<BatchFillingFeeProps> = React.memo((
    { version, disabled, ...rest },
) => {
    const currencyCode = useSelector(GRSelectors.getCurrencyCodeGR);

    const feePeriodOfContractProperty: Record<FeePeriod, boolean | string | undefined> = {
        [FeePeriod.Advance]: useSelector<ApplicationState, boolean | string | undefined>((state) => GRSelectors.isContractPropertyByCodeGR(state, ContractPropertyCode.PeriodTypeAdvance)),
        [FeePeriod.EveryQuarter]: useSelector<ApplicationState, boolean | string | undefined>((state) => GRSelectors.isContractPropertyByCodeGR(state, ContractPropertyCode.PeriodTypeQuarter)),
        [FeePeriod.EveryQuarterAdvance]: useSelector<ApplicationState, boolean | string | undefined>((state) => GRSelectors.isContractPropertyByCodeGR(state, ContractPropertyCode.PeriodTypeQuarterAdvance)),
        [FeePeriod.EveryMonth]: useSelector<ApplicationState, boolean | string | undefined>((state) => GRSelectors.isContractPropertyByCodeGR(state, ContractPropertyCode.PeriodTypeMonth)),
        [FeePeriod.EveryMonthAdvance]: useSelector<ApplicationState, boolean | string | undefined>((state) => GRSelectors.isContractPropertyByCodeGR(state, ContractPropertyCode.PeriodTypeMonthAdvance)),
        [FeePeriod.EndTerm]: useSelector<ApplicationState, boolean | string | undefined>((state) => GRSelectors.isContractPropertyByCodeGR(state, ContractPropertyCode.PeriodTypeEnd)),
        [FeePeriod.OneTime]: useSelector<ApplicationState, boolean | string | undefined>((state) => GRSelectors.isContractPropertyByCodeGR(state, ContractPropertyCode.PeriodTypeOneTime)),
    };

    const feePeriods = (feePeriodDictionary[version] || [])
        .filter(({ value }) => feePeriodOfContractProperty[value]);

    const { waiting } = rest;

    return (
        <Grid.Row>
            <Grid.Col width={ { desktop: '12', tablet: { s: '12', m: '8' }, mobile: '12' } }>
                <BatchGuaranteeFillingFeeControls
                    waiting={ waiting }
                    disabled={ disabled }
                    minSum={ 0 }
                    feePeriods={ feePeriods }
                    currencyCode={ currencyCode as CoreCurrencyUtils.Currency }
                />
            </Grid.Col>
        </Grid.Row>
    );
});

BatchFillingFeeGR.displayName = nameof(BatchFillingFeeGR);
