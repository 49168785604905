import React from 'react';
import { LineIntent } from '@ak-front/core/client/components/line-intent';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Grid } from '@alfalab/core-components/grid';
import { PasswordInput } from '@alfalab/core-components/password-input';
import { Space } from '@alfalab/core-components/space';
import { Typography } from '@alfalab/core-components/typography';

import { ChangePasswordForm, useChangePasswordCurrentForm } from '#/src/client/components/forms/change-password';
import { PageExternal } from '#/src/client/pages/external';

export const ChangePasswordPage: React.VFC = () => {
    const [
        currentPassword,
        errorCurrentPassword,
        loading,
        disabled,
        state,
        openNotification,
        openChipsValidation,
        statuses,
        onChangePassword,
        onChangeNewPassword,
        onFocusNewPassword,
        onBlurNewPassword,
        onChangeConfirmPassword,
        onFocusConfirmPassword,
        onCloseNotification,
        onChangeCurrentPassword,
        onFocusCurrentPassword,
    ] = useChangePasswordCurrentForm();

    return (
        <PageExternal>
            <Space size={0} direction='vertical' fullWidth={true} wrap={false}>
                <LineIntent top='6xl' bottom='6xl'>
                    <Grid.Row gutter='0' justify='center'>
                        <Grid.Col width={{ desktop: '6', tablet: { s: '10', m: '8' }, mobile: '12' }}>
                            <Grid.Row>
                                <Grid.Col width='12'>
                                    <Grid.Row justify='left'>
                                        <Grid.Col width='auto'>
                                            <Typography.TitleResponsive
                                                tag='h1'
                                                view='large'
                                                font='system'
                                                color='primary'
                                            >
                                                Смена пароля
                                            </Typography.TitleResponsive>
                                        </Grid.Col>
                                    </Grid.Row>
                                    <Gap size='xl' />
                                    <PasswordInput
                                        id='current-password'
                                        size='m'
                                        block={true}
                                        label='Текущий пароль'
                                        value={currentPassword}
                                        disabled={disabled}
                                        error={errorCurrentPassword}
                                        onChange={onChangeCurrentPassword}
                                        onFocus={onFocusCurrentPassword}
                                    />
                                    <Gap size='m' />
                                    <ChangePasswordForm
                                        state={state}
                                        openChipsValidation={openChipsValidation}
                                        openNotification={openNotification}
                                        statuses={statuses}
                                        disabled={disabled}
                                        onBlurNewPassword={onBlurNewPassword}
                                        onChangeNewPassword={onChangeNewPassword}
                                        onFocusNewPassword={onFocusNewPassword}
                                        onChangeConfirmPassword={onChangeConfirmPassword}
                                        onFocusConfirmPassword={onFocusConfirmPassword}
                                        onCloseNotification={onCloseNotification}
                                    />
                                    <Gap size='2xl' />
                                    <Button
                                        id='change-password'
                                        view='primary'
                                        size='s'
                                        block={true}
                                        loading={loading}
                                        disabled={disabled}
                                        onClick={onChangePassword}
                                    >
                                        Сохранить
                                    </Button>
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                </LineIntent>
            </Space>
        </PageExternal>
    );
};

ChangePasswordPage.displayName = nameof(ChangePasswordPage);
