import React from 'react';

import { FormControl } from '@ak-front/core/client/types';

import { FormSection } from '@ak-front/core/client/components/forms/form-section';
import { GuaranteeFillingBeneficiary } from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-beneficiary';

export const BatchFillingBeneficiaryGR: React.FC<FormControl> = React.memo(({ waiting, disabled }) => (
    <FormSection title="Бенефициар" waiting={ waiting }>
        <GuaranteeFillingBeneficiary waiting={ waiting } disabled={ disabled } />
    </FormSection>
));

BatchFillingBeneficiaryGR.displayName = nameof(BatchFillingBeneficiaryGR);
