import React from 'react';
import { useSelector } from 'react-redux';
import { FormControl } from '@ak-front/core/client/types';
import { GRSelectors } from '@ak-front/guarantee-request/client';

import { Input } from '@alfalab/core-components/input';

export const CustomersCountField: React.FC<FormControl> = React.memo(({ error }) => {
    const customersList = useSelector(GRSelectors.get44PurchaseCustomersListGR);

    return (
        <Input
            id="guarantee-tender-customers-list-count"
            label="Количество лотов"
            size="m"
            block={ true }
            value={ `${customersList.length}` }
            maxLength={ 5 }
            disabled={ true }
            error={ error }
            dataTestId="guarantee-tender-customers-list-count"
        />
    );
});

CustomersCountField.displayName = nameof(CustomersCountField);
