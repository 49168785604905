import React from 'react';
import { useSelector } from 'react-redux';
import { BankRequestResponseStatus } from '@ak-front/bank-request/client/api/fetch-client';
import { BankForm as BF } from '@ak-front/bank-request/client/components';
import { BRSelectors } from '@ak-front/bank-request/client/store/selectors';
import { FormPlate } from '@ak-front/core/client';
import { RequestCode } from '@ak-front/core/client/types';

import { RequestFeedback } from '#/src/client/components/request/request-feedback';

export const BankForm: React.VFC = () => {
    const status = useSelector(BRSelectors.getStatusBR);
    const requestFeedbackIsVisible = status === BankRequestResponseStatus.ClientSendBank
        || status === BankRequestResponseStatus.BankSigned;

    const requestFeedback = requestFeedbackIsVisible ? (
        <FormPlate>
            <RequestFeedback requestCode={ RequestCode.BankRequest } />
        </FormPlate>
    ) : null;

    return (
        <BF footer={ requestFeedback } />
    );
};

BankForm.displayName = nameof(BankForm);
