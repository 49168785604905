import React from 'react';

import { Typography } from '@alfalab/core-components/typography';

export const SignInFormTitle = React.memo(() => (
    <Typography.TitleResponsive
        tag="h1"
        view="large"
        font="system"
        color="primary"
    >
        Вход в Альфа-Кредит
    </Typography.TitleResponsive>
));

SignInFormTitle.displayName = nameof(SignInFormTitle);
