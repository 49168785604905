import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { validateProblemsSync, ValidationParameterType } from '@ak-front/core/client/validations';
import { MDASH } from '@ak-front/core/constants/all';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Input } from '@alfalab/core-components/input';
import { Typography } from '@alfalab/core-components/typography';

import { SendConfirmationRequestChannel } from '#/src/client/api/fetch-client';
import { PasswordRecoveryChangePassword } from '#/src/client/pages/external/password-recovery/change-password';
import { PasswordRecoveryConfirmation } from '#/src/client/pages/external/password-recovery/confirmation';
import { PasswordRecoveryNavigation } from '#/src/client/pages/external/password-recovery/navigation';
import { TaskActions } from '#/src/client/store/actions/creators';
import { getStatusTaskSendConfirmation } from '#/src/client/store/selectors/task';
import { emailSchema } from '#/src/client/validations/auth';

enum Step {
    SendConfimation = 'SendConfimation',
    CheckConfirmation = 'CheckConfirmation',
    ChangePassword = 'ChangePassword',
}

type PasswordRecoveryEmailProps = {
    onNavigationBack: () => void;
};

export const PasswordRecoveryEmail: React.VFC<PasswordRecoveryEmailProps> = ({ onNavigationBack }) => {
    const dispatch = useDispatch();

    const [step, setStep] = useState<Step>(Step.SendConfimation);
    const [emailError, setEmailError] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const statusTaskSendConfirmation = useSelector(getStatusTaskSendConfirmation);

    const handleChangeEmail = (_: React.ChangeEvent, { value = '' }: { value: string }) => {
        setEmail(value);
        setEmailError('');
    };

    const handleClickContinue = () => {
        const validationParameter: ValidationParameterType<{ email: string }> = {
            obj: { email },
            schema: emailSchema,
        };

        const { valid, problems } = validateProblemsSync(validationParameter);

        const { error = [] } = problems.email || {};

        if (valid) {
            dispatch(
                TaskActions.actionTaskSendConfirmationRun(SendConfirmationRequestChannel.Email, email, () =>
                    setStep(Step.CheckConfirmation),
                ),
            );
        } else {
            setEmailError(error[0]?.message);
        }
    };

    const handleBack = () => {
        switch (step) {
            case Step.SendConfimation:
                onNavigationBack();
                break;
            case Step.CheckConfirmation:
                setStep(Step.SendConfimation);
                break;
        }
    };

    const handleConfirmationSuccess = () => setStep(Step.ChangePassword);

    switch (step) {
        case Step.SendConfimation:
            return (
                <PasswordRecoveryNavigation onBack={handleBack}>
                    <Typography.TitleResponsive tag='h1' view='medium' color='primary' font='system'>
                        Восстановление данных по email
                    </Typography.TitleResponsive>
                    <Gap size='m' />
                    <Typography.Text view='primary-medium' color='primary'>
                        Введите email, который указан в профиле в Альфа-Кредите
                    </Typography.Text>
                    <Gap size='2xl' />
                    <Input
                        value={email}
                        size='m'
                        label='Email'
                        block={true}
                        error={emailError}
                        onChange={handleChangeEmail}
                    />
                    <Gap size='xl' />
                    <Button
                        value={step}
                        view='primary'
                        size='m'
                        block={true}
                        loading={statusTaskSendConfirmation}
                        onClick={handleClickContinue}
                    >
                        Продолжить
                    </Button>
                </PasswordRecoveryNavigation>
            );
        case Step.CheckConfirmation:
            return (
                <PasswordRecoveryNavigation onBack={handleBack}>
                    <PasswordRecoveryConfirmation
                        channel={SendConfirmationRequestChannel.Email}
                        title='Введите код из письма'
                        text={`Отправили код на ${email}. Если письмо не пришло, проверьте папку Спам. Затем вернитесь назад и попробуйте отправить письмо еще раз`}
                        value={email}
                        onSuccess={handleConfirmationSuccess}
                    />
                </PasswordRecoveryNavigation>
            );
        case Step.ChangePassword:
            return (
                <PasswordRecoveryChangePassword
                    text={`Отправили логин на email, а временный пароль в ${MDASH} смс. На следующем шаге пароль нужно изменить на постоянный`}
                />
            );
        default:
            return null;
    }
};

PasswordRecoveryEmail.displayName = nameof(PasswordRecoveryEmail);
