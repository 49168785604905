import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { FormControl } from '@ak-front/core/client/types';
import { useDidMount, useScrollToError } from '@ak-front/core/client/utils/hook';
import { validationShallowEqual } from '@ak-front/core/client/validations';
import { AdditionalDataFnsPurposeCode as PurposeCode } from '@ak-front/guarantee-request/client/api/fetch-client';
import {
    KndField,
    PeriodCodeField,
    PeriodTextField,
    PeriodTypeField,
    PeriodYearField,
    PurposeCodeField,
} from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-additional-data/fns/fields';
import { GRActions } from '@ak-front/guarantee-request/client/store/actions';
import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';
import { isPeriodTypeOther,UtilGR } from '@ak-front/guarantee-request/client/utils/request';
import { fieldsNames } from '@ak-front/guarantee-request/client/validations';

import { Grid } from '@alfalab/core-components/grid';

const autoSelectedPurposeCode = PurposeCode.FNS_204;

export const BatchFillingAdditionalDataFNSGR: React.FC<FormControl> = React.memo(({ waiting, disabled }) => {
    const dispatch = useDispatch();

    const issueType = useSelector(GRSelectors.getIssueTypeGR);
    const purposeCode = useSelector(GRSelectors.getFNSPurposeCodeGR);
    const periodCode = useSelector(GRSelectors.getFNSPeriodCodeGR);

    const validationErrors = useSelector(
        GRSelectors.getValidationErrorsGR,
        validationShallowEqual(fieldsNames.additionalData.fns),
    );
    const ref = useScrollToError<HTMLDivElement>(validationErrors, fieldsNames.additionalData.fns);

    useDidMount(() => {
        dispatch(
            GRActions.actionSetAdditionalDataFNSGR({ 
                ...UtilGR.getInitialDataFNS(issueType, autoSelectedPurposeCode)
            }),
        );
    });

    const isElectronic = UtilGR.isElectronic(issueType);
    const isReimbursement = UtilGR.isKindReimbursement(purposeCode);

    const periodField = isPeriodTypeOther(periodCode)
        ? (
            <PeriodTextField
                disabled={ disabled }
                waiting={ waiting }
                visible={ isElectronic || isReimbursement }
                error={ validationErrors[fieldsNames.additionalData.fns.periodText] }
            />
        ) : (
            <PeriodCodeField
                disabled={ disabled }
                waiting={ waiting }
                visible={ isElectronic || isReimbursement }
            />
        );

    return (
        <Grid.Row>
            <Grid.Col width="12">
                <div ref={ ref }>
                    <Grid.Row>
                        <Grid.Col width="12">
                            <LineIntent top="xl">
                                <Skeleton width="available" visible={ waiting } size="4xl">
                                    <PurposeCodeField
                                        kinds={ [autoSelectedPurposeCode] }
                                        disabled={ disabled }
                                        error={ validationErrors[fieldsNames.additionalData.fns.purposeCode] }
                                    />
                                </Skeleton>
                            </LineIntent>
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col width="12">
                            <KndField
                                disabled={ disabled }
                                waiting={ waiting }
                                visible={ isElectronic }
                                error={ validationErrors[fieldsNames.additionalData.fns.knd] }
                            />
                        </Grid.Col>
                    </Grid.Row>
                    <Grid.Row>
                        <Grid.Col width="12">
                            <Grid.Row>
                                <Grid.Col width={ { desktop: '4', tablet: '4', mobile: '12' } }>
                                    <PeriodTypeField
                                        disabled={ disabled }
                                        waiting={ waiting }
                                        visible={ isElectronic || isReimbursement }
                                    />
                                </Grid.Col>
                                <Grid.Col width={ { desktop: '4', tablet: '4', mobile: '12' } }>
                                    { periodField }
                                </Grid.Col>
                                <Grid.Col width={ { desktop: '4', tablet: '4', mobile: '12' } }>
                                    <PeriodYearField
                                        disabled={ disabled }
                                        waiting={ waiting }
                                        visible={ isElectronic || isReimbursement }
                                    />
                                </Grid.Col>
                            </Grid.Row>
                        </Grid.Col>
                    </Grid.Row>
                </div>
            </Grid.Col>
        </Grid.Row>
    );
});

BatchFillingAdditionalDataFNSGR.displayName = nameof(BatchFillingAdditionalDataFNSGR);
