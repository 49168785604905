// eslint-disable-next-line import/no-extraneous-dependencies
import { conformToMask } from 'text-mask-core';

/**
 * Получить ФИО пользователя
 * @example Борщ Аноним Анонимыч
 */
export const getFullName = (firstName = '', lastName = '', patronymicName = '') => (
    [lastName, firstName, patronymicName].filter(Boolean).join(' ')
);

const defaultPhoneMask = [
    /([7-8])/,
    ' ',
    /([0-6]|[8-9])/,
    /\d/,
    /\d/,
    ' ',
    /\d/,
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
    '-',
    /\d/,
    /\d/,
];

/** Получить маскированный номер телефнона по умолчанию */
export const getDefaultMaskedPhone = (value?: string): string => {
    if (!value) {
        return '';
    }

    const conformedValue = conformToMask(value, defaultPhoneMask, {})?.conformedValue || '';

    return conformedValue[0] === '7' ? `+${conformedValue}` : conformedValue;
};

export const formatConfirmationPhone = (value: string) => value.split(/\+| /).reduce((p, c) => `${p}${c}`, '');
