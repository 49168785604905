import React from 'react';
import { useDispatch } from 'react-redux';

import { AdditionalDataTenderSubtype as TenderSubtype } from '@ak-front/guarantee-request/client/api/fetch-client';

import { FormControl } from '@ak-front/core/client/types';

import { GRActions } from '@ak-front/guarantee-request/client/store/actions';

import { useDidMount } from '@ak-front/core/client/utils/hook';

export const BatchFillingAdditionalDataTender44GR: React.FC<FormControl> = React.memo(() => {
    const dispatch = useDispatch();

    useDidMount(() => {
        dispatch(GRActions.actionSetAdditionalDataTender44GR({
            subtype: TenderSubtype.Contract,
            shouldBeSentToEis: true,
            firstRequestCompleted: false,
        }));
    });

    return null;
});

BatchFillingAdditionalDataTender44GR.displayName = nameof(BatchFillingAdditionalDataTender44GR);
