import React from 'react';
import { useSelector } from 'react-redux';

import { FormControl } from '@ak-front/core/client/types';

import { CoreTasksSelectors } from '@ak-front/core/client/store/selectors';
import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';
import { fieldsNames } from '@ak-front/guarantee-request/client/validations';
import { useScrollToError } from '@ak-front/core/client/utils/hook';

import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { validationShallowEqual } from '@ak-front/core/client/validations';
import { FactPrincipalField } from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-fact-principal/fields';

const validationFields = {
    idClient: fieldsNames.factPrincipal.idClient,
};

export const BatchFillingFactPrincipalGR: React.FC<FormControl> = React.memo(({ disabled, ...rest }) => {
    const contractId = useSelector(GRSelectors.getContractIdGR);
    const factPrincipals = useSelector(GRSelectors.getFactPrincipalsGR);

    const statusTaskGetFactPrincipals = useSelector(CoreTasksSelectors.getStatusTaskGetFactPrincipals);

    const validationErrors = useSelector(GRSelectors.getValidationErrorsGR, validationShallowEqual(validationFields));
    const refFactPrincipal = useScrollToError<HTMLDivElement>(validationErrors, validationFields);

    const waiting = rest.waiting || statusTaskGetFactPrincipals;

    return contractId && (waiting || Boolean(factPrincipals.length)) ? (
        <div ref={ refFactPrincipal }>
            <LineIntent top="xl">
                <Skeleton visible={ waiting } size="4xl">
                    <FactPrincipalField
                        disabled={ disabled }
                        error={ validationErrors[fieldsNames.factPrincipal.idClient] }
                    />
                </Skeleton>
            </LineIntent>
        </div>
    ) : null;
});

BatchFillingFactPrincipalGR.displayName = nameof(BatchFillingFactPrincipalGR);
