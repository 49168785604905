/* eslint-disable react/jsx-no-useless-fragment */
import React from 'react';
import { useSelector } from 'react-redux';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import { CoreCertificateSelectors } from '@ak-front/core/client/store/selectors';

import { Gap } from '@alfalab/core-components/gap';
import { SuperEllipse } from '@alfalab/core-components/icon-view/super-ellipse';
import { SystemMessage } from '@alfalab/core-components/system-message';
import { colorLightBgSecondary } from '@alfalab/core-components/vars';
import { DocumentHorizontalMarkMIcon } from '@alfalab/icons-glyph/DocumentHorizontalMarkMIcon';

export const SignInFormCertificateModalEmptyBoundary: React.FC<React.PropsWithChildren> = React.memo(({ children }) => {
    const [validCertificates, otherCertificates] = useSelector(CoreCertificateSelectors.getCertificateGroups);

    if (validCertificates.length === 0 && otherCertificates.length === 0) {
        return (
            <React.Fragment>
                <Gap size='xl' />
                <SystemMessage>
                    <SystemMessage.Graphic>
                        <SuperEllipse size={64} backgroundColor={colorLightBgSecondary}>
                            <DocumentHorizontalMarkMIcon />
                        </SuperEllipse>
                    </SystemMessage.Graphic>
                    <SystemMessage.Title>Нет доступных сертификатов</SystemMessage.Title>
                    <SystemMessage.Subtitle>
                        Нужно установить сертификат электронной подписи. Если сертификат установлен, а{NBSP}ошибка
                        повторяется, обратитесь в{NBSP}поддержку
                    </SystemMessage.Subtitle>
                </SystemMessage>
            </React.Fragment>
        );
    }

    return <React.Fragment>{children}</React.Fragment>;
});

SignInFormCertificateModalEmptyBoundary.displayName = nameof(SignInFormCertificateModalEmptyBoundary);
