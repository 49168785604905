import { applyMiddleware, compose, createStore, Middleware, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { alfaMetricaMiddleware } from '@ak-front/core/client/store/middlewares';

import { IS_PRODUCTION_BUNDLE } from '#/src/client/constants';
import { ApplicationState, rootReducer } from '#/src/client/store/reducers';
import rootSaga, { getCoreSagaContextObject } from '#/src/client/store/sagas';

export const configureClientStore = async (
    initialState: ApplicationState,
): Promise<Store<ApplicationState>> => {
    const sagaMiddleware = createSagaMiddleware({
        context: getCoreSagaContextObject(),
    });
    const middlewares: Middleware[] = [
        alfaMetricaMiddleware,
        sagaMiddleware,
    ];

    if (!IS_PRODUCTION_BUNDLE) {
        // eslint-disable-next-line import/no-extraneous-dependencies
        const reduxLogger = await import(/* webpackChunkName: "redux-logger" */ 'redux-logger');

        middlewares.push(reduxLogger.createLogger());
    }

    const enhancer = applyMiddleware(...middlewares);
    const store = createStore(rootReducer(), initialState, IS_PRODUCTION_BUNDLE ? enhancer : compose(enhancer));

    sagaMiddleware.run(rootSaga);

    return store;
};

export const configureServerStore = (
    initialState: ApplicationState,
): Store<ApplicationState> => {
    const store = createStore(() => initialState, initialState);

    return store;
};
