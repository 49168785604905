import { ClientType } from '#/src/client/types/client';

type AccessionAgreement = {
    clientType?: ClientType;
    clientInn?: string;
    clientName?: string;
    o?: string;
    ogrn?: string;
    ogrnIp?: string;
    sn?: string;
    g?: string;
    inn?: string;
    snils?: string;
};

type AccessionAgreementPrintForm = AccessionAgreement & {
    fileName: string;
    appToken?: string;
};

const getJurTemplate = (snils: string, inn: string, o: string, sn: string, g: string) => `
    <div>${o} (Клиент)</div>
    <div>ИНН (КИО): ${inn}</div>
    <div>от имени которого выступает ${sn} ${g} (Подписант Клиента)</div>
    <div>СНИЛС: ${snils}</div>

    <p>
        путем подписания настоящего Подтверждения своей усиленной квалифицированной электронной подписью,
        в соответствии со статьей 428 Гражданского кодекса Российской Федерации,
        я подтверждаю АО «АЛЬФА-БАНК» (Банку) присоединение Клиента к Договору об использовании системы «Альфа-Кредит» (далее – Договор),
        а также мое присоединение к системе «Альфа-Кредит» в качестве Подписанта Клиента.
    </p>

    Подтверждаю, что ознакомился(ась) с положениями Договора, выражаю свое согласие с ними и обязуюсь выполнять их условия.
   `;

const getSpTemplate = (snils: string, inn: string, sn: string, g: string) => `
    <div>Я, Индивидуальный предприниматель ${sn} ${g} (Клиент)</div>
    <div>ИНН (КИО): ${inn}</div>
    <div>СНИЛС: ${snils}</div>

    <p>
        путем подписания настоящего Подтверждения своей усиленной квалифицированной электронной подписью,
        в соответствии со статьей 428 Гражданского кодекса Российской Федерации,
        я подтверждаю АО «АЛЬФА-БАНК» (Банку) свое присоединение к Договору об использовании системы «Альфа-Кредит» (далее – Договор) и присоединение к системе «Альфа-Кредит».
    </p>

    Подтверждаю, что до заключения Договора ознакомился(ась) с положениями Договора, выражаю свое согласие с ними и обязуюсь выполнять их условия.
    `;

const getPhysTemplate = (snils: string, inn: string, sn: string, g: string) => `
    <div>Я, ${sn} ${g} (Клиент)</div>
    <div>ИНН (КИО): ${inn}</div>
    <div>СНИЛС: ${snils}</div>

    <p>
        путем подписания настоящего Подтверждения своей усиленной квалифицированной электронной подписью,
        в соответствии со статьей 428 Гражданского кодекса Российской Федерации,
        я подтверждаю АО «АЛЬФА-БАНК» (Банку) свое присоединение в качестве Клиента к Договору об использовании системы «Альфа-Кредит» (далее – Договор) и присоединение к системе «Альфа-Кредит».
    </p>

    Подтверждаю, что до заключения Договора ознакомился(ась) с положениями Договора, выражаю свое согласие с ними и обязуюсь выполнять их условия.
    `;

const getAccessionAgreement = (value: AccessionAgreement) => {
    // Проверяем что сертификат ЮЛ
    if (value.o) {
        return getJurTemplate(value.snils!, value.inn!, value.o!, value.sn!, value.g!);
    }

    // Проверяем что сертификат ИП
    if (value.ogrnIp) {
        return getSpTemplate(value.snils!, value.inn!, value.sn!, value.g!);
    }

    // Считаем что сертификат ФЛ и проверяем что сертификат ФЛ является представителем ЮЛ
    if (value.clientType === ClientType.Jur && !value.ogrn && !value.ogrnIp) {
        return getJurTemplate(value.snils!, value.clientInn!, value.clientName!, value.sn!, value.g!);
    }

    // Считаем что сертификат ФЛ
    return getPhysTemplate(value.snils!, value.inn!, value.sn!, value.g!);
};

export const getAccessionAgreementPrintForm = (value: AccessionAgreementPrintForm) => {
    const { appToken, fileName, ...rest } = value;
    const dataAppTokenAttribute = appToken ? `data-app-token="${appToken}"` : '';

    const accessionAgreement = getAccessionAgreement(rest);

    return `<?xml version="1.0" encoding="UTF-8"?><!DOCTYPE HTML PUBLIC "-//W3C//DTD HTML 4.01//EN" "http://www.w3.org/TR/html4/strict.dtd">
        <html>
            <head>
                <meta http-equiv="Content-Type" content="text/html; charset=UTF-8"/>
                <title>${fileName}</title>
                <style type="text/css">
                    .header {
                        text-align: left;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 30px;
                        line-height: 36px;
                        color: #0B1F35;
                        margin-bottom: 16px;
                    }
                    .sub-header {
                        text-align: left;
                        font-style: normal;
                        font-weight: 700;
                        font-size: 22px;
                        line-height: 26px;
                        color: #0B1F35;
                    }
                    .body {
                        font-style: normal;
                        font-weight: 400;
                        font-size: 18px;
                        line-height: 24px;
                        color: #0B1F35;
                        margin-top: 32px;
                    }
                </style>
            </head>
            <body>
                <div class="header" ${dataAppTokenAttribute}>ПОДТВЕРЖДЕНИЕ О ПРИСОЕДИНЕНИИ</div>
                <div class="sub-header">к Договору об использовании системы «Альфа-Кредит»</div>
                <div class="body">
                    ${accessionAgreement}
                </div>
            </body>
        </html>
    `;
};
