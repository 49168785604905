import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';

import ApplicationMenu from 'arui-private/core/application-menu';
import ApplicationMenuGroup from 'arui-private/core/application-menu-group';
import ApplicationMenuItem from 'arui-private/core/application-menu-item';

import { CoreAlfaMetricaActions } from '@ak-front/core/client';
import { CoreUserSelectors } from '@ak-front/core/client/store/selectors';
import { AlfaMetricsAction, AlfaMetricsCategory } from '@ak-front/core/client/types';

import { useDidMount } from '@ak-front/core/client/utils/hook';
import { removeStartSlash } from '@ak-front/core/client/utils/router';

import { PATH_ROOT_ROUTE } from '@ak-front/core/constants';
import { PATH_REQUEST_DOCUMENT_ROUTE } from '@ak-front/document-request/constants';
import { PATH_REQUEST_GUARANTEE_ROUTE } from '@ak-front/guarantee-request/client/constants';
import { PATH_REQUEST_CREDIT_ROUTE } from '@ak-front/credit-request/constants';
import {
    PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_LIST_ROUTE,
    PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_ROUTE,
} from '@ak-front/tranche-change-request/client/constants';
import { PATH_DEAL_REPORT_ROUTE } from '@ak-front/deal-report/constants';

import { VisibleBoundary } from '@ak-front/core/client/components/visible-boundary';
import { Text } from '@ak-front/core/client/components/text';
import { PATH_INCOMING_GUARANTEES_ROUTE } from '@ak-front/incoming-guarantee/client';

import { PATH_REQUEST_BATCH_ROUTE } from '#/src/shared/constants/routes';
import { AppSidebarSupport } from '#/src/client/components/app-sidebar-support';
import { AppFeedbackModal } from '#/src/client/components/modals';
import Divider from '#/src/client/components/divider';
import { useUserDisallowedRightFlags } from './hooks';
import { PATH_SETTINGS, PATH_REQUEST_BATCH_LIST_ROUTE } from '#/src/client/constants/routes';

import './index.css';

export const AppMenu: React.VFC = React.memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();

    const {
        isDocumentRequestSendBankDisallowed,
        isReportContractsViewDisallowed,
        isCreditHidden,
        isGuaranteeHidden,
        isMainPageHidden,
        isNewAndDraftsPagesHidden,
    } = useUserDisallowedRightFlags();

    const whiteList = useSelector(CoreUserSelectors.getWhiteListU);

    const [support, setSupport] = useState<boolean>(false);
    const [feedback, setFeedback] = useState<boolean>(false);

    const { location: { pathname } } = history;

    useDidMount(() => {
        if (isMainPageHidden) {
            history.push(PATH_SETTINGS);
        }
    });

    const handleClickSupport = useCallback(() => {
        setSupport(true);
    }, [support]);

    const handleClickFeedback = useCallback(() => {
        setFeedback(true);
    }, [feedback]);

    const handleCloseSupport = useCallback(() => {
        setSupport(false);
    }, [support]);

    const handleCloseFeedback = useCallback(() => {
        setFeedback(false);
    }, [feedback]);

    const handleClickItem = useCallback(
        (path: string) => history.push(path),
        [history.push],
    );

    const handleClickIncomingGuarantees = () => {
        dispatch(CoreAlfaMetricaActions.actionSendAlfaMetrica({
            category: AlfaMetricsCategory.IncomingGuarantee,
            action: AlfaMetricsAction.PageJump,
            additionalData: {
                source: 'AppMenu',
                target: PATH_INCOMING_GUARANTEES_ROUTE,
            },
        }));
    };

    return (
        <React.Fragment>
            <ApplicationMenu className="app-menu column">
                <div className="app-menu__items-wrapper-top">
                    <VisibleBoundary visible={ !isMainPageHidden }>
                        <ApplicationMenuItem
                            id="menu-item-head"
                            href={ removeStartSlash(PATH_ROOT_ROUTE) }
                            checked={ pathname === PATH_ROOT_ROUTE }
                        >
                            <Text value="Главная" />
                        </ApplicationMenuItem>
                    </VisibleBoundary>
                    <VisibleBoundary visible={ !isNewAndDraftsPagesHidden }>
                        <ApplicationMenuGroup id="menu-item-new" title="Новое заявление" isExpanded={ true }>
                            <VisibleBoundary visible={ !isCreditHidden }>
                                <ApplicationMenuItem
                                    id="menu-item-credit"
                                    href={ removeStartSlash(PATH_REQUEST_CREDIT_ROUTE) }
                                    checked={ pathname === PATH_REQUEST_CREDIT_ROUTE }
                                >
                                    <Text value="Кредит" />
                                </ApplicationMenuItem>
                            </VisibleBoundary>
                            <VisibleBoundary visible={ !isGuaranteeHidden }>
                                <ApplicationMenuItem
                                    id="menu-item-guarantee"
                                    href={ removeStartSlash(PATH_REQUEST_GUARANTEE_ROUTE) }
                                    checked={ pathname === PATH_REQUEST_GUARANTEE_ROUTE }
                                >
                                    <Text value="Банковская гарантия" />
                                </ApplicationMenuItem>
                            </VisibleBoundary>
                            <VisibleBoundary visible={ !isDocumentRequestSendBankDisallowed }>
                                <ApplicationMenuItem
                                    id="menu-item-document"
                                    href={ removeStartSlash(PATH_REQUEST_DOCUMENT_ROUTE) }
                                    checked={ pathname === PATH_REQUEST_DOCUMENT_ROUTE }
                                >
                                    <Text value="Отправка документов" />
                                </ApplicationMenuItem>
                            </VisibleBoundary>
                            <VisibleBoundary visible={ whiteList?.batchRequest && !isGuaranteeHidden }>
                                <ApplicationMenuItem
                                    id="menu-item-batch"
                                    href={ removeStartSlash(PATH_REQUEST_BATCH_LIST_ROUTE) }
                                    checked={ pathname === PATH_REQUEST_BATCH_LIST_ROUTE || pathname === PATH_REQUEST_BATCH_ROUTE }
                                >
                                    <Text value="Пакетная загрузка" />
                                </ApplicationMenuItem>
                            </VisibleBoundary>
                            <ApplicationMenuItem
                                id="menu-item-tranche-change-guarantee"
                                href={ removeStartSlash(PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_LIST_ROUTE) }
                                checked={ pathname.includes(PATH_REQUEST_TRANCHE_CHANGE_GUARANTEE_ROUTE) }
                            >
                                <Text value="Изменение гарантии" />
                            </ApplicationMenuItem>
                        </ApplicationMenuGroup>
                    </VisibleBoundary>
                    <ApplicationMenuItem
                        id="menu-item-incoming-guarantee"
                        href={ removeStartSlash(PATH_INCOMING_GUARANTEES_ROUTE) }
                        checked={ pathname === PATH_INCOMING_GUARANTEES_ROUTE }
                        onClick={ handleClickIncomingGuarantees }
                    >
                        <Text value="Входящие гарантии" />
                    </ApplicationMenuItem>
                    <VisibleBoundary visible={ !isReportContractsViewDisallowed }>
                        <ApplicationMenuItem
                            id="menu-item-deal"
                            href={ removeStartSlash(PATH_DEAL_REPORT_ROUTE) }
                            checked={ pathname === PATH_DEAL_REPORT_ROUTE }
                        >
                            <Text value="Информация по договорам" />
                        </ApplicationMenuItem>
                    </VisibleBoundary>
                </div>
                <Divider color="white" />
                <div>
                    <ApplicationMenuItem
                        id="menu-item-settings"
                        href={ removeStartSlash(PATH_SETTINGS) }
                        onClick={ () => handleClickItem(`${PATH_SETTINGS}`) }
                        checked={ pathname === PATH_SETTINGS }
                    >
                        <Text value="Настройки" />
                    </ApplicationMenuItem>
                    <ApplicationMenuItem id="menu-item-support" onClick={ handleClickSupport }>
                        <Text value="Помощь по разделу" />
                    </ApplicationMenuItem>
                    <ApplicationMenuItem id="menu-item-feedback" onClick={ handleClickFeedback }>
                        <Text value="Обратная связь" />
                    </ApplicationMenuItem>
                </div>
            </ApplicationMenu>
            <AppSidebarSupport visible={ support } onClose={ handleCloseSupport } />
            <AppFeedbackModal visible={ feedback } onClose={ handleCloseFeedback } />
        </React.Fragment>
    );
});

AppMenu.displayName = nameof(AppMenu);
