import { BankRequestResponseStatus } from '@ak-front/bank-request/client/api/fetch-client';
import { bankRequestStatuses } from '@ak-front/bank-request/client/constants/request';
import { Dictionary, DictionaryRecord, DocumentType } from '@ak-front/core/client/types';
import { RequestCode } from '@ak-front/core/client/types/request';
import { CreditRequestModelStatus } from '@ak-front/credit-request/client/api/fetch-client';
import { creditRequestStatuses } from '@ak-front/credit-request/client/constants/request';
import { DocumentQueryRequestStatus } from '@ak-front/document-query-request/client/api/fetch-client';
import { documentQueryRequestStatuses } from '@ak-front/document-query-request/client/constants/request';
import { DocumentRequestStatus } from '@ak-front/document-request/client/api/fetch-client';
import { documentRequestStatuses } from '@ak-front/document-request/client/constants/request';
import {
    GuaranteeRequestModelIssueType,
    GuaranteeRequestModelStatus,
} from '@ak-front/guarantee-request/client/api/fetch-client';
import { guaranteeRequestStatuses } from '@ak-front/guarantee-request/client/constants/request';
import { guaranteeFillingIssueTypeDictionary } from '@ak-front/guarantee-request/client/utils/dictionary';
import { TrancheChangeRequestModelStatus } from '@ak-front/tranche-change-request/client/api/fetch-client';
import { trancheChangeRequestStatuses } from '@ak-front/tranche-change-request/client/constants/request';

import { colors } from '@alfalab/core-components/status';

import { BatchRequestModelStatus } from '#/src/client/api/fetch-client';
import {
    BatchFillingGuaranteeType,
    BatchRequestFilterStatus,
    RequestStatusAll,
    TRequestStatus,
} from '#/src/client/types';

export const requestStatusAll = {
    ...GuaranteeRequestModelStatus,
    ...TrancheChangeRequestModelStatus,
    ...DocumentQueryRequestStatus,
    ...DocumentRequestStatus,
    ...CreditRequestModelStatus,
    ...BankRequestResponseStatus,
    ...BatchRequestModelStatus,
};

export const getDictionaryRecordByValue = (value: any, dictionary?: Dictionary): DictionaryRecord => {
    const emptyRecord: DictionaryRecord = { text: '' };

    if (!value) {
        return emptyRecord;
    }

    const foundRecord: DictionaryRecord | undefined = (dictionary || []).find((record) => {
        const { value: val }: DictionaryRecord =
            record.type === 'group' ? getDictionaryRecordByValue(value, record.content) : record || {};

        return val === value;
    });

    return foundRecord || emptyRecord;
};

export const batchRequestStatuses: Record<
    BatchRequestModelStatus,
    { description: string; color: (typeof colors)[number] }
> = {
    [BatchRequestModelStatus.Created]: { description: 'Создан', color: 'grey' },
    [BatchRequestModelStatus.ToBeSigned]: { description: 'На подпись', color: 'orange' },
    [BatchRequestModelStatus.InProgress]: { description: 'Загружается', color: 'grey' },
    [BatchRequestModelStatus.SendToBank]: { description: 'Отправлен в банк', color: 'green' },
};

const statusesOfRequestCode: Record<RequestCode, TRequestStatus[]> = {
    [RequestCode.BankRequest]: Object.entries(bankRequestStatuses).map(([key, value]) => ({
        value: requestStatusAll[key],
        text: value.description,
        color: value.color,
    })),
    [RequestCode.CreditRequest]: Object.entries(creditRequestStatuses).map(([key, value]) => ({
        value: requestStatusAll[key],
        text: value.description,
        color: value.color,
    })),
    [RequestCode.GuaranteeRequest]: Object.entries(guaranteeRequestStatuses).map(([key, value]) => ({
        value: requestStatusAll[key],
        text: value.description,
        color: value.color,
    })),
    [RequestCode.DocumentQueryRequest]: Object.entries(documentQueryRequestStatuses).map(([key, value]) => ({
        value: requestStatusAll[key],
        text: value.description,
        color: value.color,
    })),
    [RequestCode.DocumentRequest]: Object.entries(documentRequestStatuses).map(([key, value]) => ({
        value: requestStatusAll[key],
        text: value.description,
        color: value.color,
    })),
    [RequestCode.BatchRequest]: Object.entries(batchRequestStatuses).map(([key, value]) => ({
        value: requestStatusAll[key],
        text: value.description,
        color: value.color,
    })),
    [RequestCode.TrancheChangeRequest]: Object.entries(trancheChangeRequestStatuses).map(([key, value]) => ({
        value: requestStatusAll[key],
        text: value.description,
        color: value.color,
    })),
};

/** @deprecated Удалить */
export const getRequestStatusDictionary = (
    requestCode: RequestCode,
    includeRequestStatusDraft = false,
): TRequestStatus[] => {
    const statuses: TRequestStatus[] = [];

    (statusesOfRequestCode[requestCode] || []).forEach((s) => {
        if (!includeRequestStatusDraft) {
            if (s.value !== requestStatusAll.Draft) {
                statuses.push(s);
            }
        } else {
            statuses.push(s);
        }
    });

    return statuses;
};

/** @deprecated Удалить */
export const getRequestStatuses = (
    requestCode: RequestCode,
    onlyRequestStatusDraft = false,
    includeRequestStatusDraft = false,
): RequestStatusAll[] =>
    onlyRequestStatusDraft
        ? [requestStatusAll.Draft]
        : getRequestStatusDictionary(requestCode, includeRequestStatusDraft).map(({ value }) => value);

        /** @deprecated Удалить */
export const getRequestStatus = (
    requestCode: RequestCode,
    status?: RequestStatusAll,
): Omit<TRequestStatus, 'value'> => {
    const { text = 'Новое', color = 'grey' } =
        getRequestStatusDictionary(requestCode, true).find(({ value }) => value === status) || {};

    return { text, color };
};

export const batchGuaranteeTypeDictionary = [
    { value: BatchFillingGuaranteeType.FNS, text: 'ФНС' },
    { value: BatchFillingGuaranteeType.Tender44FZ, text: '44-ФЗ' },
    { value: BatchFillingGuaranteeType.Rent, text: 'Аренда' },
    { value: BatchFillingGuaranteeType.Tender44FZJointPurchases, text: '44-ФЗ Совместные закупки' },
];

export const batchGuaranteeIssueTypeDictionary = guaranteeFillingIssueTypeDictionary.filter((r) =>
    [GuaranteeRequestModelIssueType.Electronic, GuaranteeRequestModelIssueType.Bank].includes(r.value),
);

export const batchFilterStatusDictionary = [
    { value: BatchRequestFilterStatus.All, text: 'Все' },
    { value: BatchRequestFilterStatus.ToBeSigned, text: 'На подпись' },
    { value: BatchRequestFilterStatus.Draft, text: 'Черновик' },
    { value: BatchRequestFilterStatus.SendToBank, text: 'Отправлен в банк' },
];

export const documents: Record<
    | 'UsageAgreementLegalEntities'
    | 'InstructionsInstallingPersonalCertificate'
    | 'Declaration'
    | 'Confirmation'
    | 'RegisterGuaranteesFederalTaxServiceExample'
    | 'RegisterGuarantees44FZExample'
    | 'RegisterGuaranteesRentExample',
    {
        description: string;
        versions: DocumentType[];
    }
> = {
    UsageAgreementLegalEntities: {
        description: 'Договор об использовании Альфа-Кредит',
        versions: [
            {
                description: 'Действует по 09.08.2024',
                name: 'Договор об использовании системы Альфа-Кредит (действ. по 09.08.2024)',
                ext: 'pdf',
                relativePath: 'docs/Договор об использовании системы Альфа-Кредит (действ. по 09.08.2024).pdf',
            },
            {
                description: 'Действует с 10.08.2024',
                name: 'Договор об использовании системы Альфа-Кредит (действ. с 10.08.2024)',
                ext: 'pdf',
                relativePath: 'docs/Договор об использовании системы Альфа-Кредит (действ. с 10.08.2024).pdf',
                startDate: new Date('2024.08.10')
            },
        ],
    },
    Declaration: {
        description: 'Заявление',
        versions: [
            {
                description: 'Заявление',
                name: 'Заявление (действ. с 14.04.2022)',
                ext: 'docx',
                relativePath: 'docs/Заявление (действ. с 14.04.2022).docx',
            },
        ],
    },
    Confirmation: {
        description: 'Подтверждение',
        versions: [
            {
                description: 'Подтверждение',
                name: 'Подтверждение (действ. с 14.04.2022)',
                ext: 'doc',
                relativePath: 'docs/Подтверждение (действ. с 14.04.2022).doc',
            },
        ],
    },
    InstructionsInstallingPersonalCertificate: {
        description: 'Инструкция по установке личного сертификата',
        versions: [
            {
                description: 'Инструкция по установке личного сертификата',
                name: 'Инструкция по установке личного сертификата',
                ext: 'pdf',
                relativePath: 'docs/help/Инструкция по установке личного сертификата.pdf',
                size: 1874801,
            },
        ],
    },
    RegisterGuaranteesFederalTaxServiceExample: {
        description: 'Реестр гарантий ФНС (пример)',
        versions: [
            {
                description: 'Реестр гарантий ФНС (пример)',
                name: 'Реестр гарантий ФНС (пример)',
                ext: 'xlsx',
                relativePath: 'templates/Реестр гарантий ФНС (пример).xlsx',
            },
        ],
    },
    RegisterGuarantees44FZExample: {
        description: 'Реестр гарантий 44ФЗ (пример)',
        versions: [
            {
                description: 'Реестр гарантий 44ФЗ (пример)',
                name: 'Реестр гарантий 44ФЗ (пример)',
                ext: 'xlsx',
                relativePath: 'templates/Реестр гарантий 44ФЗ (пример).xlsx',
            },
        ],
    },
    RegisterGuaranteesRentExample: {
        description: 'Реестр арендных гарантий (пример)',
        versions: [
            {
                description: 'Реестр арендных гарантий (пример)',
                name: 'Реестр арендных гарантий (пример)',
                ext: 'xlsx',
                relativePath: 'templates/Реестр арендных гарантий (пример).xlsx',
            },
        ],
    },
};

export const findLastDocumentVersion = (versions: DocumentType[]) => {
    const currentDate = new Date();
    const defaultDate = new Date('0001.01.01');
    const filtered = versions.filter((x) => currentDate >= (x?.startDate || defaultDate));
    return filtered[filtered.length - 1];
}
