/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-import-module-exports */
import React from 'react';
import { createRoot } from 'react-dom/client';
import { CoreHistorySagas } from '@ak-front/core/client';
import { rootElementId } from '@ak-front/core/constants';
import { createBrowserHistory } from 'history';

import { IS_BROWSER, IS_PRODUCTION_BUNDLE } from '#/src/client/constants';
import { configureClientStore } from '#/src/client/store';
import { ApplicationState } from '#/src/client/store/reducers';
import { BrowserRoot } from '#/src/shared/components/root';

import './index.css';

if (IS_BROWSER) {
    window.__main = async (state: ApplicationState) => {
        const history = createBrowserHistory({ basename: state.core.app?.contextRoot });

        const store = await configureClientStore(state);

        const renderRoot = () => {
            const container = document.getElementById(rootElementId);
            const root = createRoot(container!);

            CoreHistorySagas.connectHistory(history);

            root.render(<BrowserRoot store={store} history={history} />);
        };

        renderRoot();

        if (!IS_PRODUCTION_BUNDLE && module.hot) {
            module.hot.accept(['#/src/client/routing', '#/src/client/store/reducers'], () => {
                renderRoot();
            });
        }
    };
}
