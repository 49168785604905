import React from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { Typography } from '@alfalab/core-components/typography';

import { SignInForm } from '#/src/client/pages/external/sign/in/form';

type PasswordRecoveryChangePasswordIdentificationProps = {
    text: string;
    onSuccess: (redirectUrl: string, login: string, currentPassword: string) => void;
};

export const PasswordRecoveryChangePasswordIdentification: React.VFC<
    PasswordRecoveryChangePasswordIdentificationProps
> = ({ text, onSuccess }) => (
    <React.Fragment>
        <Typography.TitleResponsive tag='h1' view='medium' font='system' color='primary'>
            Вход в Альфа-Кредит
        </Typography.TitleResponsive>
        <Gap size='m' />
        <Typography.Text view='primary-medium' color='primary'>
            {text}
        </Typography.Text>
        <Gap size='xl' />
        <SignInForm
            singInPasswordInputLabel='Временный пароль'
            signInButtonText='Продолжить'
            includeButtonCertificate={false}
            onSuccess={onSuccess}
        />
    </React.Fragment>
);

PasswordRecoveryChangePasswordIdentification.displayName = nameof(PasswordRecoveryChangePasswordIdentification);
