import {
    mixed, object, date, ObjectSchema, Schema, TestContext, ValidationError,
} from 'yup';

import Exception from '@ak-front/core/client/exception';
import {
    GuaranteeRequestModelType,
    GuaranteeRequestModelIssueType,
    IGuaranteeRequestBeneficiaryBankInfoModel,
    IAdditionalData,
    IAdditionalDataTender,
} from '@ak-front/guarantee-request/client/api/fetch-client';

import * as Types from '@ak-front/guarantee-request/client/types';

import { isPrintFormVersionAsTwo } from '@ak-front/guarantee-request/client/utils/deal-property';
import { UtilGR } from '@ak-front/guarantee-request/client/utils/request';
import { fieldsNames } from '@ak-front/guarantee-request/client/validations';
import { RequestCode } from '@ak-front/core/client/types/request';
import { DeepPartial } from '@ak-front/core/types/utils';
import { getVersionOfContractPropertyCode } from '@ak-front/core/client/utils/deal-property';
import { idDealSchema, idClientSchema } from '@ak-front/guarantee-request/client/validations/request';
import { typeSchema, issueTypeSchema } from '@ak-front/guarantee-request/client/validations/type';
import { factPrincipalInfoSchema } from '@ak-front/guarantee-request/client/validations/fact-principal';
import { feePercentSchema, feePeriodSchema } from '@ak-front/guarantee-request/client/validations/fee';
import { issueDateSchema, forceEntryDateSchema, endDateSchema } from '@ak-front/guarantee-request/client/validations/conditions';
import { principalAddressSchema } from '@ak-front/guarantee-request/client/validations/additional-data/additional-data-tender';
import {
    beneficiaryNameSchema,
    beneficiaryInnSchema,
    beneficiaryKppSchema,
    beneficiaryOgrnSchema,
    beneficiaryFnsSchema,
    beneficiaryBankSwiftCodeSchema,
    beneficiaryBankNameSchema,
} from '@ak-front/guarantee-request/client/validations/beneficiary';
import { UtilBHR } from '#/src/client/utils/batch';

import { BatchFillingGuaranteeType } from '#/src/client/types';

export type ValidationContextBatchGuaranteeRequestType = {
    guaranteeType: BatchFillingGuaranteeType;
    guaranteeRequest: Types.GuaranteeRequest;
    guaranteeRequests: Array<DeepPartial<Types.GuaranteeRequest>>;
};

export type BatchRequestGuaranties = Types.GuaranteeRequest
    & { file: Array<DeepPartial<Types.GuaranteeRequest>> };

function isConditionsDateValid(testContext: TestContext, schema: Schema<any>, value?: Date) {
    const { createError, options: { context } } = testContext;
    const { guaranteeType } = context as ValidationContextBatchGuaranteeRequestType;

    if (UtilBHR.isGuaranteeType44FZJointPurchases(guaranteeType)) {
        try {
            schema.validateSync(value, { context });
        } catch (e) {
            return createError(e as Exception);
        }
    }

    return true;
}

const guaranteeIssueDateSchema = date()
    .nullable()
    .test(
        'issueDateValid',
        '',
        function test(value?: Date | undefined) {
            return isConditionsDateValid(this, issueDateSchema, value);
        },
    );

const guaranteeEndDateSchema = date()
    .nullable()
    .test(
        'endDateValid',
        '',
        function test(value?: Date | undefined) {
            return isConditionsDateValid(this, endDateSchema, value);
        },
    );

const guaranteeForceEntryDateSchema = mixed()
    .nullable()
    .test(
        'forceEntryDateValid',
        '',
        function test(value?: Date | undefined) {
            const { createError, options: { context } } = this;
            const { guaranteeType } = context as ValidationContextBatchGuaranteeRequestType;

            if (UtilBHR.isGuaranteeType44FZJointPurchases(guaranteeType)) {
                try {
                    forceEntryDateSchema.validateSync(value, { context });
                } catch (ex) {
                    const { message, params } = ex as ValidationError;
                    return createError({
                        message,
                        path: fieldsNames.conditions.forceEntryDate,
                        params: { ...params, path: fieldsNames.conditions.forceEntryDate },
                    });
                }
            }

            return true;
        },
    );

const guaranteeTenderCustomersListSchema = mixed().nullable().test(
    'isNotEmpty',
    'По найденному номеру извещения не найдено ни одного лота',
    function test(value) {
        const { options: { context } } = this;
        const { guaranteeType } = context as ValidationContextBatchGuaranteeRequestType;

        return UtilBHR.isGuaranteeType44FZJointPurchases(guaranteeType) ? value?.length > 0 : true;
    },
);

export const additionalDataTender44Schema = object<IAdditionalDataTender>().shape({
    principalAddress: principalAddressSchema,
    customersList: guaranteeTenderCustomersListSchema,
});

export const additionalDataSchema = object<IAdditionalData>()
    .when(
        [
            nameof<Types.GuaranteeRequest>((s) => s.type),
            nameof<Types.GuaranteeRequest>((s) => s.issueType),
        ],
        (
            type: GuaranteeRequestModelType,
            issueType: GuaranteeRequestModelIssueType,
            objectSchema: ObjectSchema,
        ) => {
            if (type) {
                switch (type) {
                case GuaranteeRequestModelType.Tender_44_FZ:
                    return objectSchema.shape({ tender44: additionalDataTender44Schema });
                default:
                    return objectSchema;
                }
            }

            return objectSchema;
        },
    );

export const batchRequestGuaranteeSchema = object<BatchRequestGuaranties>().shape({
    parentDealInfo: object<Types.GuaranteeRequestDealInfo>().shape({ idDeal: idDealSchema }),
    principalInfo: object<Types.PrincipalInfo>().shape({ idClient: idClientSchema }),
    type: typeSchema,
    issueType: issueTypeSchema,
    additionalData: additionalDataSchema,
    factPrincipalInfo: factPrincipalInfoSchema,
    issueDate: guaranteeIssueDateSchema,
    forceEntryDate: guaranteeForceEntryDateSchema,
    endDate: guaranteeEndDateSchema,
    beneficiaryInfo: object<Types.BeneficiaryInfo>().shape({
        name: beneficiaryNameSchema,
        inn: beneficiaryInnSchema,
        ogrn: beneficiaryOgrnSchema,
        kpp: beneficiaryKppSchema,
        beneficiaryFns: beneficiaryFnsSchema,
        beneficiaryBankInfo: object<IGuaranteeRequestBeneficiaryBankInfoModel>().shape({
            swiftCode: beneficiaryBankSwiftCodeSchema,
            bankName: beneficiaryBankNameSchema,
        }),
    }).when(
        [
            nameof<Types.GuaranteeRequest>((s) => s.parentDealInfo),
            nameof<Types.GuaranteeRequest>((s) => s.type),
        ],
        (
            { dealProperties }: Types.GuaranteeRequestDealInfo,
            type: GuaranteeRequestModelType,
            schema: ObjectSchema,
        ) => (
            (isPrintFormVersionAsTwo(getVersionOfContractPropertyCode(dealProperties)) && UtilGR.isTypeFNS(type))
                ? schema
                : object()
        ),
    ),
    feePercent: feePercentSchema,
    feePeriod: feePeriodSchema,
    file: mixed().test(
        'required',
        'Прикрепите реестр',
        function requireFile() {
            const {
                guaranteeRequests = [],
                guaranteeType,
            } = this.options.context as ValidationContextBatchGuaranteeRequestType;

            return UtilBHR.isGuaranteeType44FZJointPurchases(guaranteeType) ? true : Boolean(guaranteeRequests.length);
        },
    ),
}) as Schema<BatchRequestGuaranties>;

export type BatchFileStructureValidationOptions = {
    requestCode: RequestCode;
    guaranteeType?: GuaranteeRequestModelType;
};

export const isFileStructureValid = (
    data: Array<Array<string | number | Date | undefined>>,
    options?: BatchFileStructureValidationOptions,
): boolean => {
    switch (options?.requestCode) {
    case RequestCode.GuaranteeRequest:
        return Boolean(data.length)
                && data[0]?.length
                === UtilBHR.errorsFileOptions[RequestCode.GuaranteeRequest][options.guaranteeType!]?.headerColumns?.length;
    }

    return true;
};

export type BatchFieldsNames = {
    file: string;
};

export const batchFieldsNames: BatchFieldsNames = {
    file: nameof<BatchRequestGuaranties>((s) => s.file),
};
