import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeStartSlash } from '@ak-front/core/client/utils/router';
import { PATH_ROOT_ROUTE } from '@ak-front/core/constants/router';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Notification } from '@alfalab/core-components/notification';
import { Typography } from '@alfalab/core-components/typography';

import { ChangePasswordForm, useChangePasswordForm } from '#/src/client/components/forms/change-password';
import { PasswordRecoveryChangePasswordIdentification } from '#/src/client/pages/external/password-recovery/change-password/identification';
import { PasswordRecoveryChangePasswordSuccess } from '#/src/client/pages/external/password-recovery/change-password/success';
import { TaskActions } from '#/src/client/store/actions/creators';
import { PATH_SIGN_IN_ROUTE } from '#/src/shared/constants/user';

enum Step {
    Identification = 'Identification',
    ChangePassword = 'ChangePassword',
    Success = 'Success',
}

type PasswordRecoveryChangePasswordProps = {
    text: string;
};

export const PasswordRecoveryChangePassword: React.VFC<PasswordRecoveryChangePasswordProps> = ({ text }) => {
    const dispatch = useDispatch();

    const [openNotificationError, setOpenNotificationError] = useState<boolean>(false);
    const [step, setStep] = useState<Step>(Step.Identification);
    const [credentials, setCredentials] = useState<{ login: string; password: string }>({
        login: '',
        password: '',
    });

    const handleSignInSuccess = () => {
        window.location.href = removeStartSlash(PATH_ROOT_ROUTE);
    };

    const handleSignInFail = () => setOpenNotificationError(true);

    const handleCloseNotification = () => {
        window.location.href = removeStartSlash(PATH_SIGN_IN_ROUTE);
    };

    const handleChangePasswordSuccess = () => {
        setStep(Step.Success);
        dispatch(
            TaskActions.actionTaskSignInRun(
                credentials.login,
                credentials.password,
                handleSignInSuccess,
                handleSignInFail,
            ),
        );
    };

    const [
        loading,
        disabled,
        state,
        openNotification,
        openChipsValidation,
        statuses,
        onChangePassword,
        onChangeNewPassword,
        onFocusNewPassword,
        onBlurNewPassword,
        onChangeConfirmPassword,
        onFocusConfirmPassword,
        onCloseNotification,
    ] = useChangePasswordForm({
        currentPassword: credentials.password,
        onSuccess: handleChangePasswordSuccess,
    });

    const handleAuthSuccess = (_: string, login: string, password: string) => {
        setCredentials({ login, password });
        setStep(Step.ChangePassword);
    };

    switch (step) {
        case Step.Identification:
            return <PasswordRecoveryChangePasswordIdentification text={text} onSuccess={handleAuthSuccess} />;
        case Step.ChangePassword:
            return (
                <React.Fragment>
                    <Typography.TitleResponsive tag='h1' view='medium' font='system' color='primary'>
                        Смена пароля
                    </Typography.TitleResponsive>
                    <Gap size='xl' />
                    <ChangePasswordForm
                        state={state}
                        openChipsValidation={openChipsValidation}
                        openNotification={openNotification}
                        statuses={statuses}
                        disabled={disabled}
                        onBlurNewPassword={onBlurNewPassword}
                        onChangeNewPassword={onChangeNewPassword}
                        onFocusNewPassword={onFocusNewPassword}
                        onChangeConfirmPassword={onChangeConfirmPassword}
                        onFocusConfirmPassword={onFocusConfirmPassword}
                        onCloseNotification={onCloseNotification}
                    />
                    <Gap size='xl' />
                    <Button
                        id='change-password'
                        view='primary'
                        size='s'
                        block={true}
                        loading={loading}
                        disabled={disabled}
                        onClick={onChangePassword}
                    >
                        Продолжить
                    </Button>
                </React.Fragment>
            );
        case Step.Success:
            return (
                <React.Fragment>
                    <Gap size='5xl' />
                    <PasswordRecoveryChangePasswordSuccess />
                    <Notification
                        visible={openNotificationError}
                        title='Произошла ошибка перехода'
                        badge='negative'
                        autoCloseDelay={5000}
                        onClose={handleCloseNotification}
                        onCloseTimeout={handleCloseNotification}
                    >
                        Для продолжения работы необходимо авторизоваться с новым паролем
                    </Notification>
                </React.Fragment>
            );
        default:
            return null;
    }
};

PasswordRecoveryChangePassword.displayName = nameof(PasswordRecoveryChangePassword);
