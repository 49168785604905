import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { VisibleBoundary } from '@ak-front/core/client/components/visible-boundary';
import Exception from '@ak-front/core/client/exception';
import { TValidationProblems, ValidationSeverity } from '@ak-front/core/client/types/validation';
import { validateProblemsSync, ValidationParameterType } from '@ak-front/core/client/validations';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { Input } from '@alfalab/core-components/input';
import { PasswordInput } from '@alfalab/core-components/password-input';
import { Space } from '@alfalab/core-components/space';

import { SignInResponseCode as AuthCode } from '#/src/client/api/fetch-client/fetch-client-dto';
import { SignInFormButtonCertificate } from '#/src/client/pages/external/sign/in/form/button-certificate';
import { SignInPlate } from '#/src/client/pages/external/sign/in/form/plate';
import { TaskActions } from '#/src/client/store/actions/creators';
import { TaskSelectors } from '#/src/client/store/selectors';
import { Credentials, Login, Password } from '#/src/client/types/user';
import { credentialsSchema, loginSchema, passwordSchema } from '#/src/client/validations/auth';
import { ENTER_KEY } from '#/src/client/constants';

export type SignInType = 'CREDINTIALS' | 'CERTIFICATE';

type SignInFormState = {
    login: string;
    password: string;
    validationProblems: TValidationProblems;
    code?: AuthCode;
    error?: {
        title: string;
        message: string;
    };
};

const validationFields = {
    login: nameof<Credentials>((s) => s.login),
    password: nameof<Credentials>((s) => s.password),
};

const getErrorMessage = (validationProblem: TValidationProblems[0]) => {
    const [error] = validationProblem?.error || [];

    return error?.message;
};

type SignInFormProps = {
    includeButtonCertificate?: boolean;
    signInButtonText?: string;
    singInPasswordInputLabel?: string;
    onSuccess?: (redirectUrl: string, login: string, currentPassword: string) => void;
    onFail?: (error: Exception, code?: string) => void;
};

export const SignInForm: React.VFC<SignInFormProps> = ({
    includeButtonCertificate = true,
    signInButtonText = 'Войти',
    singInPasswordInputLabel = 'Пароль',
    onSuccess,
    onFail,
}) => {
    const dispatch = useDispatch();

    const [data, setData] = useState<SignInFormState>({
        login: '',
        password: '',
        validationProblems: {},
    });

    const statusTaskSignIn = useSelector(TaskSelectors.getStatusTaskSignIn);
    const statusTaskSignInCertificate = useSelector(TaskSelectors.getStatusTaskSignInCertificate);

    const handleLoginChange = (_: React.ChangeEvent, { value = '' }: { value: string }) => {
        const inputValue = value.trim();

        const validationParameter: ValidationParameterType<Login> = {
            obj: { login: inputValue },
            schema: loginSchema,
        };

        const { valid, problems } = validateProblemsSync(validationParameter);

        if (valid) {
            problems[validationFields.login] = undefined;
        }

        setData((s) => ({
            ...s,
            login: inputValue,
            validationProblems: { ...s.validationProblems, ...problems },
            error: undefined,
        }));
    };

    const handleChangePassword = (_: React.ChangeEvent, { value = '' }: { value: string }) => {
        const validationParameter: ValidationParameterType<Password> = {
            obj: { password: value },
            schema: passwordSchema,
        };

        const { valid, problems } = validateProblemsSync(validationParameter);

        if (valid) {
            problems[validationFields.password] = undefined;
        }

        setData((s) => ({
            ...s,
            password: value,
            validationProblems: { ...s.validationProblems, ...problems },
            error: undefined,
        }));
    };

    const handleSignInSuccess = (returnUrl: string) => {
        if (onSuccess) {
            onSuccess(returnUrl, data.login, data.password);
        } else {
            window.location.href = returnUrl;
        }
    };

    const handleSignInFail = (error: Exception, code?: string) => {
        if (onFail) {
            onFail(error, code);
        } else {
            switch (code) {
                case AuthCode.UNAUTHENTICATED:
                    setData((s) => ({
                        ...s,
                        validationProblems: {
                            [validationFields.login]: {
                                [ValidationSeverity.Error]: [{ message: ' ' }],
                                [ValidationSeverity.Warning]: undefined,
                            },
                            [validationFields.password]: {
                                [ValidationSeverity.Error]: [{ message: error.message }],
                                [ValidationSeverity.Warning]: undefined,
                            },
                        },
                        code: AuthCode[code!],
                    }));
                    break;
                default:
                    setData((s) => ({
                        ...s,
                        error: { title: error.title!, message: error.message },
                        code: AuthCode[code!],
                    }));
            }
        }
    };

    const handleSignInByCertificateFail = (error: Exception, code?: string) => {
        if (onFail) {
            onFail(error, code);
        } else {
            setData((s) => ({
                ...s,
                error: { title: error.title!, message: error.message },
                code: AuthCode[code!],
            }));
        }
    };

    const handleSignIn = () => {
        const { login = '', password = '' } = data;
        const validationParameter: ValidationParameterType<Credentials> = {
            obj: { login, password },
            schema: credentialsSchema,
        };

        const { valid, problems } = validateProblemsSync(validationParameter);

        setData((s) => ({ ...s, validationProblems: problems, error: undefined, code: undefined }));

        if (valid) {
            dispatch(TaskActions.actionTaskSignInRun(login, password, handleSignInSuccess, handleSignInFail));
        }
    };

    const handleKeyDown =  (event: React.KeyboardEvent<Element>) => {
        if (event?.key?.toUpperCase() === ENTER_KEY) {
            handleSignIn();
        }
    }

    const handleSignInByCertificate = () => {
        setData((s) => ({ ...s, validationProblems: {}, error: undefined, code: undefined }));
    };

    return (
        <React.Fragment>
            <Space size='m' direction='vertical' fullWidth={true}>
                <Input
                    dataTestId='login'
                    type='text'
                    size='m'
                    block={true}
                    label='Логин'
                    value={data.login}
                    disabled={statusTaskSignIn || statusTaskSignInCertificate}
                    error={getErrorMessage(data.validationProblems[validationFields.login])}
                    onChange={handleLoginChange}
                    onKeyDown={handleKeyDown}
                />
                <PasswordInput
                    dataTestId='password'
                    size='m'
                    block={true}
                    label={singInPasswordInputLabel}
                    value={data.password}
                    disabled={statusTaskSignIn || statusTaskSignInCertificate}
                    error={getErrorMessage(data.validationProblems[validationFields.password])}
                    onChange={handleChangePassword}
                    onKeyDown={handleKeyDown}
                />
            </Space>
            <Gap size='2xl' />
            <Space size='m' direction='vertical' fullWidth={true}>
                <Button
                    dataTestId='sign-in'
                    view='primary'
                    size='s'
                    block={true}
                    loading={statusTaskSignIn}
                    disabled={statusTaskSignIn || statusTaskSignInCertificate}
                    onClick={handleSignIn}
                >
                    {signInButtonText}
                </Button>
                <VisibleBoundary visible={includeButtonCertificate}>
                    <SignInFormButtonCertificate
                        disabled={statusTaskSignIn}
                        onClick={handleSignInByCertificate}
                        onSuccess={handleSignInSuccess}
                        onFail={handleSignInByCertificateFail}
                    />
                </VisibleBoundary>
            </Space>
            <VisibleBoundary visible={Boolean(data.error)}>
                <SignInPlate
                    view={data.code === AuthCode.BLOCKED ? 'attention' : 'negative'}
                    title={data.error?.title || ''}
                    text={data.error?.message || ''}
                />
            </VisibleBoundary>
            <Gap size='xl' />
        </React.Fragment>
    );
};

SignInForm.displayName = nameof(SignInForm);
