import React from 'react';
import {
    getActionByStatusCode,
    getImageByStatusCode,
    getPageTextByStatusCode,
    getPageTitleByStatusCode,
} from '@ak-front/core/client/components/error';
import { FormError } from '@ak-front/core/client/components/forms';

import Content from 'arui-private/core/content';
import Header from 'arui-private/core/header';
import Page from 'arui-private/core/page';

export const NotFoundPage: React.FC = () => (
    <Page
        className="page-internal"
        hasOffset={ false }
        header={ <Header hasSidebar={ false } /> }
    >
        <Content>
            <FormError
                image={ getImageByStatusCode(404) }
                title={ getPageTitleByStatusCode(404) }
                text={ getPageTextByStatusCode(404) }
                action={ getActionByStatusCode(404) }
            />
        </Content>
    </Page>
);

NotFoundPage.displayName = nameof(NotFoundPage);
