import React from 'react';
import { useSelector } from 'react-redux';

import { Gap } from '@alfalab/core-components/gap';
import { Plate, PlateProps } from '@alfalab/core-components/plate';
import { StatusBadge } from '@alfalab/core-components/status-badge';

import { UserSelectors } from '#/src/client/store/selectors';

type SignInPlateProps = {
    title: string;
    text: string;
    view: Extract<PlateProps['view'], 'negative' | 'attention'>;
};

export const SignInPlate: React.VFC<SignInPlateProps> = ({ view, title, text }) => {
    const isSignInUpOff = useSelector(UserSelectors.isSignInUpOffAH);

    if (isSignInUpOff) {
        return (
            <React.Fragment>
                <Gap size='xl' />
                <Plate
                    view='negative'
                    title='Технические работы'
                    border={false}
                    leftAddons={<StatusBadge view='negative-alert' />}
                >
                    Вход через УКЭП недоступен. Войдите с помощью логина и пароля.
                    <br />
                    Если нужно восстановить пароль, обратитесь в поддержку
                </Plate>
            </React.Fragment>
        );
    }

    switch (view) {
        case 'negative':
            return (
                <React.Fragment>
                    <Gap size='xl' />
                    <Plate view={view} title={title} border={false} leftAddons={<StatusBadge view='negative-alert' />}>
                        {text}
                    </Plate>
                </React.Fragment>
            );
        case 'attention':
            return (
                <React.Fragment>
                    <Gap size='xl' />
                    <Plate view={view} title={title} border={true} leftAddons={<StatusBadge view='attention-alert' />}>
                        {text}
                    </Plate>
                </React.Fragment>
            );
        default:
            return null;
    }
};

SignInPlate.displayName = nameof(SignInPlate);
