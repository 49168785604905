import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { Typography } from '@alfalab/core-components/typography';
import { MailOpenLinesMIcon } from '@alfalab/icons-glyph/MailOpenLinesMIcon';
import { MobilePhoneMIcon } from '@alfalab/icons-glyph/MobilePhoneMIcon';
import { UserRoundedMIcon } from '@alfalab/icons-glyph/UserRoundedMIcon';

import { PasswordRecoveryEmail } from '#/src/client/pages/external/password-recovery/email';
import { PasswordRecoveryNavigation } from '#/src/client/pages/external/password-recovery/navigation';
import { PasswordRecoveryPhone } from '#/src/client/pages/external/password-recovery/phone';
import { PasswordRecoverySelectItem } from '#/src/client/pages/external/password-recovery/select/item';
import { PasswordRecoverySupport } from '#/src/client/pages/external/password-recovery/support';
import { PATH_SIGN_IN_ROUTE } from '#/src/shared/constants/user';

enum Mode {
    Select = 'Select',
    Email = 'Email',
    Phone = 'Phone',
    Support = 'Support',
}

export const PasswordRecoverySelect: React.VFC = () => {
    const history = useHistory();

    const [mode, setMode] = useState<Mode>(Mode.Select);

    const handleClickContinue = (value: string) => setMode(Mode[value]);

    const handleNavigationBackHead = () => history.push(PATH_SIGN_IN_ROUTE);
    const handleNavigationBack = () => setMode(Mode.Select);

    switch (mode) {
        case Mode.Email:
            return <PasswordRecoveryEmail onNavigationBack={handleNavigationBack} />;
        case Mode.Phone:
            return <PasswordRecoveryPhone onNavigationBack={handleNavigationBack} />;
        case Mode.Support:
            return <PasswordRecoverySupport onNavigationBack={handleNavigationBack} />;
        default:
            return (
                <PasswordRecoveryNavigation onBack={handleNavigationBackHead}>
                    <Typography.TitleResponsive tag='h1' view='medium' color='primary' font='system'>
                        Восстановление данных
                    </Typography.TitleResponsive>
                    <Gap size='m' />
                    <Typography.Text view='primary-medium' color='primary'>
                        Выберите один из вариантов:
                    </Typography.Text>
                    <Gap size='2xl' />
                    <GenericWrapper column={true}>
                        <PasswordRecoverySelectItem
                            value={Mode.Email}
                            text='По email'
                            icon={MailOpenLinesMIcon}
                            onClick={handleClickContinue}
                        />
                        <PasswordRecoverySelectItem
                            value={Mode.Phone}
                            text='По телефону'
                            icon={MobilePhoneMIcon}
                            onClick={handleClickContinue}
                        />
                        <PasswordRecoverySelectItem
                            value={Mode.Support}
                            text='Через поддержку'
                            icon={UserRoundedMIcon}
                            onClick={handleClickContinue}
                        />
                    </GenericWrapper>
                </PasswordRecoveryNavigation>
            );
    }
};

PasswordRecoverySelect.displayName = nameof(PasswordRecoverySelect);
