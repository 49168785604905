import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import { FormControl } from '@ak-front/core/client/types';
import { IContractWithClientModel } from '@ak-front/core/client/api/fetch-client';

import { GRSelectors } from '@ak-front/guarantee-request/client/store/selectors';

import { formatDate } from '@ak-front/core/client/utils/date';
import { UtilGR } from '@ak-front/guarantee-request/client/utils/request';

import { FormGroupInfo, FormPlate } from '@ak-front/core/client/components/forms';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { BHRSelectors } from '#/src/client/store/selectors';

export const BatchViewInfo: React.FC<FormControl> = React.memo(({ waiting }) => {
    const contracts = useSelector(GRSelectors.getContractsGR);
    const contractId = useSelector(BHRSelectors.getContractIdGRBHR);

    const type = useSelector(BHRSelectors.getTypeGRBHR);
    const issueType = useSelector(BHRSelectors.getIssueTypeGRBHR);

    const { docTypeName = '', docNumber = '', docDate }: IContractWithClientModel = useMemo(() => (
        contracts.find(({ idDeal }) => idDeal === contractId) || {} as IContractWithClientModel
    ), [waiting, contracts.length]);

    return (
        <FormPlate>
            <Grid.Row>
                <Grid.Col width="auto">
                    <LineIntent>
                        <Skeleton visible={ waiting } width="available">
                            <Typography.Title view="xsmall" color="primary" font="system" tag="h3">
                                Договор и тип гарантии
                            </Typography.Title>
                        </Skeleton>
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row justify="left">
                <Grid.Col width={ { desktop: '4', tablet: { s: '6', m: '4' }, mobile: '12' } }>
                    <LineIntent top="m">
                        <FormGroupInfo
                            waiting={ waiting }
                            title={ docTypeName }
                            text={ `№ ${docNumber} от ${formatDate(docDate)}` }
                            top="m"
                        />
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
            <Grid.Row justify="left">
                <Grid.Col width={ { desktop: '4', tablet: { s: '6', m: '4' }, mobile: '12' } }>
                    <LineIntent top="m">
                        <FormGroupInfo
                            waiting={ waiting }
                            title="Тип гарантии"
                            text={ UtilGR.getTypeTitle(type) }
                            top="m"
                        />
                    </LineIntent>
                </Grid.Col>
                <Grid.Col width={ { desktop: '4', tablet: { s: '6', m: '4' }, mobile: '12' } }>
                    <LineIntent top="m">
                        <FormGroupInfo
                            waiting={ waiting }
                            title="Тип способа выдачи"
                            text={ UtilGR.getIssueTypeTitle(issueType) }
                            top="m"
                        />
                    </LineIntent>
                </Grid.Col>
            </Grid.Row>
        </FormPlate>
    );
});

BatchViewInfo.displayName = nameof(BatchViewInfo);
