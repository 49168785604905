import React from 'react';

import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { Typography } from '@alfalab/core-components/typography';
import { colorLightGraphicPositive, colorLightGraphicQuaternary } from '@alfalab/core-components/vars';
import { CheckmarkMIcon } from '@alfalab/icons-glyph/CheckmarkMIcon';

type PasswordRulesValidationProps = {
    dataTestId?: string;
    statuses: Record<string, { valid?: boolean; message: string }>;
};

export const PasswordRulesValidation: React.VFC<PasswordRulesValidationProps> = ({ statuses, dataTestId }) => {
    const tags = Object.entries(statuses).map(([key, { valid, message }]) => (
        <GenericWrapper key={key} padding={{ top: '3xs', bottom: '3xs' }}>
            <CheckmarkMIcon
                width={20}
                height={20}
                color={valid ? colorLightGraphicPositive : colorLightGraphicQuaternary}
            />
            <Gap size='xs' direction='horizontal' />
            <Typography.Text view='primary-small'>{message}</Typography.Text>
        </GenericWrapper>
    ));

    return (
        <GenericWrapper dataTestId={dataTestId} column={true}>
            <Typography.Text view='primary-small'>Пароль должен содержать:</Typography.Text>
            {tags}
        </GenericWrapper>
    );
};

PasswordRulesValidation.displayName = nameof(PasswordRulesValidation);
