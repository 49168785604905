import React from 'react';

import { Button } from '@alfalab/core-components/button';
import { Gap } from '@alfalab/core-components/gap';
import { GenericWrapper } from '@alfalab/core-components/generic-wrapper';
import { Circle } from '@alfalab/core-components/icon-view/circle';
import { colorLightBgSecondary, colorLightGraphicPrimary } from '@alfalab/core-components/vars';
import { ArrowBackMIcon } from '@alfalab/icons-glyph/ArrowBackMIcon';

type PasswordRecoveryNavigationProps = {
    onBack: () => void;
};

export const PasswordRecoveryNavigation: React.FC<React.PropsWithChildren<PasswordRecoveryNavigationProps>>
= ({ children, onBack }) => (
    <GenericWrapper alignItems='start' grow={true}>
        <Button view='ghost' onClick={onBack}>
            <Circle size={40} backgroundColor={colorLightBgSecondary}>
                <ArrowBackMIcon color={colorLightGraphicPrimary} />
            </Circle>
        </Button>
        <Gap size='xl' direction='horizontal' />
        <GenericWrapper column={true} grow={true}>
            {children}
        </GenericWrapper>
    </GenericWrapper>
);

PasswordRecoveryNavigation.displayName = nameof(PasswordRecoveryNavigation);
