import React, { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Button } from '@alfalab/core-components/button';
import { Grid } from '@alfalab/core-components/grid';
import { Typography } from '@alfalab/core-components/typography';

import { Form, FormPlate } from '@ak-front/core/client/components/forms';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { getFullName, getDefaultMaskedPhone } from '#/src/client/utils/user';
import { UserActions } from '#/src/client/store/actions/creators';

import { ChangePasswordModal } from '#/src/client/components/modals';
import { getUserAH } from '#/src/client/store/selectors/user';
import { UserAttribute } from './user-attribute';

export const SettingsPage: React.VFC = (): JSX.Element => {
    const dispatch = useDispatch();

    const {
        firstName, lastName, patronymicName, attributes,
    } = useSelector(getUserAH);

    const handleChangePassword = useCallback(() => dispatch(UserActions.actionOpenPasswordChangeModalAH(true)), []);

    return (
        <Form title="Настройки">
            <LineIntent top="xl">
                <FormPlate>
                    <Typography.TitleResponsive tag="h1" view="xsmall" font="system" color="primary">
                        { getFullName(firstName, lastName, patronymicName) }
                    </Typography.TitleResponsive>
                    <UserAttribute name="Электронная почта" value={ attributes.email } />
                    <UserAttribute name="Телефон" value={ getDefaultMaskedPhone(attributes.phoneNumber) } />
                    <LineIntent top="4xl">
                        <Grid.Row>
                            <Grid.Col width={ { desktop: { s: '4', m: '3' }, tablet: '4', mobile: '12' } }>
                                <Button size="xs" view="secondary" block={ true } onClick={ handleChangePassword }>
                                    Изменить пароль
                                </Button>
                            </Grid.Col>
                        </Grid.Row>
                    </LineIntent>
                </FormPlate>
            </LineIntent>
            <ChangePasswordModal />
        </Form>
    );
};
