import { ApplicationState } from '#/src/client/store/reducers';

/** Получить список пакетных загрузок */
export const getListBHR = (state: ApplicationState) => state.request.batch.list || [];
/** Получить флаг полного списка */
export const getListEofBHR = (state: ApplicationState) => state.request.batch.eof;

/** Загружен ли пакет с заявлениями */
export const getIsLoadedBHR = (state: ApplicationState, id: string) => state.request.batch.batchRequest.id === id;
/** Получить данные заполненной формы */
export const getFormBHR = (state: ApplicationState) => state.request.batch.batchRequestForm;
/** Получить исходные данные, загруженные из xlsx файла */
export const getSourceDataBHR = (status: ApplicationState) => status.request.batch.batchRequestForm.sourceData;
/** Получить завяления из пакета */
export const getRequestsBHR = (status: ApplicationState) => status.request.batch.batchRequestForm.guaranteeRequests;
/** Получить объект пакетной загрузки */
export const getBHR = (state: ApplicationState) => state.request.batch.batchRequest;
/** Получить тип гарантии для пакетной загрузки */
export const getGuaranteeTypeBHR = (state: ApplicationState) => state.request.batch.batchRequestForm.guaranteeType;
/** Получить код заявления для пакета загрузки */
export const getRequestCodeBHR = (state: ApplicationState) => state.request.batch.batchRequest.requestCode;
/** Идентификатор состояния рамочного договора */
export const getContractStateIdBHR = (state: ApplicationState) => (
    state.request.batch.batchRequest.guaranteesCommonData?.parentDealInfo?.idDealState
);
/** Получить id организации */
export const getOrganizationIdBHR = (state: ApplicationState) => (
    state.request.batch.batchRequest.guaranteesCommonData?.principalInfo?.idClient
);
/** Получить ИНН организации */
export const getOrganizationInnBHR = (state: ApplicationState) => (
    state.request.batch.batchRequest.guaranteesCommonData?.principalInfo?.inn
);
/** Получить номер документа заявления на пакетную загрузку */
export const getDocNumberBHR = (state: ApplicationState) => state.request.batch.batchRequest.number;
/** Получить статус пакета */
export const getStatusBHR = (state: ApplicationState) => state.request.batch.batchRequest.status;
/** Получить количество уже созданных заявлений в пакете */
export const getRequestsCountBHR = (state: ApplicationState) => state.request.batch.batchRequest.requestsCount;
/** Получить идентификатор договора пакетной обработки */
export const getContractIdGRBHR = (state: ApplicationState) => state.request.batch.batchRequest.guaranteesCommonData?.parentDealInfo?.idDeal;
/** Получить тип пакетной обработки */
export const getTypeGRBHR = (state: ApplicationState) => state.request.batch.batchRequest.guaranteesCommonData?.type;
/** Получить тип выдачи гарантий для пакетной обработки */
export const getIssueTypeGRBHR = (state: ApplicationState) => state.request.batch.batchRequest.guaranteesCommonData?.issueType;
/** Получить значение фильтра по статусу для пакетной обработки */
export const getFilterStatusBHR = (state: ApplicationState) => state.request.batch.filter.status;

export const getErrorsBHR = (state: ApplicationState) => state.request.batch.errors;
export const getExceptionBHR = (state: ApplicationState) => state.request.batch.exception;
