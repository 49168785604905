import React, { useEffect, useRef } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import {
    getActionByStatusCode,
    getImageByStatusCode,
    getPageTextByStatusCode,
    getPageTitleByStatusCode,
} from '@ak-front/core/client/components/error';
import { Form, FormError } from '@ak-front/core/client/components/forms';
import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { TypographyWrapper } from '@ak-front/core/client/components/typography';
import { VisibleBoundary } from '@ak-front/core/client/components/visible-boundary';

import { Button } from '@alfalab/core-components/button';
import { Grid } from '@alfalab/core-components/grid';
import { Spinner } from '@alfalab/core-components/spinner';
import { Table } from '@alfalab/core-components/table';
import ContainerMIcon from '@alfalab/icons-glyph/ContainerMIcon';
import { EmptyState } from 'arui-private/core/empty-state';

import { TaskActions } from '#/src/client/store/actions/creators';
import { BHRSelectors, TaskSelectors } from '#/src/client/store/selectors';
import { PATH_REQUEST_BATCH_ROUTE } from '#/src/shared/constants/routes';

import { BatchListItem } from './batch-list-item';

const headers: Array<{ title: string; align?: 'left' | 'right' }> = [
    { title: 'Пакет файлов' },
    { title: 'Дата создания' },
    { title: 'Организация' },
    { title: 'Тип гарантий' },
    { title: 'Статус', align: 'right' },
];

export const BatchList: React.FC = React.memo(() => {
    const dispatch = useDispatch();
    const history = useHistory();

    const statusTaskGetList = useSelector(TaskSelectors.getStatusTaskListBHR);
    const statusTaskLoadMoreBatches = useSelector(TaskSelectors.getStatusTaskListMoreBHR);

    const eof = useSelector(BHRSelectors.getListEofBHR);
    const list = useSelector(BHRSelectors.getListBHR, (left, right) => left.length === right.length);
    const exception = useSelector(BHRSelectors.getExceptionBHR, shallowEqual);

    const ref = useRef<HTMLDivElement>(null);

    let lastScrollTop = 0;

    const handleClickCreate = () => {
        history.push(PATH_REQUEST_BATCH_ROUTE);
    };

    const handleClickItem = (id: string) => {
        history.push(`${PATH_REQUEST_BATCH_ROUTE}/${id}`);
    };

    const isLoadMore = () => {
        if (!ref.current || !document) {
            return false;
        }

        const doc = document.documentElement;

        return (doc.clientHeight + window.pageYOffset <= doc.scrollHeight)
            && (doc.clientHeight + window.pageYOffset >= doc.scrollHeight - 70);
    };

    const handleScroll = (event) => {
        const scrollTop = window.pageYOffset || event.currentTarget.scrollTop;

        if (!statusTaskGetList
            && !statusTaskLoadMoreBatches
            && !eof
            && scrollTop > lastScrollTop
            && isLoadMore()
        ) {
            dispatch(TaskActions.actionTaskLoadMoreBatchesRun());
        }

        lastScrollTop = scrollTop;
    };

    useEffect(() => {
        document?.addEventListener('scroll', handleScroll);

        return () => document?.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    if (exception) {
        const { statusCode, requestId } = exception;

        return (
            <FormError
                requestId={ requestId }
                image={ getImageByStatusCode(statusCode) }
                title={ getPageTitleByStatusCode(statusCode) }
                text={ getPageTextByStatusCode(statusCode) }
                action={ getActionByStatusCode(statusCode) }
            />
        );
    }

    return (
        <Form title="Пакетная загрузка" waiting={ statusTaskGetList }>
            <LineIntent top="xl">
                <VisibleBoundary visible={ !statusTaskGetList && !list.length }>
                    <EmptyState
                        icon={ <ContainerMIcon /> }
                        header="У вас пока нет пакетных загрузок"
                        description="Здесь появятся созданные заявления"
                        buttonText="Создать пакет заявлений"
                        onButtonClick={ handleClickCreate }
                        data-test-id="bhr-empty-list-plate"
                    />
                </VisibleBoundary>
                <VisibleBoundary visible={ statusTaskGetList || Boolean(list.length) }>
                    <Grid.Row>
                        <Grid.Col width="auto">
                            <LineIntent top="xs">
                                <Skeleton visible={ statusTaskGetList } size="4xl">
                                    <Button
                                        id="bhr-create-package"
                                        view="primary"
                                        size="s"
                                        onClick={ handleClickCreate }
                                    >
                                        Создать пакет заявлений
                                    </Button>
                                </Skeleton>
                            </LineIntent>
                        </Grid.Col>
                    </Grid.Row>
                    <LineIntent top="xl">
                        <Skeleton visible={ statusTaskGetList } size="8xl">
                            <div ref={ ref } className="layout-list_padding_m">
                                <Table>
                                    <Table.THead>
                                        { headers.map(({ title, align }) => (
                                            <Table.THeadCell title={ title } textAlign={ align }>
                                                { title }
                                            </Table.THeadCell>
                                        )) }
                                    </Table.THead>
                                    <Table.TBody>
                                        { list.map((item) => (
                                            <BatchListItem item={ item } onGoToItem={ handleClickItem } />
                                        )) }
                                    </Table.TBody>
                                </Table>
                                <VisibleBoundary visible={ statusTaskLoadMoreBatches }>
                                    <LineIntent top="s" bottom="s">
                                        <TypographyWrapper align="center">
                                            <Spinner visible={ true } size="s" />
                                        </TypographyWrapper>
                                    </LineIntent>
                                </VisibleBoundary>
                            </div>
                        </Skeleton>
                    </LineIntent>
                </VisibleBoundary>
            </LineIntent>
        </Form>
    );
});

BatchList.displayName = nameof(BatchList);
