import { AllEffect, call, CallEffect, put, PutEffect, select } from 'redux-saga/effects';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import Exception, { ExceptionInitiator } from '@ak-front/core/client/exception';
import {
    CoreAppActions,
    CoreNotificationManagerActions,
    CoreUserActions,
} from '@ak-front/core/client/store/actions/creators';
import { actionSendAlfaMetrica } from '@ak-front/core/client/store/actions/creators/alfa-metrica';
import { sagaSignData } from '@ak-front/core/client/store/sagas/certificate';
import { CoreCertificateSelectors } from '@ak-front/core/client/store/selectors';
import { AlfaMetricsAction, AlfaMetricsCategory } from '@ak-front/core/client/types/alfa-metrics';
import { RoleCode } from '@ak-front/core/client/types/app';
import { Certificate } from '@ak-front/core/client/types/certificate';
import { formatDate } from '@ak-front/core/client/utils/date';
import { base64ToBlob } from '@ak-front/core/client/utils/file';
import { PATH_ROOT_ROUTE } from '@ak-front/core/constants/router';

import * as Api from '#/src/client/api/fetch-client';
import { CheckConfirmationRequestChannel, SendConfirmationRequestChannel } from '#/src/client/api/fetch-client';
import {
    changePassword,
    checkConfirmation,
    sendConfirmation,
    signIn,
    signInCertificate,
    signOut,
    signUpCertificate,
} from '#/src/client/api/user-api';
import { PATH_CHANGE_PASSWORD_ROUTE } from '#/src/client/constants/routes';
import { DEFAULT_ACCESSION_AGREEMENT_NAME } from '#/src/client/constants/user';
import { UserActions } from '#/src/client/store/actions/creators';
import { UserSelectors } from '#/src/client/store/selectors';
import { ClientType } from '#/src/client/types/client';
import { UserData } from '#/src/client/types/user';
import { getAccessionAgreementPrintForm } from '#/src/client/utils/print-form';
import { getDefaultMaskedPhone } from '#/src/client/utils/user';

export function* sagaSignIn(
    login: string,
    password: string,
    callbackAuthSuccess?: (redirectUrl: string) => void,
    callbackAuthFail?: (error: Exception, code?: string) => void,
) {
    try {
        const signInCommand: Api.ISignInRequest = { login, password };

        const signInResponse: Api.ISignInResponse = yield call(signIn, signInCommand);

        const userData: UserData = {
            lastName: signInResponse.user.lastName,
            firstName: signInResponse.user.firstName,
            patronymicName: signInResponse.user.patronymicName,
            attributes: signInResponse.user.attributes,
        };

        yield put(CoreUserActions.actionSetRoleU(RoleCode[signInResponse.user.role]));
        yield put(UserActions.actionSetDataAH(signInResponse.code, userData));

        switch (signInResponse.code) {
            case Api.SignInResponseCode.AUTHENTICATED:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignIn,
                        action: AlfaMetricsAction.Authenticated,
                    }),
                );
                callbackAuthSuccess?.(signInResponse.nextUrl || PATH_ROOT_ROUTE);
                break;

            case Api.SignInResponseCode.TEMP:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignIn,
                        action: AlfaMetricsAction.Temp,
                    }),
                );
                callbackAuthSuccess?.(PATH_CHANGE_PASSWORD_ROUTE);
                break;
            case Api.SignInResponseCode.EXPIRED:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignIn,
                        action: AlfaMetricsAction.Expired,
                    }),
                );
                callbackAuthSuccess?.(PATH_CHANGE_PASSWORD_ROUTE);
                break;

            case Api.SignInResponseCode.UNAUTHENTICATED:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignIn,
                        action: AlfaMetricsAction.Unauthenticated,
                    }),
                );
                callbackAuthFail?.(
                    new Exception({
                        title: 'Неверный логин или пароль',
                        message: 'Логин или пароль не подходят. Проверьте, верно ли все написали',
                        initiator: ExceptionInitiator.Client,
                    }),
                    signInResponse.code,
                );
                break;

            case Api.SignInResponseCode.NOTACTIVE:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignIn,
                        action: AlfaMetricsAction.NoActive,
                    }),
                );
                callbackAuthFail?.(
                    new Exception({
                        title: 'Вход в Альфа-Кредит заблокирован',
                        message: 'Ваша учётная запись заблокирована, обратитесь в службу поддержки.',
                        initiator: ExceptionInitiator.Client,
                    }),
                    signInResponse.code,
                );
                break;

            case Api.SignInResponseCode.BLOCKED:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignIn,
                        action: AlfaMetricsAction.Blocked,
                    }),
                );
                callbackAuthFail?.(
                    new Exception({
                        title: 'Вход в Альфа-Кредит заблокирован',
                        message:
                            'Превышено количество неудачных попыток входа в систему. Ваша учётная запись заблокирована на 30 минут.',
                        initiator: ExceptionInitiator.Client,
                    }),
                    signInResponse.code,
                );
                break;

            case Api.SignInResponseCode.OUTDATED:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignIn,
                        action: AlfaMetricsAction.OutDated,
                    }),
                );
                callbackAuthFail?.(
                    new Exception({
                        title: 'У вас недостаточно прав для входа',
                        message: 'Обратитесь в службу поддержки.',
                        initiator: ExceptionInitiator.Client,
                    }),
                    signInResponse.code,
                );
                break;

            default:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignIn,
                        action: AlfaMetricsAction.Failed,
                    }),
                );
                callbackAuthFail?.(
                    new Exception({
                        title: 'Возникла техническая проблема',
                        message: 'Сервис временно недоступен. Попробуйте зайти позднее или обратитесь в поддержку.',
                        initiator: ExceptionInitiator.Server,
                    }),
                );
                break;
        }
    } catch (ex) {
        yield put(
            actionSendAlfaMetrica({
                category: AlfaMetricsCategory.SignIn,
                action: AlfaMetricsAction.Failed,
            }),
        );
        callbackAuthFail?.(ex as Exception);
    }
}

export function* sagaSignInCertificate(
    isFirstSignIn: boolean,
    callbackAuthSuccess?: (redirectUrl: string) => void,
    callbackAuthFail?: (error: Exception, code?: string) => void,
) {
    try {
        const appToken: string = yield select(UserSelectors.getTokenAH);

        const data = new Blob([appToken], { type: 'text/plain' });

        const signature: string = yield call(sagaSignData, data);

        const { code, user, nextUrl }: Api.ISignInCertificateResponse = yield call(
            signInCertificate,
            { data, fileName: 'app-token.txt' },
            { data: base64ToBlob(signature), fileName: 'app-token.sig' },
        );

        const userData: UserData = {
            lastName: user.lastName,
            firstName: user.firstName,
            patronymicName: user.patronymicName,
            attributes: user.attributes,
        };

        yield put(CoreUserActions.actionSetRoleU(RoleCode[user.role]));
        yield put(UserActions.actionSetDataAH(Api.SignInResponseCode[code], userData));

        switch (code) {
            case Api.SignInCertificateResponseCode.AUTHENTICATED:
                if (isFirstSignIn) {
                    const phone = getDefaultMaskedPhone(userData.attributes.phoneNumber);

                    yield put(
                        CoreAppActions.actionAddSystemNotificationA({
                            title: 'Вы зарегистрированы',
                            text: [
                                `Пароль к${NBSP}системе Альфа-Кредит отправлен на${NBSP}номер телефона${NBSP}${phone}`,
                                `Логин отправлен на${NBSP}почту${NBSP}${userData.attributes.email}.`,
                            ],
                            closable: true,
                        }),
                    );
                }
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignInCertificate,
                        action: AlfaMetricsAction.Authenticated,
                    }),
                );
                callbackAuthSuccess?.(nextUrl || PATH_ROOT_ROUTE);
                break;

            case Api.SignInCertificateResponseCode.UNAUTHENTICATED: {
                const selectedCertificate: Certificate = yield select(CoreCertificateSelectors.getSelectedCertificate);

                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignInCertificate,
                        action: AlfaMetricsAction.Unregistered,
                    }),
                );
                callbackAuthFail?.(
                    new Exception({
                        title: 'Вход по сертификату недоступен',
                        message: `Сертификат ${selectedCertificate?.subjectAttributes?.cn} (выдан ${
                            selectedCertificate?.issuerAttributes?.cn
                        }, действует до ${formatDate(
                            selectedCertificate?.validToDate,
                            'D MMMM YYYY',
                        )}) не подходит для входа по УКЭП. Войдите с помощью пароля или обратитесь в службу поддержки.`,
                        initiator: ExceptionInitiator.Client,
                    }),
                    code,
                );
                break;
            }

            case Api.SignInCertificateResponseCode.NOTACTIVE:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignInCertificate,
                        action: AlfaMetricsAction.NoActive,
                    }),
                );
                callbackAuthFail?.(
                    new Exception({
                        title: 'Вход в Альфа-Кредит заблокирован',
                        message: 'Ваша учётная запись заблокирована, обратитесь в службу поддержки.',
                        initiator: ExceptionInitiator.Client,
                    }),
                    code,
                );
                break;

            default:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.SignInCertificate,
                        action: AlfaMetricsAction.Failed,
                    }),
                );
                callbackAuthFail?.(
                    new Exception({
                        title: 'Возникла техническая проблема',
                        message: 'Сервис временно недоступен. Попробуйте зайти позднее или обратитесь в поддержку.',
                        initiator: ExceptionInitiator.Server,
                    }),
                );
                break;
        }
    } catch (ex) {
        yield put(
            actionSendAlfaMetrica({
                category: AlfaMetricsCategory.SignInCertificate,
                action: AlfaMetricsAction.Failed,
            }),
        );
        callbackAuthFail?.(ex as Exception);
    }
}

export function* sagaSignUpCertificate() {
    const selectedCertificate: Certificate = yield select(CoreCertificateSelectors.getSelectedCertificate);

    try {
        const appToken: string = yield select(UserSelectors.getTokenAH);
        const identifiers: {
            inn: string;
            clientType: ClientType;
            clientName: string;
        } = yield select(UserSelectors.getIdentifiersAH);

        const accessionAgreementPrintForm = getAccessionAgreementPrintForm({
            fileName: DEFAULT_ACCESSION_AGREEMENT_NAME,
            clientType: identifiers.clientType,
            clientInn: identifiers.inn,
            clientName: identifiers.clientName,
            o: selectedCertificate?.subjectAttributes.o,
            ogrn: selectedCertificate?.subjectAttributes.ogrn,
            ogrnIp: selectedCertificate?.subjectAttributes.ogrnIp,
            sn: selectedCertificate?.subjectAttributes.sn,
            g: selectedCertificate?.subjectAttributes.g,
            inn: selectedCertificate?.subjectAttributes.inn,
            snils: selectedCertificate?.subjectAttributes.snils,
            appToken,
        });

        const data = new Blob([accessionAgreementPrintForm], { type: 'text/html' });

        const signature: string = yield call(sagaSignData, data);

        yield call(
            signUpCertificate,
            { data, fileName: `${DEFAULT_ACCESSION_AGREEMENT_NAME}.html` },
            { data: base64ToBlob(signature), fileName: `${DEFAULT_ACCESSION_AGREEMENT_NAME}.sig` },
        );

        yield put(
            actionSendAlfaMetrica({
                category: AlfaMetricsCategory.SignUpCertificate,
                action: AlfaMetricsAction.Registered,
            }),
        );
    } catch (ex) {
        yield put(
            actionSendAlfaMetrica({
                category: AlfaMetricsCategory.SignUpCertificate,
                action: AlfaMetricsAction.Failed,
            }),
        );

        const e = new Exception({
            ...(ex as Exception),
            title: 'Регистрация по сертификату недоступна',
            message: `Сертификат ${selectedCertificate?.subjectAttributes?.cn} (выдан ${
                selectedCertificate?.issuerAttributes?.cn
            }, действует до ${formatDate(
                selectedCertificate?.validToDate,
                'D MMMM YYYY',
            )}) не подходит для регистрации по УКЭП. Попробуйте выбрать другой сертификат или обратитесь в${NBSP}поддержку`,
        });

        yield put(UserActions.actionExceptionAH(e));

        throw ex;
    }
}

export function* sagaChangePassword(
    currentPassword: string,
    newPassword: string,
    callbackSuccess?: () => void,
    callbackFail?: (error: Exception, code?: string) => void,
) {
    try {
        const changePasswordCommand: Api.IChangePasswordRequest = { currentPassword, newPassword };

        const changePasswordResponse: Api.IChangePasswordResponse = yield call(changePassword, changePasswordCommand);

        switch (changePasswordResponse.code) {
            case Api.ChangePasswordResponseCode.CHANGED:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.ChangePassword,
                        action: AlfaMetricsAction.Changed,
                    }),
                );
                callbackSuccess?.();
                break;

            case Api.ChangePasswordResponseCode.NOTMATCHED:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.ChangePassword,
                        action: AlfaMetricsAction.NotMatched,
                    }),
                );
                callbackFail?.(
                    new Exception({
                        message: 'Введен некорректный текущий пароль',
                        initiator: ExceptionInitiator.Client,
                    }),
                    changePasswordResponse.code,
                );
                break;

            case Api.ChangePasswordResponseCode.MATCHED:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.ChangePassword,
                        action: AlfaMetricsAction.Matched,
                    }),
                );
                callbackFail?.(
                    new Exception({
                        message: 'Введенный пароль должен отличаться от текущего',
                        initiator: ExceptionInitiator.Client,
                    }),
                    changePasswordResponse.code,
                );
                break;

            case Api.ChangePasswordResponseCode.USED:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.ChangePassword,
                        action: AlfaMetricsAction.Used,
                    }),
                );
                callbackFail?.(
                    new Exception({
                        message: 'Введенный пароль уже был использован ранее. Пожалуйста, введите другой пароль',
                        initiator: ExceptionInitiator.Client,
                    }),
                    changePasswordResponse.code,
                );
                break;

            default:
                yield put(
                    actionSendAlfaMetrica({
                        category: AlfaMetricsCategory.ChangePassword,
                        action: AlfaMetricsAction.Failed,
                    }),
                );
                callbackFail?.(
                    new Exception({
                        message: 'По техническим причинам не удалось сменить пароль.',
                        initiator: ExceptionInitiator.Server,
                    }),
                );
        }
    } catch (ex) {
        yield put(
            actionSendAlfaMetrica({
                category: AlfaMetricsCategory.ChangePassword,
                action: AlfaMetricsAction.Failed,
            }),
        );
        callbackFail?.(ex as Exception);
    }
}

export function* sagaSignOut(effect?: PutEffect | CallEffect | AllEffect<PutEffect | CallEffect>) {
    try {
        yield call(signOut);
        yield put(
            actionSendAlfaMetrica({
                category: AlfaMetricsCategory.SignOut,
                action: AlfaMetricsAction.Success,
            }),
        );

        if (effect) {
            yield effect;
        }
    } catch (ex) {
        yield put(
            actionSendAlfaMetrica({
                category: AlfaMetricsCategory.SignOut,
                action: AlfaMetricsAction.Failed,
            }),
        );
        yield put(CoreNotificationManagerActions.actionAddNotificationError(ex as Exception));
    }
}

export function* sagaSendConfirmation(channel: string, value: string) {
    yield call(sendConfirmation, { channel: SendConfirmationRequestChannel[channel], value });
}

export function* sagaCheckConfirmation(channel: string, code: string, value: string) {
    const result: boolean = yield call(checkConfirmation, {
        channel: CheckConfirmationRequestChannel[channel],
        code,
        value,
    });

    return result;
}
