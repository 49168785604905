import React from 'react';

import { GuaranteeForm as GF } from '@ak-front/guarantee-request/client/components';
import { GuaranteeFillingContractWithOrganization } from '@ak-front/guarantee-request/client/components/guarantee-filling/guarantee-filling-contract';

export const GuaranteeForm: React.VFC = () => (
    <GF guaranteeFillingContractComponent={ GuaranteeFillingContractWithOrganization } />
);

GuaranteeForm.displayName = nameof(GuaranteeForm);
