import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';

import { Grid } from '@alfalab/core-components/grid';
import { Button } from '@alfalab/core-components/button';

import { FormControl } from '@ak-front/core/client/types';

import { LineIntent } from '@ak-front/core/client/components/line-intent';
import { Skeleton } from '@ak-front/core/client/components/skeleton';
import { getStatusTaskCreateBHR } from '#/src/client/store/selectors/task';
import { TaskActions } from '#/src/client/store/actions/creators';

export const ButtonCreate: React.FC<FormControl> = React.memo(({ waiting, disabled }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const statusTaskCreateBatchRequest = useSelector(getStatusTaskCreateBHR);

    const handleCreate = useCallback(() => {
        dispatch(TaskActions.actionTaskCreateBHRRun({ history }));
    }, []);

    return (
        <Grid.Col width={ { desktop: { s: '4', m: '2' }, tablet: { s: '3', m: '4' }, mobile: '12' } }>
            <LineIntent bottom="m">
                <Skeleton width="available" visible={ waiting }>
                    <Button
                        id="bhr-create"
                        view="primary"
                        block={ true }
                        size="m"
                        loading={ statusTaskCreateBatchRequest }
                        disabled={ disabled }
                        onClick={ handleCreate }
                    >
                        Создать
                    </Button>
                </Skeleton>
            </LineIntent>
        </Grid.Col>
    );
});

ButtonCreate.displayName = nameof(ButtonCreate);
