import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { BankRequestList } from '@ak-front/bank-request/client/components/bank-request-list';
import { PATH_REQUEST_BANK_ROUTE } from '@ak-front/bank-request/constants';
import { Form } from '@ak-front/core/client/components/forms';
import { CoreState } from '@ak-front/core/client/store/reducers';
import { CoreUserSelectors } from '@ak-front/core/client/store/selectors';
import { RequestCode } from '@ak-front/core/client/types';
import { removeStartSlash } from '@ak-front/core/client/utils/router';
import { PATH_ROOT_ROUTE } from '@ak-front/core/constants';
import { CreditRequestList } from '@ak-front/credit-request/client/components/credit-request-list';
import { PATH_REQUEST_CREDIT_ROUTE } from '@ak-front/credit-request/constants';
import { DocumentAggregationType } from '@ak-front/document-aggregation/client/api/fetch-client';
import { DocumentAggregationList } from '@ak-front/document-aggregation/client/components/document-aggregation-list';
import { DISALLOW_RIGHTS_DOCUMENT_QUERY_REQUEST_SEND_BANK } from '@ak-front/document-query-request/client/constants/user';
import { PATH_REQUEST_DOCUMENT_QUERY_ROUTE } from '@ak-front/document-query-request/constants';
import { DISALLOW_RIGHTS_DOCUMENT_REQUEST_SEND_BANK } from '@ak-front/document-request/client/constants/user';
import { PATH_REQUEST_DOCUMENT_ROUTE } from '@ak-front/document-request/constants';
import { PATH_REQUEST_GUARANTEE_ROUTE } from '@ak-front/guarantee-request/client';
import { GuaranteeRequestList } from '@ak-front/guarantee-request/client/components/guarantee-request-list';
import {
    DISALLOW_RIGHTS_GUARANTEE_REQUEST_ALL,
    DISALLOW_RIGHTS_GUARANTEE_REQUEST_CREATE_OR_EDIT,
} from '@ak-front/guarantee-request/client/constants/user';

import { Gap } from '@alfalab/core-components/gap';
import { Tab, Tabs } from '@alfalab/core-components/tabs';
import { SelectedId } from '@alfalab/core-components/tabs/typings';

import {
    DISALLOW_RIGHTS_BANK_REQUEST_SIGN,
    DISALLOW_RIGHTS_CREDIT_REQUEST_ALL,
    DISALLOW_RIGHTS_CREDIT_REQUEST_CREATE_OR_EDIT,
    IS_BROWSER,
} from '#/src/client/constants';

import { useRequestCode } from './hooks';

export const RequestForm: React.FC = () => {
    const isCreditRequestAllDisallowed = useSelector<CoreState, boolean>((s) =>
        CoreUserSelectors.isActionsDisallowedU(s, [DISALLOW_RIGHTS_CREDIT_REQUEST_ALL]),
    );
    const isCreditRequestCreateOrEditDisallowed = useSelector<CoreState, boolean>((s) =>
        CoreUserSelectors.isActionsDisallowedU(s, [DISALLOW_RIGHTS_CREDIT_REQUEST_CREATE_OR_EDIT]),
    );
    const isGuaranteeRequestAllDisallowed = useSelector<CoreState, boolean>((s) =>
        CoreUserSelectors.isActionsDisallowedU(s, [DISALLOW_RIGHTS_GUARANTEE_REQUEST_ALL]),
    );
    const isGuaranteeRequestCreateOrEditDisallowed = useSelector<CoreState, boolean>((s) =>
        CoreUserSelectors.isActionsDisallowedU(s, [DISALLOW_RIGHTS_GUARANTEE_REQUEST_CREATE_OR_EDIT]),
    );
    const isDocumentQueryRequestSendBankDisallowed = useSelector<CoreState, boolean>((s) =>
        CoreUserSelectors.isActionsDisallowedU(s, [DISALLOW_RIGHTS_DOCUMENT_QUERY_REQUEST_SEND_BANK]),
    );
    const isDocumentRequestSendBankDisallowed = useSelector<CoreState, boolean>((s) =>
        CoreUserSelectors.isActionsDisallowedU(s, [DISALLOW_RIGHTS_DOCUMENT_REQUEST_SEND_BANK]),
    );
    const isBankRequestSignDisallowed = useSelector<CoreState, boolean>((s) =>
        CoreUserSelectors.isActionsDisallowedU(s, [DISALLOW_RIGHTS_BANK_REQUEST_SIGN]),
    );
    const history = useHistory();
    const requestCode = useRequestCode();
    const [selectTabId, setSelectTabId] = useState<SelectedId>(requestCode || RequestCode.DocumentRequest);

    const openPath = (path:string)=>  {
        window.open(removeStartSlash(path), '_blank');
    }

    const handleBankRequestRowClick = useCallback((id: string): void => {
        openPath(`${PATH_REQUEST_BANK_ROUTE}/${id}`);
    }, []);

    const handleCreditequestListRowClick = useCallback((id: string): void => {
        openPath(`${PATH_REQUEST_CREDIT_ROUTE}/${id}`);
    }, []);

    const handleGuaranteeListRowClick = useCallback((id: string): void => {
        openPath(`${PATH_REQUEST_GUARANTEE_ROUTE}/${id}`);
    }, []);

    const handleDocumentAggregationRowClick = useCallback((id: string, type: DocumentAggregationType): void => {
        switch (type) {
            case DocumentAggregationType.DocumentQueryRequest:
                openPath(`${PATH_REQUEST_DOCUMENT_QUERY_ROUTE}/${id}`);
                break;
            case DocumentAggregationType.DocumentRequest:
                openPath(`${PATH_REQUEST_DOCUMENT_ROUTE}/${id}`);
                break;
        }
    }, []);

    const handleCreateDocumentRequest = useCallback((): void => openPath(PATH_REQUEST_DOCUMENT_ROUTE), []);

    const handleCreateCreditRequest = useCallback((): void => openPath(PATH_REQUEST_CREDIT_ROUTE), []);

    const handleCreateGuaranteeRequest = useCallback((): void => openPath(PATH_REQUEST_GUARANTEE_ROUTE), []);

    const handleChangeTab = (
        _: React.MouseEvent,
        payload: {
            selectedId: SelectedId;
        },
    ) => {
        setSelectTabId(payload.selectedId); 

        const search = new URLSearchParams({ requestCode: `${payload.selectedId}` });

        history.replace(removeStartSlash(`${PATH_ROOT_ROUTE}?${search}`));
    };

    return (
        <Form title='Кредитные продукты'>
            <Gap size='xl' />
            <Tabs selectedId={selectTabId} onChange={handleChangeTab}>
                <Tab
                    id={RequestCode.DocumentRequest}
                    title='Отправка документов'
                    hidden={isDocumentRequestSendBankDisallowed || !!isDocumentQueryRequestSendBankDisallowed}
                    keepMounted={false}
                >
                    <Gap size='2xl' />
                    <DocumentAggregationList waiting={!IS_BROWSER}
                        onRowClick={handleDocumentAggregationRowClick}
                        onCreateDocumentRequest={handleCreateDocumentRequest} />
                </Tab>
                <Tab
                    id={RequestCode.BankRequest}
                    title='Договоры'
                    hidden={!!isBankRequestSignDisallowed}
                    keepMounted={false}
                >
                    <BankRequestList onRowClick={handleBankRequestRowClick} />
                </Tab>
                <Tab
                    id={RequestCode.CreditRequest}
                    title='Кредиты'
                    hidden={isCreditRequestAllDisallowed || isCreditRequestCreateOrEditDisallowed}
                    keepMounted={false}
                >
                    <Gap size='2xl' />
                    <CreditRequestList 
                        onRowClick={handleCreditequestListRowClick}
                        onCreateCreditRequest={handleCreateCreditRequest}
                    />
                </Tab>
                <Tab
                    id={RequestCode.GuaranteeRequest}
                    title='Гарантии'
                    hidden={isGuaranteeRequestAllDisallowed || isGuaranteeRequestCreateOrEditDisallowed}
                    keepMounted={false}
                >
                    <Gap size='2xl' />
                    <GuaranteeRequestList 
                        onRowClick={handleGuaranteeListRowClick}
                        onCreateGuaranteeRequest={handleCreateGuaranteeRequest}
                    />
                </Tab>
            </Tabs>
            <Gap size='xl' />
        </Form>
    );
};

RequestForm.displayName = nameof(RequestForm);
