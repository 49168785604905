import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { CoreCertificateSelectors } from '@ak-front/core/client/store/selectors';

import { Gap } from '@alfalab/core-components/gap';
import { Indicator } from '@alfalab/core-components/indicator';
import { Modal as ModalResponsive } from '@alfalab/core-components/modal';
import { Tab, Tabs as TabsResponsive } from '@alfalab/core-components/tabs';
import { SelectedId } from '@alfalab/core-components/tabs/typings';
import {
    colorLightBgAccent,
    colorLightSpecialbgSecondaryTransparent,
    colorLightTextPrimary,
    colorStaticTextPrimaryLight,
} from '@alfalab/core-components/vars';

import { CertificateGroup } from '#/src/client/pages/external/sign/in/form/certificate-modal/certificate-group';
import { SignInFormCertificateModalEmptyBoundary } from '#/src/client/pages/external/sign/in/form/certificate-modal/empty-boundary';

type SignInFormCertificateModalProps = {
    open: boolean;
    onSignIn: (thumbprint?: string) => void;
    onClose: () => void;
};

type CertificateTabs = {
    active: string;
    noActive: string;
};
const tabs: CertificateTabs = {
    active: 'tab-1',
    noActive: 'tab-2',
};

export const SignInFormCertificateModal: React.VFC<SignInFormCertificateModalProps> = ({ open, onSignIn, onClose }) => {
    const [selectedTab, setSelectedTab] = useState<string>(tabs.active);
    const [validCertificates, otherCertificates] = useSelector(CoreCertificateSelectors.getCertificateGroups);

    const handleTabsChange = (
        _: React.MouseEvent,
        payload: {
            selectedId: SelectedId;
        },
    ) => {
        setSelectedTab(payload.selectedId.toString());
    };

    const handleCertificateClick = (
        _: React.MouseEvent,
        payload: {
            thumbprint?: string;
        },
    ) => {
        onSignIn(payload.thumbprint);
        onClose();
    };

    const activeIndicator = (
        <Indicator
            size={20}
            value={validCertificates.length}
            color={colorStaticTextPrimaryLight}
            backgroundColor={colorLightBgAccent}
        />
    );
    const notActiveIndicator = (
        <Indicator
            size={20}
            value={otherCertificates.length}
            color={colorLightTextPrimary}
            backgroundColor={colorLightSpecialbgSecondaryTransparent}
        />
    );

    return (
        <ModalResponsive open={open} size='m' onClose={onClose}>
            <ModalResponsive.Header title='Выберите сертификат' hasCloser={true} />
            <ModalResponsive.Content flex={true}>
                <SignInFormCertificateModalEmptyBoundary>
                    <TabsResponsive selectedId={selectedTab} view='primary' size='xs' onChange={handleTabsChange}>
                        <Tab id={tabs.active} title='Активные' rightAddons={activeIndicator}>
                            <Gap size='xl' />
                            <CertificateGroup
                                dataTestId='valid-certificates'
                                value={validCertificates}
                                onClick={handleCertificateClick}
                            />
                        </Tab>
                        <Tab id={tabs.noActive} title='Неактивные' rightAddons={notActiveIndicator}>
                            <Gap size='xl' />
                            <CertificateGroup
                                dataTestId='other-certificates'
                                disabled={true}
                                value={otherCertificates}
                            />
                        </Tab>
                    </TabsResponsive>
                </SignInFormCertificateModalEmptyBoundary>
            </ModalResponsive.Content>
        </ModalResponsive>
    );
};

SignInFormCertificateModal.displayName = nameof(SignInFormCertificateModal);
