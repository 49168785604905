import React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { RequestCode } from '@ak-front/core/client/types/request';
import { GuaranteeRequestModelType as GuaranteeType } from '@ak-front/guarantee-request/client/api/fetch-client';
import { PATH_REQUEST_GUARANTEE_ROUTE } from '@ak-front/guarantee-request/client/constants/router';
import { GuaranteeRequest } from '@ak-front/guarantee-request/client/types';

import { Table } from '@alfalab/core-components/table';

import { BHRActions } from '#/src/client/store/actions/creators';

import { tableSettings } from '../../batch-table-settings';

type BatchViewGuaranteeRequestItemProps = {
    request: GuaranteeRequest;
    type?: GuaranteeType;
};

export const BatchViewGuaranteeRequestItem: React.FC<BatchViewGuaranteeRequestItemProps> = React.memo(
    ({ request, type }) => {
        const dispatch = useDispatch();
        const history = useHistory();

        const handleGoToGuarantee = () => {
            if (request.id) {
                history.push(`${PATH_REQUEST_GUARANTEE_ROUTE}/${request.id}`);
            }
        };

        const handleDownloadPrintForm = () => {
            const idFile = request.requestFile?.idFile;

            if (idFile) {
                dispatch(BHRActions.actionDownloadRequestPrintFormFileBHR(idFile));
            }
        };

        return (
            <Table.TRow onClick={ handleGoToGuarantee }>
                {
                    (tableSettings[RequestCode.GuaranteeRequest]![type!] || [])
                        .map((col, idx) => (
                            <Table.TCell>
                                { idx === 0 ? col.getValue(request, handleDownloadPrintForm) : col.getValue(request) }
                            </Table.TCell>
                        ))
                }
            </Table.TRow>
        );
    },
);

BatchViewGuaranteeRequestItem.displayName = nameof(BatchViewGuaranteeRequestItem);
