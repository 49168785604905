import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { FormPlate } from '@ak-front/core/client';
import { RequestCode } from '@ak-front/core/client/types';
import { PATH_ROOT_ROUTE } from '@ak-front/core/constants/router';
import { DQRSelectors } from '@ak-front/document-query-request/client';
import { DocumentQueryRequestResponseStatus } from '@ak-front/document-query-request/client/api/fetch-client';
import { DocumentQueryForm as DQF } from '@ak-front/document-query-request/client/components';
import {
    DocumentQueryContext,
    DocumentQueryContextData,
} from '@ak-front/document-query-request/client/components/context';
import { DocumentQueryFillingOrganization } from '@ak-front/document-query-request/client/components/document-query-filling-organization';

import { RequestFeedback } from '#/src/client/components/request/request-feedback';

export const DocumentQueryForm: React.FC = () => {
    const status = useSelector(DQRSelectors.getStatusDQR);
    const contextValue = useMemo<DocumentQueryContextData>(() => {
        const search = new URLSearchParams({
            requestCode: RequestCode.DocumentRequest,
        });

        return {
            redirectType: 'push',
            redirectToPathAfterSendSuccess: `${PATH_ROOT_ROUTE}?${search}`,
        };
    }, []);

    const requestFeedbackIsVisible = status === DocumentQueryRequestResponseStatus.SendBank;

    const requestFeedback = requestFeedbackIsVisible ? (
        <FormPlate>
            <RequestFeedback requestCode={RequestCode.DocumentQueryRequest} />
        </FormPlate>
    ) : null;

    return (
        <DocumentQueryContext.Provider value={contextValue}>
            <DQF
                documentQueryFillingOrganizationComponent={DocumentQueryFillingOrganization}
                footer={requestFeedback}
            />
        </DocumentQueryContext.Provider>
    );
};

DocumentQueryForm.displayName = nameof(DocumentQueryForm);
