import React from 'react';
import { NBSP } from '@ak-front/core/client/constants/formatters';
import { LINK_SUPPORT_EMAIL, SUPPORT_EMAIL } from '@ak-front/core/constants/all';

import { Gap } from '@alfalab/core-components/gap';
import { Link } from '@alfalab/core-components/link';
import { Typography } from '@alfalab/core-components/typography';

import { PasswordRecoveryNavigation } from '#/src/client/pages/external/password-recovery/navigation';

type PasswordRecoverySupportProps = {
    onNavigationBack: () => void;
};

export const PasswordRecoverySupport: React.VFC<PasswordRecoverySupportProps> = ({ onNavigationBack }) => (
    <PasswordRecoveryNavigation onBack={onNavigationBack}>
        <Typography.TitleResponsive tag='h1' view='medium' color='primary' font='system'>
            Восстановление данных через поддержку
        </Typography.TitleResponsive>
        <Gap size='m' />
        <Typography.Text view='primary-medium' color='primary'>
            Для восстановления учетных данных вам необходимо написать на
            {NBSP}
            <Link view='default' href={LINK_SUPPORT_EMAIL}>
                {SUPPORT_EMAIL}
            </Link>
            ,{NBSP}
            указав в письме ИНН и ФИО
        </Typography.Text>
    </PasswordRecoveryNavigation>
);

PasswordRecoverySupport.displayName = nameof(PasswordRecoverySupport);
