import React from 'react';

import { ChangePasswordModalStep } from '#/src/client/types/user';

import { ChangePasswordFormWrapper } from './change-password-form-wrapper';
import { ChangePasswordSuccess } from './change-password-success';

type ChangePasswordModalStepSwitcherProps = {
    disabled?: boolean;
    step?: ChangePasswordModalStep;
    onClose: () => void;
};

export const ChangePasswordModalStepSwitcher: React.VFC<ChangePasswordModalStepSwitcherProps> = ({
    disabled,
    step,
    onClose: handleClose,
}) => {
    switch (step) {
        case ChangePasswordModalStep.Final:
            return <ChangePasswordSuccess disabled={disabled} onClick={handleClose} />;
        case ChangePasswordModalStep.Change:
        default:
            return <ChangePasswordFormWrapper onClose={handleClose} />;
    }
};

ChangePasswordModalStepSwitcher.displayName = nameof(ChangePasswordModalStepSwitcher);
