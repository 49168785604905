import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Exception from '@ak-front/core/client/exception';
import { TValidationProblems } from '@ak-front/core/client/types/validation';
import { useDidUpdate } from '@ak-front/core/client/utils/hook';
import { validateProblemsWithCodesSync, ValidationParameterType } from '@ak-front/core/client/validations';

import { PasswordInputProps } from '@alfalab/core-components/password-input';

import { ChangePasswordResponseCode } from '#/src/client/api/fetch-client/fetch-client-dto';
import { TaskActions } from '#/src/client/store/actions/creators';
import { actionTaskChangePasswordRun } from '#/src/client/store/actions/creators/task';
import { TaskSelectors } from '#/src/client/store/selectors';
import { getStatusTaskChangePassword } from '#/src/client/store/selectors/task';
import { ChangePassword } from '#/src/client/types/user';
import {
    changePasswordSchema,
    clearPasswordRegex,
    currentPasswordSchema,
    newPasswordSchema,
    passwordValidationRules,
} from '#/src/client/validations/auth';

export type ChangePasswordFormState = {
    newPassword: string;
    confirmPassword: string;
    validationProblems: TValidationProblems;
    validationRules: {
        isEmpty: boolean;
        isQuantityValid?: boolean;
        containsLatin?: boolean;
        containsNumbers?: boolean;
        containsAllowedSpecSymbols?: boolean;
        containsForbiddenSpecSymbols?: boolean;
        containsUpperAndLowerCase?: boolean;
        notContainsThreeSymbols?: boolean;
    };
    errorMessage: string;
};

const defaultChangePasswordFormState: ChangePasswordFormState = {
    newPassword: '',
    confirmPassword: '',
    validationProblems: {},
    validationRules: {
        isEmpty: true,
    },
    errorMessage: '',
};

const validationFields = {
    currentPassword: nameof<ChangePassword>((s) => s.currentPassword),
    newPassword: nameof<ChangePassword>((s) => s.newPassword),
    confirmPassword: nameof<ChangePassword>((s) => s.confirmPassword),
};

type ChangePasswordFormParams = {
    currentPassword: string;
    disabled?: boolean;
    validate?: () => boolean;
    onSuccess?: () => void;
    onFail?: (error?: Exception, code?: string) => void;
};

const getErrorMessage = (validationProblem: TValidationProblems[0]) => {
    const [error] = validationProblem?.error || [];

    return error?.message;
};

export const useChangePasswordForm = ({
    currentPassword,
    validate,
    onSuccess,
    onFail,
}: ChangePasswordFormParams): [
    boolean,
    boolean,
    ChangePasswordFormState,
    boolean,
    boolean,
    Record<string, { valid?: boolean; message: string }>,
    () => void | undefined,
    PasswordInputProps['onChange'] | undefined,
    PasswordInputProps['onFocus'] | undefined,
    PasswordInputProps['onBlur'] | undefined,
    PasswordInputProps['onChange'] | undefined,
    PasswordInputProps['onFocus'] | undefined,
    () => void | undefined,
] => {
    const dispatch = useDispatch();

    const [openChipsValidation, setOpenChipsValidation] = useState<boolean>(false);
    const [openNotification, setOpenNotification] = useState<boolean>(false);
    const [state, setState] = useState<ChangePasswordFormState>(defaultChangePasswordFormState);

    const statusTaskChangePassword = useSelector(getStatusTaskChangePassword);

    const loading = statusTaskChangePassword;
    const disabled = statusTaskChangePassword;

    const handleChangeConfirmPassword = (_: React.ChangeEvent, { value = '' }: { value: string }) =>
        setState((s) => ({
            ...s,
            confirmPassword: value.replace(clearPasswordRegex, ''),
        }));

    const handleChangeNewPassword = (_: React.ChangeEvent, { value = '' }: { value: string }) => {
        const clearPassword = value.replace(clearPasswordRegex, '');

        const validationParameter: ValidationParameterType<Pick<ChangePassword, 'newPassword'>> = {
            obj: { newPassword: clearPassword },
            schema: newPasswordSchema,
        };

        const { problems } = validateProblemsWithCodesSync(validationParameter);

        setState((s) => ({
            ...s,
            newPassword: clearPassword,
            validationProblems: problems,
        }));
    };

    const handleFocusConfirmPassword = () =>
        setState((s) => ({
            ...s,
            validationProblems: {
                ...s.validationProblems,
                [validationFields.confirmPassword]: undefined,
            },
        }));

    const handleFocusNewPassword = () => {
        setOpenChipsValidation(true);
        setState((s) => ({
            ...s,
            validationProblems: {
                ...s.validationProblems,
                [validationFields.newPassword]: undefined,
            },
            errorMessage: '',
        }));
    };

    const handleBlurNewPassword = () => setOpenChipsValidation(false);

    const handleSuccess = () => onSuccess?.();

    const handleFail = (error?: Exception, code?: string) => {
        if (!code) {
            setOpenNotification(true);
        }

        switch (code) {
            case ChangePasswordResponseCode.MATCHED:
            case ChangePasswordResponseCode.USED:
                setState((s) => ({
                    ...s,
                    errorMessage: error?.message || '',
                }));
                break;
        }

        onFail?.(error, code);
    };

    const handleCloseNotification = () => setOpenNotification(false);

    const handleChangePassword = () => {
        if (openNotification) {
            setOpenNotification(false);
        }

        const { newPassword = '', confirmPassword = '' } = state;

        const validationParameter: ValidationParameterType<Omit<ChangePassword, 'currentPassword'>> = {
            obj: { newPassword, confirmPassword },
            schema: changePasswordSchema,
        };
        const { valid, problems } = validateProblemsWithCodesSync(validationParameter);

        const result = validate ? validate() : true;

        if (valid && result) {
            dispatch(actionTaskChangePasswordRun(currentPassword, newPassword, handleSuccess, handleFail));
        }

        setState((s) => ({
            ...s,
            validationProblems: problems,
            errorMessage: s.validationRules.isEmpty
                ? 'Введите новый пароль'
                : (!(
                      s.validationRules.isQuantityValid &&
                      s.validationRules.containsLatin &&
                      s.validationRules.containsNumbers &&
                      s.validationRules.containsAllowedSpecSymbols &&
                      s.validationRules.containsUpperAndLowerCase &&
                      s.validationRules.notContainsThreeSymbols &&
                      s.validationRules.containsForbiddenSpecSymbols
                  ) &&
                      'Пароль небезопасный, придумайте более надёжный') ||
                  '',
        }));
    };

    useDidUpdate(() => {
        if (state.newPassword.length > 0) {
            const validationProblem = state.validationProblems[validationFields.newPassword];
            const errorCodes = validationProblem?.error?.map(({ code }) => code) || [];

            setState((s) => ({
                ...s,
                validationRules: {
                    ...s.validationRules,
                    isEmpty: false,
                    isQuantityValid: !errorCodes?.includes(nameof(passwordValidationRules.length)),
                    containsLatin: !errorCodes?.includes(nameof(passwordValidationRules.latin)),
                    containsNumbers: !errorCodes?.includes(nameof(passwordValidationRules.numbers)),
                    containsAllowedSpecSymbols: !errorCodes?.includes(nameof(passwordValidationRules.allowed)),
                    containsForbiddenSpecSymbols: !errorCodes?.includes(nameof(passwordValidationRules.forbidden)),
                    containsUpperAndLowerCase: !errorCodes?.includes(nameof(passwordValidationRules.letterCase)),
                    notContainsThreeSymbols: !errorCodes?.includes(nameof(passwordValidationRules.noThreeSymbols)),
                },
                errorMessage: '',
            }));
        } else {
            setState((s) => ({
                ...s,
                validationRules: defaultChangePasswordFormState.validationRules,
            }));
        }
    }, [state.newPassword]);

    const statuses: Record<string, { valid?: boolean; message: string }> = {
        [nameof(state.validationRules.isQuantityValid)]: {
            valid: state.validationRules.isQuantityValid,
            message: passwordValidationRules.length.message,
        },
        [nameof(state.validationRules.containsLatin)]: {
            valid: state.validationRules.containsLatin,
            message: passwordValidationRules.latin.message,
        },
        [nameof(state.validationRules.containsNumbers)]: {
            valid: state.validationRules.containsNumbers,
            message: passwordValidationRules.numbers.message,
        },
        [nameof(state.validationRules.containsUpperAndLowerCase)]: {
            valid: state.validationRules.containsUpperAndLowerCase,
            message: passwordValidationRules.letterCase.message,
        },
        [nameof(state.validationRules.containsAllowedSpecSymbols)]: {
            valid: state.validationRules.containsAllowedSpecSymbols,
            message: passwordValidationRules.allowed.message,
        },
        [nameof(state.validationRules.notContainsThreeSymbols)]: {
            valid: state.validationRules.notContainsThreeSymbols,
            message: passwordValidationRules.noThreeSymbols.message,
        },
        [nameof(state.validationRules.containsForbiddenSpecSymbols)]: {
            valid: state.validationRules.containsForbiddenSpecSymbols,
            message: passwordValidationRules.forbidden.message,
        },
    };

    return [
        loading,
        disabled,
        state,
        openNotification,
        openChipsValidation,
        statuses,
        handleChangePassword,
        handleChangeNewPassword,
        handleFocusNewPassword,
        handleBlurNewPassword,
        handleChangeConfirmPassword,
        handleFocusConfirmPassword,
        handleCloseNotification,
    ];
};

export const useChangePasswordCurrentForm = (): [
    string,
    string,
    boolean,
    boolean,
    ChangePasswordFormState,
    boolean,
    boolean,
    Record<string, { valid?: boolean; message: string }>,
    () => void | undefined,
    PasswordInputProps['onChange'] | undefined,
    PasswordInputProps['onFocus'] | undefined,
    PasswordInputProps['onBlur'] | undefined,
    PasswordInputProps['onChange'] | undefined,
    PasswordInputProps['onFocus'] | undefined,
    () => void | undefined,
    PasswordInputProps['onChange'] | undefined,
    PasswordInputProps['onFocus'] | undefined,
] => {
    const dispatch = useDispatch();

    const [errorCurrentPassword, setErrorCurrentPassword] = useState<string>('');
    const [currentPassword, setCurrentPassword] = useState<string>('');
    const statusTaskSignOut = useSelector(TaskSelectors.getStatusTaskSignOut);

    const handleSuccess = () => dispatch(TaskActions.actionTaskSignOutRun());
    const handleFail = (error?: Exception, code?: string) => {
        switch (code) {
            case ChangePasswordResponseCode.NOTMATCHED:
                setErrorCurrentPassword(error?.message || '');
                break;
        }
    };

    const validateCurrentPassword = () => {
        const validationParameter: ValidationParameterType<Pick<ChangePassword, 'currentPassword'>> = {
            obj: { currentPassword },
            schema: currentPasswordSchema,
        };
        const { valid, problems } = validateProblemsWithCodesSync(validationParameter);

        setErrorCurrentPassword(getErrorMessage(problems[validationFields.currentPassword]));

        return valid;
    };

    const [
        changePasswordFormLoading,
        changePasswordFormDisabled,
        state,
        openNotification,
        openChipsValidation,
        statuses,
        onChangePassword,
        onChangeNewPassword,
        onFocusNewPassword,
        onBlurNewPassword,
        onChangeConfirmPassword,
        onFocusConfirmPassword,
        onCloseNotification,
    ] = useChangePasswordForm({
        currentPassword,
        onSuccess: handleSuccess,
        onFail: handleFail,
        validate: validateCurrentPassword,
    });

    const disabled = changePasswordFormDisabled || statusTaskSignOut;
    const loading = changePasswordFormLoading;

    const handleChangeCurrentPassword = (_: React.ChangeEvent, { value = '' }: { value: string }) =>
        setCurrentPassword(value.replace(clearPasswordRegex, ''));

    const handleFocusCurrentPassword = () => setErrorCurrentPassword('');

    return [
        currentPassword,
        errorCurrentPassword,
        loading,
        disabled,
        state,
        openNotification,
        openChipsValidation,
        statuses,
        onChangePassword,
        onChangeNewPassword,
        onFocusNewPassword,
        onBlurNewPassword,
        onChangeConfirmPassword,
        onFocusConfirmPassword,
        onCloseNotification,
        handleChangeCurrentPassword,
        handleFocusCurrentPassword,
    ];
};
