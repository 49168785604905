import React from 'react';
import { OtherCertificateGroup } from '@ak-front/core/client/components/modals/certificates-modal/certificate-group';
import { ZIndexModal } from '@ak-front/core/constants';

import { Button } from '@alfalab/core-components/button';
import { Modal as ModalResponsive } from '@alfalab/core-components/modal';

type CertificateAttribute = {
    cn?: string;
    inn?: string;
    snils?: string;
    ogrnIp?: string;
    sn?: string;
    gn?: string;
};

type Certificate = {
    thumbprint?: string;
    issuerAttributes: CertificateAttribute;
    subjectAttributes: CertificateAttribute;
    validFromDate?: Date;
    validToDate?: Date;
    status?: string;
};

type OtherCertificatesModalProps = {
    open?: boolean;
    value?: Certificate[];
};

type OtherCertificatesModalEvents = {
    onClose?: () => void;
};

export const OtherCertificatesModal = React.memo(
    ({ open, value = [], onClose }: OtherCertificatesModalProps & OtherCertificatesModalEvents) => (
        <ModalResponsive
            open={Boolean(open)}
            size='m'
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            disableFocusLock={true}
            zIndex={ZIndexModal}
            onClose={onClose}
        >
            <ModalResponsive.Header title='Другие сертификаты' hasCloser={true} />
            <ModalResponsive.Content>
                <OtherCertificateGroup id='other-certificates' value={value} />
            </ModalResponsive.Content>
            <ModalResponsive.Footer sticky={true} layout='start'>
                <Button id='close' view='secondary' size='m' onClick={onClose}>
                    Закрыть
                </Button>
            </ModalResponsive.Footer>
        </ModalResponsive>
    ),
);

OtherCertificatesModal.displayName = nameof(OtherCertificatesModal);
