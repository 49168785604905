import React, { useCallback } from 'react';
import { batch, useDispatch, useSelector } from 'react-redux';
import { ZIndexModal } from '@ak-front/core/constants';

import { Grid } from '@alfalab/core-components/grid';
import { Modal as ModalResponsive } from '@alfalab/core-components/modal';

import { TaskActions, UserActions } from '#/src/client/store/actions/creators';
import { TaskSelectors, UserSelectors } from '#/src/client/store/selectors';
import { ChangePasswordModalStep } from '#/src/client/types/user';

import { ChangePasswordModalStepSwitcher } from './change-password-modal-step-switcher';

export const ChangePasswordModal: React.VFC = () => {
    const dispatch = useDispatch();

    const open = useSelector(UserSelectors.getOpenChangePasswordModalAH);
    const step = useSelector(UserSelectors.getChangePasswordModalStepAH);
    const statusTaskSignOut = useSelector(TaskSelectors.getStatusTaskSignOut);

    const handleClose = useCallback(() => {
        switch (step) {
            case ChangePasswordModalStep.Final:
                if (!statusTaskSignOut) {
                    dispatch(TaskActions.actionTaskSignOutRun());
                }
                break;
            default:
                batch(() => {
                    dispatch(UserActions.actionExceptionAH(undefined));
                    dispatch(UserActions.actionOpenPasswordChangeModalAH(false));
                });
        }
    }, [step, statusTaskSignOut]);

    const handleUnmount = useCallback(() => {
        dispatch(UserActions.actionSetStepChangePasswordModalAH(ChangePasswordModalStep.Change));
    }, []);

    return (
        <ModalResponsive
            size='m'
            open={Boolean(open)}
            disableEscapeKeyDown={true}
            disableBackdropClick={true}
            disableFocusLock={true}
            zIndex={ZIndexModal}
            onClose={handleClose}
            onUnmount={handleUnmount}
        >
            <ModalResponsive.Header hasCloser={true} />
            <ModalResponsive.Content>
                <Grid.Row gutter='0'>
                    <Grid.Col width='12'>
                        <ChangePasswordModalStepSwitcher
                            disabled={statusTaskSignOut}
                            step={step}
                            onClose={handleClose}
                        />
                    </Grid.Col>
                </Grid.Row>
            </ModalResponsive.Content>
        </ModalResponsive>
    );
};

ChangePasswordModal.displayName = nameof(ChangePasswordModal);
