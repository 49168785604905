import * as Api from './fetch-client';

/** Вход в систему Альфа Кредит 2.0 */
export const signIn = (command: Api.ISignInRequest) => {
    const model = new Api.SignInRequest(command);

    return new Api.FetchClient().signIn(model);
};

/** Вход в систему Альфа Кредит 2.0 по сертификату */
export const signInCertificate = (file: Api.FileParameter, signature: Api.FileParameter) => (
    new Api.FetchClient().signInCertificate(file, signature)
);

/** Регистрация по сертификату в системе Альфа Кредит 2.0 */
export const signUpCertificate = (file: Api.FileParameter, signature: Api.FileParameter) => (
    new Api.FetchClient().signUpCertificate(file, signature)
);

/** Выход из системы Альфа Кредит 2.0 */
export const signOut = () => new Api.FetchClient().signOut();

/** Изменение пароля входа в систему Альфа Кредит 2.0 */
export const changePassword = (command: Api.IChangePasswordRequest) => {
    const model = new Api.ChangePasswordRequest(command);

    return new Api.FetchClient().changePassword(model);
};

/** Отправить код подтвержения */
export const sendConfirmation = (request: Api.ISendConfirmationRequest) => {
    const model = new Api.SendConfirmationRequest(request);

    new Api.FetchClient().sendConfirmation(model);
};

/** Проверить код подтвержения */
export const checkConfirmation = (request: Api.ICheckConfirmationRequest) => {
    const model = new Api.CheckConfirmationRequest(request);

    return new Api.FetchClient().checkConfirmation(model);
};
