export enum UserAction {
    SET_DATA_U = '@@ak/user/SET_DATA_U',

    SET_CODE_AH = '@@ak/user/SET_CODE_AH',

    OPEN_CHANGE_PASSWORD_MODAL_AH = '@@ak/user/OPEN_CHANGE_PASSWORD_MODAL_AH',

    SET_STEP_CHANGE_PASSWORD_MODAL_AH = '@@ak/user/SET_STEP_CHANGE_PASSWORD_MODAL_AH',

    EXCEPTION_U = '@@ak/user/EXCEPTION_U',
}
