import { Reducer } from 'redux';
import Exception from '@ak-front/core/client/exception';
import { RequestCode } from '@ak-front/core/client/types/request';
import update from 'immutability-helper';

import { BHRAction } from '#/src/client/store/actions/constants/request/batch';
import { BHRActionsType } from '#/src/client/store/actions/creators';
import {
    BatchRequest,
    BatchRequestErrors,
    BatchRequestFilter,
    BatchRequestFilterStatus,
    BatchRequestForm,
} from '#/src/client/types';

/** Форма на пакетную загрузку документов */
export type BatchRequestState = {
    readonly batchRequest: BatchRequest;
    readonly batchRequestForm: BatchRequestForm;
    readonly list: BatchRequest[];
    readonly filter: BatchRequestFilter;
    readonly eof: boolean;
    readonly errors: BatchRequestErrors[];
    readonly exception?: Exception;
};

export const defaultBatchRequestState: BatchRequestState = {
    batchRequest: {},
    batchRequestForm: {
        requestCode: RequestCode.GuaranteeRequest,
        sourceData: [],
        guaranteeRequests: [],
    },
    filter: {
        status: BatchRequestFilterStatus.All,
    },
    list: [],
    eof: false,
    errors: [],
};

const batchRequestReducer: Reducer<BatchRequestState, BHRActionsType> = (
    state = defaultBatchRequestState,
    action: BHRActionsType,
): BatchRequestState => {
    switch (action.type) {
    case BHRAction.SET_LIST_BHR:
        return update(state, {
            list: { $set: action.payload.batches || [] },
            eof: { $set: action.payload.eof },
        });
    case BHRAction.ADD_TO_LIST_BHR:
        return update(state, {
            list: { $push: action.payload.batches },
            eof: { $set: action.payload.eof },
        });
    case BHRAction.SET_BHR:
        return update(state, {
            batchRequest: { $set: { ...action.payload.batchRequest } },
            batchRequestForm: {
                guaranteeType: { $set: undefined },
                guaranteeRequests: { $set: [] },
            },
        });
    case BHRAction.SET_GUARANTEE_TYPE_BHR:
        return update(state, {
            batchRequestForm: { guaranteeType: { $set: action.payload.guaranteeType } },
        });
    case BHRAction.SET_GUARANTEE_REQUESTS_BHR:
        return update(state, {
            batchRequestForm: {
                guaranteeRequests: { $set: [...action.payload.guaranteeRequests] },
                sourceData: { $set: [...action.payload.sourceData] },
            },
        });
    case BHRAction.SET_FILTER_STATUS_BHR:
        return update(state, {
            filter: {
                status: { $set: action.payload.status },
            },
        });
    case BHRAction.SET_FILE_ERRORS_BHR:
        return update(state, {
            errors: { $set: action.payload.errors },
        });
    case BHRAction.EXCEPTION_BHR:
        return update(state, {
            exception: { $set: action.payload.exception },
        });
    default:
        return state;
    }
};

export default batchRequestReducer;
