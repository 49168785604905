import React from 'react';
import { Provider } from 'react-redux';
import { Router } from 'react-router';
import { Store } from 'redux';
import { History } from 'history';

import { Routes } from '#/src/client/routing';
import { ApplicationState } from '#/src/client/store/reducers';

type BrowserRootProps = {
    history: History;
    store: Store<ApplicationState>;
};

export const BrowserRoot: React.FC<BrowserRootProps> = ({ store, history }): JSX.Element => (
    <Provider store={ store }>
        <Router history={ history } >
            <Routes />
        </Router>
    </Provider>
);

BrowserRoot.displayName = nameof(BrowserRoot);
